import { connect } from "react-redux";
import { openMenu, hideMenu } from "redux/actions";
import PathwayLegend from "./PathwayLegend";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  menu: state.ui.headerMenu,
  dataAudiences: state.data.audiences,
});

const mapDispatchToProps = {
  openMenu,
  hideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwayLegend);
