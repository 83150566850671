import { connect } from "react-redux";
import {
  setHeatMaps,
  setHeatThemes,
  setHeatAccounts,
  switchViewMode,
  setIsLoading,
} from "redux/actions";
import InsightScreen from "./InsightScreen";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  sideOption: state.ui.sideOption,
  heatMaps: state.data.heatMaps,
  heatThemes: state.data.heatThemes,
  heatAccounts: state.data.heatAccounts,
  chartStack: state.data.chartStack,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  setHeatMaps,
  setHeatThemes,
  setHeatAccounts,
  switchViewMode,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(InsightScreen);
