/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import { Chart, FontLoader } from "regraph";
import LZString from "lz-string";
import { ToastContainer, toast } from "react-toastify";

import PageTemplate from "pages/PageTemplate";
import LegendMenu from "components/Page/LegendMenu";
import GraphPopup from "components/Page/GraphPopup";
import { API_MESSAGE, API_STAR, API_UNSTAR } from "constants/routes";
import { convertToDt, compressJson } from "utils/convertData";

import "./styles.scss";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "react-toastify/dist/ReactToastify.css";
import AudienceAnalysis from "components/Page/AudienceAnalysis";
import { HEADER_ZOOM_GROUP_CT } from "constants/map";

interface IGraphScreen {
  menu: string;
  percent: number;
  updateProfileCount: (count: number) => void;
  updatePercent: (percent: number) => void;
  switchViewMode: (mode: string) => void;
  dataAudiences: AtlasMach.IAudience[];
  account: AtlasMach.INode | undefined;
  sideOption: AtlasMach.UISideOption;
  authToken: string;
  chartStack: { [key: string]: AtlasMach.IChartData };
  chartPositionStack: { [key: string]: any };
  pushChartPosition: (key: string, chart: any) => void;
  pushChart: (key: string, chart: AtlasMach.IChartData) => void;
  setChartRef: (ref: any) => void;
  setIsLoading: (loading: boolean) => void;
}

export default function GraphScreen({
  menu,
  percent,
  updateProfileCount,
  updatePercent,
  switchViewMode,
  dataAudiences,
  chartStack,
  account,
  sideOption,
  authToken,
  chartPositionStack,
  pushChart,
  pushChartPosition,
  setChartRef,
  setIsLoading,
}: IGraphScreen) {
  const [currentChartData, setCurrentChartData] =
    useState<AtlasMach.IChartData>();
  const [graphData, setGraphData] = useState<{ [key: string]: any }>({});

  const [positions, setPositions] = useState({});
  const [selection, setSelection] = useState({});
  const [zoom, setZoom] = useState(1);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [annotationPosition, setAnnotationPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [popupData, setPopupData] = useState<AtlasMach.IAccountMessages>();
  const [popupNode, setPopupNode] = useState<AtlasMach.INode>();

  const [rememberView, setRememberView] = useState<any>(undefined);
  const [currentView, setCurrentView] = useState<any>(undefined);

  const [currentPosition, setCurrentPosition] = useState();

  const chartRef = useRef<any>();

  const appendFlag = (node) => ({
    ...node,
    glyphs: [
      {
        position: "se",
        size: 1.5,
        color: "#FFA500",
        fontIcon: { text: "fa-star", color: "white" },
      },
    ],
  });

  useEffect(() => {
    const key = compressJson({
      topic: sideOption.topic,
      source: sideOption.source,
      dateFrom: sideOption.dateFrom,
      dateTo: sideOption.dateTo,
    });
    setCurrentChartData(chartStack[key]);
  }, [
    chartStack,
    sideOption.topic,
    sideOption.source,
    sideOption.dateFrom,
    sideOption.dateTo,
  ]);

  useEffect(() => {
    switchViewMode("graph");
  }, [switchViewMode]);

  useEffect(() => {
    if (!currentChartData) {
      setGraphData({});
      updateProfileCount(0);
      return;
    }
    const generateGraphData = (
      data: AtlasMach.IChartData,
      audiences,
      starred,
      percent: number
    ) => {
      const fDA = dataAudiences.filter(
        (da) => audiences.findIndex((ad) => da.id === ad) > -1
      );

      const items: { [key: string]: any } = {};
      const labelStyle = {
        backgroundColor: "rgba(0,0,0,0)",
        color: "white",
        center: false,
      };

      console.log(data.nodes);

      const fNodes = (
        audiences.length > 0
          ? data.nodes.filter(
              (node) =>
                fDA.findIndex((fda) => fda.id === node.audienceId) > -1 ||
                (starred && node.isStarred === starred)
            )
          : starred
          ? data.nodes.filter((node) => node.isStarred)
          : data.nodes
      )
        .filter(
          (node, index, self) =>
            self.findIndex((sn) => sn.key === node.key) === index
        )
        .filter(
          (node) =>
            node.orderID >= data.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
        );
      console.log(fNodes);

      let percentSum = 0;
      fNodes.forEach((node) => {
        let gNode = {
          label: {
            ...labelStyle,
            text: node.label,
          },
          size: node.size,
          color: node.color,
          sizePercent: node.sizePercent,
        };
        percentSum += node.sizePercent;
        if (node.isStarred) {
          gNode = appendFlag(gNode);
        }
        items[node.key] = gNode;
      });
      data.links.forEach((link) => {
        items[`${link.id1}-${link.id2}`] = {
          id1: link.id1,
          id2: link.id2,
          color: link.lineColor,
          lineStyle: link.lineStyle,
          width: link.width,
          end2: {
            arrow: link.end2HasArrow,
          },
        };
      });
      return {
        accountCount: fNodes.length,
        graphData: items,
        percent: Math.round(100 * percentSum) / 100,
      };
    };

    const gData = generateGraphData(
      currentChartData,
      sideOption.audiences,
      sideOption.starred,
      percent
    );
    setGraphData(gData.graphData);
    updateProfileCount(gData.accountCount);

    const keyObj: AtlasMach.UISideOption = {
      ...sideOption,
      audiences: sideOption.audiences.slice().sort(),
      // starred: sideOption.audiences.length === 0 ? false : sideOption.starred
    };
    const keyValue = LZString.compressToEncodedURIComponent(
      JSON.stringify({
        ...keyObj,
        percent: percent,
      })
    );

    const storedPosition = localStorage.getItem(`graph-${keyValue}`);
    // const storedPosition = chartPositionStack[keyValue]
    if (storedPosition) {
      const existPosition =
        LZString.decompressFromEncodedURIComponent(storedPosition);
      if (existPosition != null) {
        const existPositionObj = JSON.parse(existPosition);
        if (
          currentChartData.accountCount === existPositionObj.accountCount &&
          currentChartData.messageCount === existPositionObj.messageCount
        ) {
          console.log("found in position stack", existPositionObj);
          setCurrentPosition(existPositionObj.position);
          setPositions(existPositionObj.position);
          return;
        }
      }
    }
    console.log("not found in position stack");
    setCurrentPosition(undefined);
    setPositions({});
  }, [
    currentChartData,
    sideOption.audiences,
    dataAudiences,
    sideOption.starred,
    percent,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setSelection((prev) => {
        if (firstKey(prev) == null) {
          return prev;
        }
        return {};
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (account && chartRef.current) {
      chartRef.current.fit([account.key]);
      chartRef.current.ping(account.key);
    }
  }, [account, graphData]);

  useEffect(() => {
    if (chartRef.current) setChartRef(chartRef);
  }, [chartRef.current]);

  const loadMessages = async (topic, from, to, accountId, sourceId) => {
    const response = await fetch(API_MESSAGE.replace("$1", topic), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        dateRange: {
          startDate: from,
          endDate: to,
        },
        accountId: accountId,
        sourceId: sourceId,
      }),
    });
    const data = await response.json();
    setPopupData(data);
  };

  const handleStarred = () => {
    if (!popupNode) return;
    const starLink = popupNode.isStarred ? API_UNSTAR : API_STAR;
    fetch(
      starLink
        .replace("$1", sideOption.topic)
        .replace("$2", popupNode.sourceId)
        .replace("$3", popupNode.accountId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setGraphData((prev) => {
          prev[popupNode.key] = appendFlag(prev[popupNode.key]);
          return prev;
        });
        setCurrentChartData((prev) => {
          if (!prev) return prev;
          const updatedChartData = {
            ...prev,
            nodes: prev.nodes.map((node) =>
              node.accountId === popupNode?.accountId
                ? { ...node, isStarred: !node.isStarred }
                : node
            ),
          };
          return updatedChartData;
        });
        if (currentChartData) {
          const key = compressJson({
            topic: sideOption.topic,
            source: sideOption.source,
            dateFrom: sideOption.dateFrom,
            dateTo: sideOption.dateTo,
          });
          pushChart(key, {
            ...currentChartData,
            nodes: currentChartData.nodes.map((node) =>
              node.accountId === popupNode?.accountId
                ? { ...node, isStarred: !node.isStarred }
                : node
            ),
          });
        }
        setPopupNode((prev) =>
          prev
            ? {
                ...prev,
                isStarred: !prev.isStarred,
              }
            : prev
        );
        setRememberView(currentView);
      }
    });
  };

  function firstKey(record) {
    if (record == null) {
      return null;
    }
    const keys = Object.keys(record);
    return keys[0];
  }

  function isNode(item) {
    return item && item.id1 == null && item.id2 == null;
  }

  const onDblClickHandler = async ({ id: newID }) => {
    const chart = chartRef.current;
    let nextSelection = selection;
    let nextAnnotationPosition = annotationPosition;

    if (newID != null) {
      const selectedItemId = newID;
      const selectedItem = graphData[selectedItemId];
      if (isNode(selectedItem)) {
        nextSelection = { [selectedItemId]: true };
        nextAnnotationPosition = chart.viewCoordinates(
          positions[selectedItemId].x,
          positions[selectedItemId].y
        );

        const node = currentChartData?.nodes.find((node) => node.key === newID);
        if (!node) return;
        setPopupNode(node);
        const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
        setIsLoading(true);
        await loadMessages(
          sideOption.topic,
          dt.dt_from,
          dt.dt_to,
          node.accountId,
          node.sourceId
        );
        setIsLoading(false);
      }
    }

    if (nextAnnotationPosition)
      setAnnotationPosition({
        x: nextAnnotationPosition.x + 280,
        y: nextAnnotationPosition.y + 90,
      });
    setSelection(nextSelection);
  };

  const onChangeHandler = ({
    positions: newPositions,
    selection: newSelection,
    why,
  }: any) => {
    if (newPositions == null && newSelection == null) {
      return;
    }

    const chart = chartRef.current;
    if (chart == null) {
      return;
    }

    const nextPositions = newPositions || positions;
    let nextSelection = selection;
    let nextAnnotationPosition = annotationPosition;

    if (newSelection) {
      const selectedItemId = firstKey(newSelection);
      if (selectedItemId == null) {
        nextSelection = {};
      }
    } else if (annotationPosition == null && nextPositions != null) {
    }
    setAnnotationPosition(nextAnnotationPosition);
    setSelection(nextSelection);
    setPositions(nextPositions);

    const keyObj: AtlasMach.UISideOption = {
      ...sideOption,
      audiences: sideOption.audiences.slice().sort(),
    };
    const keyValue = LZString.compressToEncodedURIComponent(
      JSON.stringify({
        ...keyObj,
        percent: percent,
      })
    );

    const storedPosition = localStorage.getItem(`graph-${keyValue}`);

    if (storedPosition) {
      // const existPosition = chartPositionStack[keyValue]
      const existPosition =
        LZString.decompressFromEncodedURIComponent(storedPosition);
      if (existPosition != null) {
        const existPositionObj = JSON.parse(existPosition);
        if (why === "auto") {
          console.log("auto");
          if (
            currentChartData?.accountCount === existPositionObj.accountCount &&
            currentChartData?.messageCount === existPositionObj.messageCount
          ) {
            setCurrentPosition(existPositionObj.position);
            setPositions(existPositionObj.position);
            chartRef.current.fit("all");
          }
          return;
        } else if (why === "user") {
          console.log("drag & drop", keyValue);
          if (!newPositions) return;
          pushChartPosition(keyValue, newPositions);
          localStorage.setItem(
            `graph-${keyValue}`,
            compressJson({
              accountCount: currentChartData?.accountCount,
              messageCount: currentChartData?.messageCount,
              position: newPositions,
            })
          );
          return;
        }
      }
    }
    console.log("add new position state", keyValue);
    pushChartPosition(keyValue, newPositions);
    localStorage.setItem(
      `graph-${keyValue}`,
      compressJson({
        accountCount: currentChartData?.accountCount,
        messageCount: currentChartData?.messageCount,
        position: newPositions,
      })
    );
  };

  const onDragHandler = ({ type, x, y, draggedItems }) => {
    if (type !== "node" || firstKey(draggedItems) !== firstKey(selection)) {
      return;
    }

    setIsDragging(true);
    setAnnotationPosition({
      x: x - offset.x,
      y: y - offset.y,
    });
  };

  const onDragEndHandler = ({ defaultPrevented }) => {
    const chart = chartRef.current;
    const selectedItemId = firstKey(selection);
    if (
      !defaultPrevented ||
      chart == null ||
      selectedItemId == null ||
      positions[selectedItemId] == null
    ) {
      setIsDragging(false);
    } else {
      const annotationPosition = chart.viewCoordinates(
        positions[selectedItemId].x,
        positions[selectedItemId].y
      );
      setIsDragging(false);
      setAnnotationPosition(annotationPosition);
    }
  };

  const onPointerDownHandler = ({ id, x, y }) => {
    const item = graphData[id];
    const chart = chartRef.current;
    if (id == null || !isNode(item) || chart == null) {
      return;
    }
    // console.log(positions, id)
    const position = chart.viewCoordinates(positions[id].x, positions[id].y);
    const offset = {
      x: x - position.x,
      y: y - position.y,
    };
    setOffset(offset);
  };

  const onViewChangeHandler = (ev) => {
    const z = ev.zoom;
    const chart = chartRef.current;
    if (chart == null) {
      return;
    }

    const selectedItemId = firstKey(selection);
    if (
      zoom === z &&
      (selectedItemId == null || positions[selectedItemId] == null)
    ) {
      return;
    }

    if (selectedItemId != null && positions[selectedItemId] != null) {
      const nextAnnotationPosition = chart.viewCoordinates(
        positions[selectedItemId].x,
        positions[selectedItemId].y
      );
      setAnnotationPosition({
        x: nextAnnotationPosition.x + 280,
        y: nextAnnotationPosition.y + 90,
      });
    }

    setCurrentView(ev);
  };

  console.log("rendering");
  console.log(graphData);

  const selectedItemId = firstKey(selection);

  return (
    <PageTemplate title="The Atlas">
      <LegendMenu />
      {menu === "aa" && popupNode && <AudienceAnalysis />}
      <div className="chart-wrapper">
        <FontLoader config={{ custom: { families: ["Font Awesome 6 Free"] } }}>
          <Chart
            ref={chartRef}
            style={{
              width: "calc(100% - 280px)",
              height: "calc(100% - 90px)",
              left: "280px",
              top: "90px",
              boxSizing: "border-box",
              position: "relative",
            }}
            items={graphData}
            selection={selection}
            positions={currentPosition}
            onDoubleClick={onDblClickHandler}
            onChange={onChangeHandler}
            onDrag={onDragHandler}
            onDragEnd={onDragEndHandler}
            onPointerDown={onPointerDownHandler}
            onViewChange={onViewChangeHandler}
            view={rememberView}
            options={{
              navigation: {
                position: "se",
              },
              overview: false,
              backgroundColor: "rgba(0,0,0,0)",
              iconFontFamily: "Font Awesome 6 Free",
              minZoom: 0.005,
              fit: "all",
            }}
            onWheel={({ preventDefault }) => {
              if (isDragging) preventDefault();
            }}
          />
        </FontLoader>
        {selectedItemId != null &&
          annotationPosition != null &&
          popupData &&
          popupNode && (
            <GraphPopup
              position={annotationPosition}
              handleClose={() => setSelection({})}
              data={popupData}
              node={popupNode}
              onStarred={handleStarred}
            />
          )}
      </div>
    </PageTemplate>
  );
}
