import PageTemplate from "pages/PageTemplate";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import "./styles.scss";
import SvgFirst from "assets/images/icons/first.svg";
import SvgPrev from "assets/images/icons/prev.svg";
import SvgNext from "assets/images/icons/next.svg";
import SvgLast from "assets/images/icons/last.svg";
import SvgSort from "assets/images/icons/sort.svg";
import SvgSortSel from "assets/images/icons/sort-up.svg";

import PathwayLegend from "components/Page/PathwayLegend";
import {
  API_PATHWAY_ALL,
  API_PATHWAY_MACH9,
  API_PATHWAY_QUICK,
  API_PATHWAY_TOP25,
  API_STAR_PROFILE,
  API_UNSTAR,
} from "constants/routes";
import BlueButton from "components/Page/BlueButton";
import moment from "moment";
import { dataEngagements } from "utils/constants";

interface Props {
  sideOption: AtlasMach.UISideOption;
  starAccounts: AtlasMach.IStarredAccount[];
  authToken: string;
  setStarAccounts: (accounts: AtlasMach.IStarredAccount[]) => void;
  setExportData: (data: any) => void;
  switchViewMode: (mode: string) => void;
  setIsLoading: (loading: boolean) => void;
}

function PathwayScreen(props: Props) {
  const {
    sideOption,
    starAccounts,
    setStarAccounts,
    authToken,
    setExportData,
    switchViewMode,
    setIsLoading,
  } = props;

  const tabHeaders = ["All", "Mach9 Rec", "Quick Wins", "Top 25", "Starred"];

  const [activeTab, setActiveTab] = useState<string>("All");
  const [sortColumn, setSortColumn] = useState<string>("");
  const [ordering, setOrdering] = useState<boolean>(false);

  const [selected, setSelected] = useState<string[]>([]);
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [starSortColumn, setStarSortColumn] = useState<string>("");
  const [starOrdering, setStarOrdering] = useState<boolean>(false);

  const [pathData, setPathData] = useState<{
    [key: string]: AtlasMach.IPathways[];
  }>({
    All: [],
    "Mach9 Rec": [],
    "Quick Wins": [],
    "Top 25": [],
  });

  const filteredAccountsCt = useCallback(
    (tab: string) => {
      if (tab !== "Starred") {
        if (sideOption.engagements.length === 0) return pathData[tab].length;

        return pathData[tab].filter(
          (pd) =>
            sideOption.engagements.findIndex((eg) => eg === pd.likelihood) >= 0
        ).length;
      } else {
        return starAccounts.length;
      }
    },
    [pathData]
  );

  const filteredPathData = useMemo(() => {
    if (activeTab === "Starred") return [];
    if (sideOption.engagements.length === 0) return pathData[activeTab];

    return pathData[activeTab].filter(
      (pd) =>
        sideOption.engagements.findIndex((eg) => eg === pd.likelihood) >= 0
    );
  }, [pathData, activeTab, sideOption.engagements]);

  const sortedPathData = useMemo(() => {
    const res = filteredPathData.sort((a, b) => {
      if (sortColumn === "name") {
        if (ordering) {
          if (a.account > b.account) return -1;
          else if (a.account < b.account) return 1;
          else return 0;
        } else {
          if (a.account < b.account) return -1;
          else if (a.account > b.account) return 1;
          else return 0;
        }
      } else if (sortColumn === "audiences") {
        if (ordering) {
          if (a.audience > b.audience) return -1;
          else if (a.audience < b.audience) return 1;
          else return 0;
        } else {
          if (a.audience < b.audience) return -1;
          else if (a.audience > b.audience) return 1;
          else return 0;
        }
      } else if (sortColumn === "engagement") {
        if (ordering) {
          if (a.likelihood > b.likelihood) return -1;
          else if (a.likelihood < b.likelihood) return 1;
          else return 0;
        } else {
          if (a.likelihood < b.likelihood) return -1;
          else if (a.likelihood > b.likelihood) return 1;
          else return 0;
        }
      } else if (sortColumn === "score") {
        if (ordering) {
          return a.score - b.score;
        } else {
          return b.score - a.score;
        }
      } else {
        return 0;
      }
    });

    return [...res];
  }, [filteredPathData, sortColumn, ordering]);

  const tabRightBorder = useMemo(() => {
    if (activeTab === "All") {
      return [false, true, true, true, false];
    } else if (activeTab === "Mach9 Rec") {
      return [false, false, true, true, false];
    } else if (activeTab === "Quick Wins") {
      return [true, false, false, true, false];
    } else if (activeTab === "Top 25") {
      return [true, true, false, false, false];
    }
    return [true, true, true, false, false];
  }, [activeTab]);

  const filteredAccounts = useMemo(() => {
    if (sideOption.audiences.length === 0) return starAccounts;

    return starAccounts.filter(
      (ac) =>
        ac.account.audiences.findIndex(
          (acAu) =>
            sideOption.audiences.findIndex(
              (au) => au === acAu.topicAudienceId
            ) >= 0
        ) >= 0
    );
  }, [starAccounts, sideOption.audiences]);

  const sortedAccounts = useMemo(() => {
    const res = filteredAccounts.sort((a, b) => {
      if (starSortColumn === "name") {
        if (starOrdering) {
          if (a.account.accountName > b.account.accountName) return -1;
          else if (a.account.accountName < b.account.accountName) return 1;
          else return 0;
        } else {
          if (a.account.accountName < b.account.accountName) return -1;
          else if (a.account.accountName > b.account.accountName) return 1;
          else return 0;
        }
      } else if (starSortColumn === "score") {
        if (starOrdering) {
          return a.score - b.score;
        } else {
          return b.score - a.score;
        }
      } else {
        if (starOrdering) {
          if (a.createdOn > b.createdOn) return -1;
          else if (a.createdOn < b.createdOn) return 1;
          else return 0;
        } else {
          if (a.createdOn < b.createdOn) return -1;
          else if (a.createdOn > b.createdOn) return 1;
          else return 0;
        }
      }
    });

    return [...res];
  }, [filteredAccounts, starSortColumn, starOrdering]);

  const pageCount = useMemo(() => {
    if (filteredAccounts.length === 0) return 0;
    return filteredAccounts.length % pageSize !== 0
      ? Number.parseInt((filteredAccounts.length / pageSize).toString()) + 1
      : filteredAccounts.length / pageSize;
  }, [filteredAccounts, pageSize]);

  const pageLimit = useMemo(() => {
    const minPage = Math.max(0, page - 2);
    const maxPage = Math.min(minPage + 5, pageCount);
    return { minPage, maxPage };
  }, [pageCount, page]);

  const pageAccounts = useMemo(() => {
    return sortedAccounts.filter(
      (ac, idx) => idx >= page * pageSize && idx < (page + 1) * pageSize
    );
  }, [sortedAccounts, page, pageSize]);

  const allSelect = useMemo(() => {
    return pageAccounts.length > 0 && pageAccounts.length === selected.length;
  }, [selected, pageAccounts]);

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setOrdering((prev) => !prev);
    } else {
      setSortColumn(column);
      setOrdering(true);
    }
  };

  const handleStarSort = (column: string) => {
    console.log("star sort");
    if (column === starSortColumn) {
      setStarOrdering((prev) => !prev);
    } else {
      setStarSortColumn(column);
      setStarOrdering(true);
    }
  };

  useEffect(() => {
    switchViewMode("pathways");
  }, []);

  useEffect(() => {
    if (!authToken || !sideOption.topic || !sideOption.source) return;

    setIsLoading(true);
    let endPoints = [
      { tab: "All", endpoint: API_PATHWAY_ALL },
      { tab: "Mach9 Rec", endpoint: API_PATHWAY_MACH9 },
      { tab: "Quick Wins", endpoint: API_PATHWAY_QUICK },
      { tab: "Top 25", endpoint: API_PATHWAY_TOP25 },
    ];
    let promises = endPoints.map((ep) => {
      return fetch(
        ep.endpoint
          .replace("$1", sideOption.topic)
          .replace("$2", sideOption.source),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(sideOption.audiences),
        }
      )
        .then((res) => res.json())
        .then((res) =>
          setPathData((prev) => {
            let updated = { ...prev };
            updated[ep.tab] = res;
            setIsLoading(false);
            return updated;
          })
        );
    });
    Promise.all(promises);
  }, [authToken, sideOption.topic, sideOption.source, sideOption.audiences]);

  useEffect(() => {
    setSelected([]);
  }, [pageAccounts]);

  useEffect(() => {
    setExportData({
      ...pathData,
      Starred: starAccounts,
    });
  }, [pathData]);

  useEffect(() => {
    if (sideOption.topic && sideOption.source) {
      setIsLoading(true);
      fetch(
        API_STAR_PROFILE.replace("$1", sideOption.topic).replace(
          "$2",
          sideOption.source
        ),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setStarAccounts(data));
      setIsLoading(false);
    }
  }, [sideOption.topic, sideOption.source]);

  const handleCheck = (id) => {
    setSelected((prev) =>
      prev.findIndex((p) => p === id) >= 0
        ? prev.filter((p) => p !== id)
        : [...prev, id]
    );
  };
  const handleAllCheck = () => {
    if (allSelect) setSelected([]);
    else setSelected(pageAccounts.map((pa) => pa.id));
  };

  const removeStar = async () => {
    await Promise.all(
      selected.map(async (id, i) => {
        const selectedAccount = starAccounts.find((ac) => ac.id === id);
        if (!selectedAccount) return;

        await fetch(
          API_UNSTAR.replace("$1", selectedAccount.topicId)
            .replace("$2", selectedAccount.sourceId)
            .replace("$3", selectedAccount.accountId),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      })
    );
    setStarAccounts(
      starAccounts.filter(
        (pac) => selected.findIndex((sac) => sac === pac.id) < 0
      )
    );
  };

  return (
    <PageTemplate title="Pathways">
      <PathwayLegend />
      <div className="pathways-container">
        <div className="pathways-tabs">
          {tabHeaders.map((tabh, idx) => (
            <div
              key={`tabh-${tabh}`}
              className={`tab-header${activeTab === tabh ? " active" : ""}`}
              style={{
                borderRight: tabRightBorder[idx]
                  ? "2px solid #78D2F11F"
                  : "none",
              }}
              onClick={() => {
                setActiveTab(tabh);
                setSortColumn("");
              }}
            >
              <span>
                {tabh} ({filteredAccountsCt(tabh)})
              </span>
            </div>
          ))}
        </div>
        {activeTab !== "Starred" ? (
          <div className="pathways-table-container">
            <div className="pathways-table-header">
              <div
                className="icon-header"
                style={{ justifyContent: "start", paddingLeft: "40px" }}
              >
                <span className={sortColumn === "name" ? "active" : ""}>
                  Account
                </span>
                <img
                  src={sortColumn === "name" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleSort("name")}
                />
              </div>
              <div className="icon-header">
                <span className={sortColumn === "audiences" ? "active" : ""}>
                  Audiences
                </span>
                <img
                  src={sortColumn === "audiences" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleSort("audiences")}
                />
              </div>
              <div className="icon-header">
                <span className={sortColumn === "score" ? "active" : ""}>
                  Centricity Score
                </span>
                <img
                  src={sortColumn === "score" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleSort("score")}
                />
              </div>
              <div className="icon-header" style={{ paddingRight: "40px" }}>
                <span className={sortColumn === "engagement" ? "active" : ""}>
                  Engagement Likelihood
                </span>
                <img
                  src={sortColumn === "engagement" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleSort("engagement")}
                />
              </div>
            </div>
            <div className="pathways-table-body-wrapper">
              <div className="pathways-table-body">
                {sortedPathData.map((ac, idx) => (
                  <div
                    className="pathways-table-row"
                    key={`pathways-account-${idx}`}
                  >
                    <div className="pathways-table-td">{ac.account}</div>
                    <div className="pathways-table-td">{ac.audience}</div>
                    <div className="pathways-table-td">
                      {ac.score.toFixed(2)}%
                    </div>
                    <div className="pathways-table-td">
                      {
                        dataEngagements.find((de) => de.key === ac.likelihood)
                          ?.label
                      }
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="pathways-table-container">
            <div className="pathways-table-header">
              <div
                className="icon-header"
                style={{ justifyContent: "start", width: "10%" }}
              >
                <label className="check-container">
                  <input
                    type="checkbox"
                    name="checkAllProfile"
                    className="check-input"
                    checked={allSelect}
                    onChange={(ev) => handleAllCheck()}
                  />
                  <span className="check-title"></span>
                  <span className="checkmark"></span>
                </label>
              </div>
              <div className="icon-header">
                <span className={starSortColumn === "name" ? "active" : ""}>
                  Account
                </span>
                <img
                  src={starSortColumn === "name" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleStarSort("name")}
                />
              </div>
              <div className="icon-header">
                <span className={starSortColumn === "score" ? "active" : ""}>
                  Centricity Score
                </span>
                <img
                  src={starSortColumn === "score" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleStarSort("score")}
                />
              </div>
              <div className="icon-header">
                <span className={starSortColumn === "date" ? "active" : ""}>
                  Date Added
                </span>
                <img
                  src={starSortColumn === "date" ? SvgSortSel : SvgSort}
                  alt="sort"
                  className="sort-icon"
                  onClick={() => handleStarSort("date")}
                />
              </div>
            </div>
            <div className="pathways-table-body-wrapper">
              <div className="pathways-table-body">
                {pageAccounts.map((ac) => (
                  <div
                    className="pathways-table-row"
                    key={`star-profile-${ac.id}`}
                  >
                    <div
                      className="pathways-table-td"
                      style={{
                        justifyContent: "start",
                        width: "10%",
                        paddingLeft: 20,
                      }}
                    >
                      <label
                        className="check-container"
                        style={{ marginTop: 0 }}
                      >
                        <input
                          type="checkbox"
                          name="checkAllProfile"
                          className="check-input"
                          value={ac.id}
                          checked={
                            selected.findIndex((sl) => sl === ac.id) >= 0
                          }
                          onChange={(ev) => handleCheck(ac.id)}
                        />
                        <span className="check-title"></span>
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="pathways-table-td">{ac.account.name}</div>
                    <div className="pathways-table-td">{ac.score}%</div>
                    <div className="pathways-table-td">
                      {moment(ac.createdOn).format("YYYY-MM-DD")}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="profile-table-footer">
              <div className="profile-table-buttons">
                <BlueButton
                  title="Remove Starred Account"
                  disabled={selected.length === 0}
                  onClick={() => removeStar()}
                />
                <span className="selected-count">
                  {selected.length} SELECTED
                </span>
              </div>
              <div className="profile-table-pagination">
                <div>
                  <select
                    className="pagination-count"
                    value={pageSize}
                    onChange={(ev) => setPageSize(Number(ev.target.value))}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                  <span>PER PAGE</span>
                </div>
                <img
                  src={SvgFirst}
                  alt="first-page"
                  className="move-page"
                  onClick={() => setPage(0)}
                />
                <img
                  src={SvgPrev}
                  alt="prev-page"
                  className="move-page"
                  onClick={() => setPage((prev) => Math.max(0, prev - 1))}
                />
                {Array.from(Array(pageLimit.maxPage - pageLimit.minPage)).map(
                  (v, i) => (
                    <span
                      className={`page-number ${
                        pageLimit.minPage + i === page ? "active" : ""
                      }`}
                      key={`page-${pageLimit.minPage + i + 1}`}
                      onClick={() => setPage(i)}
                    >
                      {pageLimit.minPage + i + 1}
                    </span>
                  )
                )}
                <img
                  src={SvgNext}
                  alt="prev-page"
                  className="move-page"
                  onClick={() =>
                    setPage((prev) => Math.min(pageCount - 1, prev + 1))
                  }
                />
                <img
                  src={SvgLast}
                  alt="prev-page"
                  className="move-page"
                  onClick={() => setPage(pageCount - 1)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </PageTemplate>
  );
}

export default PathwayScreen;
