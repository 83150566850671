import React, { useCallback, useEffect, useMemo, useState } from "react";
import SearchIcon from "assets/images/icons/search.svg";
import BlueButton from "../BlueButton";
import { useDetectClickOutside } from "react-detect-click-outside";
interface Props {
  popupColumn: string;
  leftPos: number;
  sortColumn: string;
  ordering: boolean;
  themeData: AtlasMach.IPieData[];
  sentimentData: AtlasMach.IPieData[];
  selectedSentimentIDs: string[];
  selectedThemeIDs: string[];
  filterKeyword: string;
  accountKeyword: string;
  searchedAccounts: any[];
  selectedAccountIDs: string[];
  setSortColumn: (column: string) => void;
  setOrdering: (isDesc: boolean) => void;
  setShowSort: (isShow: boolean) => void;
  setSelectedSentimentIDs: (ids: string[]) => void;
  setSelectedThemeIDs: (ids: string[]) => void;
  setFilterKeyword: (key: string) => void;
  setAccountKeyword: (key: string) => void;
  setSelectedAccountIDs: (ids: string[]) => void;
}

function VoiceFilterPopup({
  popupColumn,
  leftPos,
  sortColumn,
  ordering,
  themeData,
  sentimentData,
  selectedSentimentIDs,
  selectedThemeIDs,
  filterKeyword,
  accountKeyword,
  searchedAccounts,
  selectedAccountIDs,
  setSortColumn,
  setOrdering,
  setShowSort,
  setSelectedSentimentIDs,
  setSelectedThemeIDs,
  setFilterKeyword,
  setAccountKeyword,
  setSelectedAccountIDs,
}: Props) {
  const [isPopupUpdated, setIsPopupUpdated] = useState<boolean>(false);

  const [popupAudienceOrdering, setPopupAudienceOrdering] =
    useState<boolean>(false);
  const [popupMessageKeyword, setPopupMessageKeyword] =
    useState<string>(filterKeyword);
  const [popupSentimentOrdering, setPopupSentimentOrdering] =
    useState<boolean>(false);
  const [popupSentimentIDs, setPopupSentimentIDs] =
    useState<string[]>(selectedSentimentIDs);
  const [popupThemeOrdering, setPopupThemeOrdering] = useState<boolean>(false);
  const [popupThemeIDs, setPopupThemeIDs] =
    useState<string[]>(selectedThemeIDs);
  const [popupDateOrdering, setPopupDateOrdering] = useState<boolean>(false);
  const [popupEngagementOrdering, setPopupEngagementOrdering] =
    useState<boolean>(false);
  const [popupAccountKeyword, setPopupAccountKeyword] =
    useState<string>(accountKeyword);
  const [popupAccountIDs, setPopupAccountIDs] =
    useState<string[]>(selectedAccountIDs);
  const [popupSenderOrdering, setPopupSenderOrdering] =
    useState<boolean>(false);

  useEffect(() => {
    if (sortColumn === "Audience") {
      setPopupAudienceOrdering(ordering);
    } else if (sortColumn === "Sentiment") {
      setPopupSentimentOrdering(ordering);
    } else if (sortColumn === "Theme") {
      setPopupThemeOrdering(ordering);
    } else if (sortColumn === "Date") {
      setPopupDateOrdering(ordering);
    } else if (sortColumn === "EngagementScore") {
      setPopupEngagementOrdering(ordering);
    } else if (sortColumn === "Sender") {
      setPopupSenderOrdering(ordering);
    }
  }, [sortColumn, ordering]);

  const checkPopupOrdering = useMemo(() => {
    if (popupColumn === "Audience" && popupAudienceOrdering) {
      return false;
    } else if (popupColumn === "Sentiment" && popupSentimentOrdering) {
      return false;
    } else if (popupColumn === "Theme" && popupThemeOrdering) {
      return false;
    } else if (popupColumn === "Date" && popupDateOrdering) {
      return false;
    } else if (popupColumn === "EngagementScore" && popupEngagementOrdering) {
      return false;
    } else if (popupColumn === "Sender" && popupSenderOrdering) {
      return false;
    }
    return true;
  }, [
    popupColumn,
    popupAudienceOrdering,
    popupSentimentOrdering,
    popupThemeOrdering,
    popupDateOrdering,
    popupEngagementOrdering,
    popupSenderOrdering,
  ]);
  const checkAllSelect = useMemo(() => {
    if (popupColumn === "Sentiment") {
      return popupSentimentIDs.length === sentimentData.length;
    } else if (popupColumn === "Theme") {
      return popupThemeIDs.length === themeData.length;
    } else if (popupColumn === "Sender") {
      return (
        popupAccountIDs.length >= searchedAccounts.length &&
        searchedAccounts.length > 0
      );
    }
    return false;
  }, [
    popupColumn,
    popupSentimentIDs,
    popupThemeIDs,
    popupAccountIDs,
    sentimentData,
    themeData,
    searchedAccounts,
  ]);

  const handlePopupOrdering = (column: string, dir: boolean) => {
    if (column === "Audience") {
      setPopupAudienceOrdering(dir);
    } else if (column === "Sentiment") {
      setPopupSentimentOrdering(dir);
    } else if (column === "Theme") {
      setPopupThemeOrdering(dir);
    } else if (column === "Date") {
      setPopupDateOrdering(dir);
    } else if (column === "EngagementScore") {
      setPopupEngagementOrdering(dir);
    } else if (column === "Sender") {
      setPopupSenderOrdering(dir);
    }
    setIsPopupUpdated(true);
  };
  const handleCheckAll = useCallback(
    (ev) => {
      if (popupColumn === "Sentiment") {
        if (ev.target.checked) {
          setPopupSentimentIDs(sentimentData.map((sd) => sd.id));
        } else {
          setPopupSentimentIDs([]);
        }
      } else if (popupColumn === "Theme") {
        if (ev.target.checked) {
          setPopupThemeIDs(themeData.map((sd) => sd.id));
        } else {
          setPopupThemeIDs([]);
        }
      } else if (popupColumn === "Sender") {
        if (ev.target.checked) {
          setPopupAccountIDs(searchedAccounts.map((sd) => sd.id));
        } else {
          setPopupAccountIDs([]);
        }
      }
      setIsPopupUpdated(true);
    },
    [popupColumn, themeData, sentimentData, searchedAccounts]
  );
  const handleSentimentCheck = (ev) => {
    if (ev.target.checked) {
      setPopupSentimentIDs((prev) => [...prev, ev.target.value]);
    } else {
      setPopupSentimentIDs((prev) =>
        prev.filter((pItem) => pItem !== ev.target.value)
      );
    }
    setIsPopupUpdated(true);
  };
  const handleThemeCheck = (ev) => {
    if (ev.target.checked) {
      setPopupThemeIDs((prev) => [...prev, ev.target.value]);
    } else {
      setPopupThemeIDs((prev) =>
        prev.filter((pItem) => pItem !== ev.target.value)
      );
    }
    setIsPopupUpdated(true);
  };
  const handleAccountCheck = (ev) => {
    if (ev.target.checked) {
      setPopupAccountIDs((prev) => [...prev, ev.target.value]);
    } else {
      setPopupAccountIDs((prev) =>
        prev.filter((pItem) => pItem !== ev.target.value)
      );
    }
    setIsPopupUpdated(true);
  };
  const handleApplyFilter = useCallback(() => {
    if (popupColumn === "Audience") {
      setSortColumn(popupColumn);
      setOrdering(popupAudienceOrdering);
    } else if (popupColumn === "Sentiment") {
      setSortColumn(popupColumn);
      setOrdering(popupSentimentOrdering);
      setSelectedSentimentIDs(popupSentimentIDs);
    } else if (popupColumn === "Theme") {
      setSortColumn(popupColumn);
      setOrdering(popupThemeOrdering);
      setSelectedThemeIDs(popupThemeIDs);
    } else if (popupColumn === "Date") {
      setSortColumn(popupColumn);
      setOrdering(popupDateOrdering);
    } else if (popupColumn === "EngagementScore") {
      setSortColumn(popupColumn);
      setOrdering(popupEngagementOrdering);
    } else if (popupColumn === "Message") {
      setSortColumn("");
      setOrdering(false);
      setFilterKeyword(popupMessageKeyword);
    } else if (popupColumn === "Sender") {
      setSortColumn("Sender");
      setOrdering(popupSenderOrdering);
      setSelectedAccountIDs(popupAccountIDs);
    }
    setShowSort(false);
  }, [
    popupColumn,
    popupAudienceOrdering,
    popupSentimentOrdering,
    popupThemeOrdering,
    popupDateOrdering,
    popupEngagementOrdering,
    popupSenderOrdering,
    popupSentimentIDs,
    popupThemeIDs,
    popupMessageKeyword,
    popupAccountIDs,
  ]);

  const handleClearFilter = useCallback(() => {
    setSortColumn("");
    setOrdering(false);
    if (popupColumn === "Sentiment") {
      setSelectedSentimentIDs([]);
    } else if (popupColumn === "Theme") {
      setSelectedThemeIDs([]);
    } else if (popupColumn === "Message") {
      setFilterKeyword("");
    } else if (popupColumn === "Sender") {
      setAccountKeyword("");
      setSelectedAccountIDs([]);
    }
    setShowSort(false);
  }, [popupColumn]);

  const renderSortPart = useCallback(() => {
    return (
      <>
        <div className="title">SORT</div>
        <div className="switch-sort">
          <div
            className={checkPopupOrdering ? "asc active" : "asc"}
            onClick={() => handlePopupOrdering(popupColumn, false)}
          >
            <div className="az">
              <span className="a">A</span>
              <span className="z">Z</span>
            </div>
            <div className="ascending">Ascending</div>
          </div>
          <div
            className={!checkPopupOrdering ? "desc active" : "desc"}
            onClick={() => handlePopupOrdering(popupColumn, true)}
          >
            <div className="az">
              <span className="a">A</span>
              <span className="z">Z</span>
            </div>
            <div className="descending">Descending</div>
          </div>
        </div>
      </>
    );
  }, [checkPopupOrdering, popupColumn]);
  const renderCheckPart = useCallback(() => {
    return (
      <div className="filter-check-wrapper">
        <label className="check-container">
          <input
            type="checkbox"
            name="checkAllProfile"
            className="check-input"
            checked={checkAllSelect}
            onChange={(ev) => handleCheckAll(ev)}
          />
          <span className="check-title">(Select All)</span>
          <span className="checkmark"></span>
        </label>
        {popupColumn === "Sentiment" &&
          sentimentData.map((st, idx) => (
            <label
              className="check-container"
              key={`sentiment-filter-${st.id}`}
            >
              <input
                type="checkbox"
                name="checkAllProfile"
                className="check-input"
                value={st.id}
                checked={popupSentimentIDs.findIndex((sl) => sl === st.id) >= 0}
                onChange={handleSentimentCheck}
              />
              <span className="check-title">{st.label}</span>
              <span className="checkmark"></span>
            </label>
          ))}
        {popupColumn === "Theme" &&
          themeData.map((th, idx) => (
            <label
              className="check-container"
              key={`sentiment-filter-${th.id}`}
            >
              <input
                type="checkbox"
                name="checkAllProfile"
                className="check-input"
                value={th.id}
                checked={popupThemeIDs.findIndex((sl) => sl === th.id) >= 0}
                onChange={handleThemeCheck}
              />
              <span className="check-title">{th.label}</span>
              <span className="checkmark"></span>
            </label>
          ))}
        {popupColumn === "Sender" &&
          searchedAccounts.map((ac) => (
            <label
              className="check-container"
              key={`sentiment-filter-${ac.id}`}
            >
              <input
                type="checkbox"
                name="checkAllProfile"
                className="check-input"
                value={ac.id}
                checked={popupAccountIDs.findIndex((sl) => sl === ac.id) >= 0}
                onChange={handleAccountCheck}
              />
              <span className="check-title">{ac.label}</span>
              <span className="checkmark"></span>
            </label>
          ))}
      </div>
    );
  }, [
    popupColumn,
    sentimentData,
    searchedAccounts,
    popupSentimentIDs,
    popupThemeIDs,
    popupAccountIDs,
  ]);
  const renderButtonPart = useCallback(() => {
    return (
      <div className="filter-button-wrapper">
        <BlueButton
          title="Apply Filter"
          disabled={!isPopupUpdated}
          onClick={handleApplyFilter}
        />
        <BlueButton title="Clear Filter" onClick={handleClearFilter} />
      </div>
    );
  }, [isPopupUpdated, handleApplyFilter]);

  const ref = useDetectClickOutside({ onTriggered: () => setShowSort(false) });

  return (
    <div
      ref={ref}
      className="sort-dialog"
      onClick={(ev) => ev.stopPropagation()}
      style={{
        left: leftPos,
      }}
    >
      {(popupColumn === "Audience" ||
        popupColumn === "Date" ||
        popupColumn === "EngagementScore") && (
        <>
          {renderSortPart()}
          {renderButtonPart()}
        </>
      )}
      {(popupColumn === "Sentiment" || popupColumn === "Theme") && (
        <>
          {renderSortPart()}
          <div className="divider" />
          {renderCheckPart()}
          {renderButtonPart()}
        </>
      )}
      {popupColumn === "Message" && (
        <>
          <div className="title">FILTER</div>
          <div className="filter-input-wrapper">
            <img src={SearchIcon} alt="search" />
            <input
              className="atlas-input"
              placeholder="Search..."
              value={popupMessageKeyword}
              onChange={(e) => {
                setPopupMessageKeyword(e.target.value);
                setIsPopupUpdated(e.target.value.length >= 3);
              }}
            />
          </div>
          {renderButtonPart()}
        </>
      )}
      {popupColumn === "Sender" && (
        <>
          {renderSortPart()}
          <div className="divider" />
          <div className="title">FILTER</div>
          <div className="filter-input-wrapper">
            <img src={SearchIcon} alt="search" />
            <input
              className="atlas-input"
              placeholder="Search..."
              value={popupAccountKeyword}
              onChange={(e) => {
                setPopupAccountKeyword(e.target.value);
                setIsPopupUpdated(e.target.value.length >= 3);
                setAccountKeyword(e.target.value);
                setPopupAccountIDs([]);
              }}
            />
          </div>
          {renderCheckPart()}
          {renderButtonPart()}
        </>
      )}
    </div>
  );
}

export default VoiceFilterPopup;
