import React, { useMemo, useState } from "react";
import LinesEllipsis from "react-lines-ellipsis";
import moment from "moment";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";

import StarFilled from "assets/images/icons/star-filled.svg";
import StarEmpty from "assets/images/icons/star-empty.svg";

import CircleProgress from "components/Page/CircleProgress";
import { API_ENHANCED_PROFILE } from "constants/routes";

interface IGraphPopup {
  position: { x: number; y: number };
  handleClose: () => void;
  data: AtlasMach.IAccountMessages;
  node: AtlasMach.INode;
  onStarred: () => void;
  dataSources: AtlasMach.ISource[];
  sideOption: AtlasMach.UISideOption;
  authToken: string;
  openMenu: (menu: string) => void;
  setAudienceAnalysis: (aa: AtlasMach.IAASection) => void;
}

export default function GraphPopup({
  position,
  handleClose,
  data,
  node,
  onStarred,
  dataSources,
  sideOption,
  authToken,
  openMenu,
  setAudienceAnalysis,
}: IGraphPopup) {
  const [aaStatus, setAAStatus] = useState(1);

  const showAAButton = useMemo(() => {
    const curSource = dataSources.find((ds) => ds.id === sideOption.source);
    return curSource && curSource.name === "Twitter";
  }, [dataSources, sideOption]);

  const handleMessage = (msg) => {
    if (msg.ingestionMeta.permaLink) window.open(msg.ingestionMeta.permaLink);
  };

  const handleAA = async (e) => {
    e.stopPropagation();
    const response = await fetch(
      API_ENHANCED_PROFILE.replace("$1", node.accountId).replace(
        "$2",
        sideOption.topic
      ),
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const dataAA = await response.json();
    setAAStatus(dataAA.status);
    if (dataAA.message && !dataAA.url) {
      if (dataAA.status === 3 || dataAA.status === 4 || dataAA.status === 5) {
        toast(dataAA.message, {
          position: "top-left",
          type: toast.TYPE.ERROR,
        });
      } else if (dataAA.status === 0 || dataAA.status === 2) {
        toast(dataAA.message, {
          position: "top-left",
          type: toast.TYPE.INFO,
        });
      }
    }

    if (dataAA.url) {
      // handle profile popup
      const sectionResp = await fetch(dataAA.url, {
        method: "GET",
      });
      const aaData = await sectionResp.json();
      setAudienceAnalysis({
        ...aaData,
        name: node.label,
        profileImage: data.account.profileImage,
      });
      openMenu("aa");
    }
  };

  return (
    <div
      className="graph-popup"
      style={{
        top: `${position.y}px`,
        left: `${position.x - 330}px`,
        background: node.color,
      }}
    >
      <div className="account-name">{data.account.name}</div>
      <div className="account-progress-status">
        <CircleProgress percent={node.sizePercent} color="#1C3D4D" />
        <span>{node.sizePercent.toFixed(2)}%</span>
      </div>

      <div className="message-wrapper">
        {data.messages.map((msg) => (
          <div
            className="message-item"
            key={msg.id}
            onClick={(e) => {
              e.stopPropagation();
              handleMessage(msg);
            }}
          >
            <div className="message-header">
              <div className="message-avatar">
                {data.account.profileImage && (
                  <img src={data.account.profileImage} alt="" />
                )}
              </div>
              <span>{data.account.accountName}</span>
              <span style={{ marginLeft: 10 }}>
                {moment(msg.ingestionMeta.messageDate).format("MM/DD/YYYY")}
              </span>
            </div>
            <LinesEllipsis
              text={msg.message}
              maxLine={4}
              ellipsis="..."
              trimRight
              basedOn="letters"
              className="message-content"
            />
          </div>
        ))}
      </div>

      <div className={`social-links ${showAAButton ? "aactive" : ""}`}>
        {data.account.facebookUrl && (
          <a href={data.account.facebookUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
        )}
        {data.account.instagramUrl && (
          <a href={data.account.instagramUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        )}
        {data.account.twitterUrl && (
          <a href={data.account.twitterUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faTwitter} />
          </a>
        )}
        {data.account.linkedInUrl && (
          <a href={data.account.linkedInUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        )}
        {data.account.redditUrl && (
          <a href={data.account.redditUrl} target="_blank" rel="noreferrer">
            <FontAwesomeIcon icon={faReddit} />
          </a>
        )}
      </div>
      <div
        className="popup-close"
        style={{
          color: node.color,
        }}
        onClick={(e) => {
          e.stopPropagation();
          if (handleClose) handleClose();
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
      <div
        className={`popup-like${node.isStarred ? " star" : ""} ${
          showAAButton ? "aactive" : ""
        }`}
        onClick={(e) => {
          e.stopPropagation();
          onStarred();
        }}
      >
        {node.isStarred ? (
          <img style={{ width: 36, height: 36 }} src={StarFilled} alt="star" />
        ) : (
          <img style={{ width: 36, height: 36 }} src={StarEmpty} alt="star" />
        )}
      </div>
      {showAAButton && (aaStatus === 1 || aaStatus === 99) && (
        <div className="button-analysis" onClick={handleAA}>
          <div className="button-text">
            <span>Audience Analysis</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
      {showAAButton && aaStatus === 2 && (
        <div className="button-analysis disabled">
          <div className="button-text">
            <span>Audience Analysis in Progress</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
      {showAAButton && aaStatus === 3 && (
        <div className="button-analysis disabled">
          <div className="button-text">
            <span>No Audience Analysis Available</span>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      )}
    </div>
  );
}
