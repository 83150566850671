import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Constants
import * as ROUTES from "constants/routes";

import { LoginCallback } from "pages/auth/Home/logincallback";

import Home from "pages/auth/Home";
import GraphScreen from "pages/GraphScreen";
import ChartScreen from "pages/ChartScreen";
import StarProfileScreen from "pages/StarProfileScreen";
import InsightScreen from "pages/InsightScreen";
import NotificationScreen from "pages/NotificationScreen";
import PathwayScreen from "pages/PathwayScreen";
import MatrixScreen from "pages/MatrixScreen";

function router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/callback" element={<LoginCallback />} />
        <Route path={ROUTES.HCP} element={<GraphScreen />} />
        <Route path={ROUTES.TOPIC} element={<ChartScreen />} />
        <Route path={ROUTES.STARRED} element={<StarProfileScreen />} />
        <Route path={ROUTES.INSIGHT} element={<InsightScreen />} />
        <Route path={ROUTES.NOTIFICATION} element={<NotificationScreen />} />
        <Route path={ROUTES.PATHWAYS} element={<PathwayScreen />} />
        <Route path={ROUTES.CONVERSATIONAL} element={<MatrixScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default router;
