import { connect } from "react-redux";
import { hideMenu } from "redux/actions";
import MenuMain from "./MenuMain";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  mode: state.ui.mode,
});

const mapDispatchToProps = {
  hideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMain);
