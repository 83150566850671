import { connect } from "react-redux";
import {
  openMenu,
  hideMenu,
  updateSideOption,
  updatePercent,
  setTopics,
  setSubTopics,
  setSources,
  setAudiences,
  setIsLoading,
  pushChart,
} from "redux/actions";
import SideMenu from "./SideMenu";

const mapStateToProps = (state: AtlasMach.StoreState, ownProps) => ({
  menu: state.ui.headerMenu,
  mode: state.ui.mode,
  percent: state.ui.percent,
  sideOption: state.ui.sideOption,
  dataTopics: state.data.topics,
  dataSubtopics: state.data.subtopics,
  dataSources: state.data.sources,
  dataAudiences: state.data.audiences,
  starAccounts: state.data.starAccounts,
  authToken: state.data.auth_token,
  chartStack: state.data.chartStack,
});

const mapDispatchToProps = {
  openMenu,
  hideMenu,
  updateSideOption,
  updatePercent,
  pushChart,
  setTopics,
  setSubTopics,
  setSources,
  setAudiences,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideMenu);
