import { connect } from "react-redux";
import { switchViewMode, setExportData, setIsLoading } from "redux/actions";
import MatrixScreen from "./MatrixScreen";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  menu: "",
  sideOption: state.ui.sideOption,
  chartStack: state.data.chartStack,
  authToken: state.data.auth_token,
  loading: state.ui.isLoading,
});

const mapDispatchToProps = {
  switchViewMode,
  setExportData,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatrixScreen);
