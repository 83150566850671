import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserManager } from "utils/authConst";
import { useCookies } from "react-cookie";
import * as ROUTES from "constants/routes";

export default function Home() {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(["userobj"]);

  useEffect(() => {
    UserManager.events.addUserSignedOut(function () {
      console.log("User signed out of IdentityServer");
    });
    UserManager.getUser().then(function (user) {
      if (user) {
        setCookie("userobj", user, { path: "/" });
        navigate(ROUTES.HCP, { replace: true });
      } else {
        console.log("User not logged in");
        UserManager.signinRedirect();
      }
    });
  }, []);

  return (
    <>
      <div>Redirecting</div>
    </>
  );
}
