import React, { useEffect, useState } from "react";
import { Oval } from "react-loader-spinner";
import { ToastContainer } from "react-toastify";
import { useCookies } from "react-cookie";
import { Helmet } from "react-helmet";

import { UserManager } from "utils/authConst";

import Logo from "components/Page/Logo";
import Header from "components/Page/Header";
import SideMenu from "components/Page/SideMenu";

import backgroundImage from "assets/images/normal-back.png";
import "./styles.scss";
import { Navigate } from "react-router-dom";
import { API_IDENTITY } from "constants/routes";

interface IPage {
  title: string;
  loading: boolean;
  children: React.ReactNode;
  setAPIToken: (token: string) => void;
}

function Page({ title, loading, children, setAPIToken }: IPage) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [cookies, setCookie] = useCookies(["userobj"]);

  useEffect(() => {
    const identityAPI = async (user) => {
      setAPIToken(user.access_token);
      setCookie("userobj", user, { path: "/" });
      try {
        const response = await fetch(API_IDENTITY, {
          headers: {
            Authorization: `Bearer ${user.access_token}`,
          },
        });
        if (response.status !== 200) {
          UserManager.signoutRedirect();
        }
        setIsLoggedIn(true);
      } catch (ex) {
        UserManager.signoutRedirect();
      }
    };

    UserManager.getUser().then((user) => {
      if (user) {
        identityAPI(user);
      } else {
        if (cookies.userobj) {
          identityAPI(cookies.userobj);
        } else {
          UserManager.signoutRedirect();
        }
      }
    });
  }, []);

  if (isLoggedIn === false) {
    return <Navigate to="/" />;
  } else if (isLoggedIn) {
    return (
      <div className="page-container">
        <Helmet>
          <title>Mach9 Atlas</title>
        </Helmet>
        <ToastContainer />
        <img id="background" src={backgroundImage} alt="background" />
        <div className="header-wrapper">
          <Logo />
          <Header title={title} />
        </div>
        <SideMenu />
        <div className="content-wrapper">{children}</div>
        {loading && (
          <div className="load-wrapper">
            <div className="load-center">
              <Oval
                color="#78D2F1"
                height={40}
                width={40}
                secondaryColor="white"
                strokeWidth={5}
              />
              <span>Loading, please be patient</span>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return <span>...</span>;
  }
}

export default Page;
