import { config } from "../config";
import { UserManager as ClientUserManager } from "oidc-client";

export const AUTH_CONFIG = {
  authority: config.AUTHORITY,
  client_id: config.CLIENT_ID,
  redirect_uri: config.BASE_URL + "/callback",
  response_type: config.RESPONSE_TYPE,
  scope: config.SCOPE,
  post_logout_redirect_uri: config.BASE_URL,
};

var mgr: ClientUserManager | undefined = undefined;

export const HS_AUTH_CONFIG = {
  authority: config.AUTHORITY,
  client_id: config.CLIENT_ID,
  redirect_uri: config.BASE_URL + "/hs/callback",
  response_type: config.RESPONSE_TYPE,
  scope: config.SCOPE,
  post_logout_redirect_uri: config.BASE_URL + "/hs/",
};

if (window.location.pathname.startsWith("/hs")) {
  mgr = new ClientUserManager(HS_AUTH_CONFIG);
} else {
  mgr = new ClientUserManager(AUTH_CONFIG);
}

export const UserManager: ClientUserManager = mgr;

export const CHANGE_PASSWORD_ROUTE = config.CHANGE_PASSWORD_ROUTE || "";
