/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Modal from "react-modal";
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import { Range, getTrackBackground } from "react-range";
import debounce from "lodash/debounce";

import { convertToDt, compressJson, replaceAll } from "utils/convertData";
import {
  API_NOTIFICATION_GET,
  API_NOTIFICATION_READ,
  API_SUPPORT,
  API_CHART_EXPORT,
} from "constants/routes";
import moment from "moment";

import BellIcon from "assets/images/icons/bell.svg";
import SettingIcon from "assets/images/icons/setting.svg";
import AccountIcon from "assets/images/icons/account.svg";
import SearchIcon from "assets/images/icons/search.svg";
import CircleProgress from "components/Page/CircleProgress";
import MenuMain from "components/Page/Menu/MenuMain";
import MenuUser from "components/Page/Menu/MenuUser";
import MenuGlossary from "components/Page/Menu/MenuGlossary";
import MenuNotification from "components/Page/Menu/MenuNotification";
import ChevronDown from "assets/images/icons/chevron_down.svg";
import ChevronUp from "assets/images/icons/chevron_up.svg";
import ExportIcon from "assets/images/icons/export.svg";
import { API_GLOSSARY } from "constants/routes";
import BlueButton from "../BlueButton";
import { HEADER_ZOOM_GROUP_CT } from "constants/map";
import { dataEngagements } from "utils/constants";

interface HeaderProps {
  title: string;
  menu: string;
  percent: number;
  profileCt: number;
  mode: string;
  sideOption: AtlasMach.UISideOption;
  dataTopics: AtlasMach.ITopic[];
  dataSubTopics: AtlasMach.ISubtopic[];
  dataSources: AtlasMach.ISource[];
  dataAudiences: AtlasMach.IAudience[];
  starAccounts: AtlasMach.IStarredAccount[];
  heatMaps: AtlasMach.IHeatMapData[];
  heatAccounts: AtlasMach.IHeatAccountData[];
  chartStack: { [key: string]: AtlasMach.IChartData };
  chartRef: any;
  authToken: string;
  exportData: any;
  setIsLoading: (loading: boolean) => void;
  openMenu: (menu: string) => void;
  hideMenu: () => void;
  setAccount: (account: AtlasMach.INode | undefined) => void;
  updatePercent: (percent: number) => void;
}

interface IVoiceExport {
  subTopic: string;
  audience: string;
  key: string;
  message: string;
  messageDate: string;
  messageLink: string;
  messageSentiment: string;
  theme: string;
}

function Header({
  title,
  menu,
  percent,
  updatePercent,
  openMenu,
  hideMenu,
  profileCt,
  mode,
  sideOption,
  dataTopics,
  dataSubTopics,
  dataSources,
  dataAudiences,
  starAccounts,
  heatMaps,
  heatAccounts,
  chartStack,
  chartRef,
  setAccount,
  authToken,
  exportData,
  setIsLoading,
}: HeaderProps) {
  const [keyword, setKeyword] = useState("");
  const [currentChartData, setCurrentChartData] =
    useState<AtlasMach.IChartData>();
  const [suggestionList, setSuggestionList] = useState<AtlasMach.INode[]>([]);
  const [glossaries, setGlossaries] = useState<AtlasMach.IGlossary[]>([]);
  const [notifications, setNotifications] = useState<AtlasMach.INotification[]>(
    []
  );
  const [contactOpen, setContactOpen] = useState(false);
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [downloadName, setDownloadName] = useState("Atlas");
  const [supportMessage, setSupportMessage] = useState("");
  const [sliderValue, setSliderValue] = useState<number>(1);

  useEffect(() => {
    setSliderValue(percent);
  }, []);

  useEffect(() => {
    setSliderValue(HEADER_ZOOM_GROUP_CT);
  }, [sideOption.topic, sideOption.source]);

  useEffect(() => {
    const key = compressJson({
      topic: sideOption.topic,
      source: sideOption.source,
      dateFrom: sideOption.dateFrom,
      dateTo: sideOption.dateTo,
    });
    setCurrentChartData(chartStack[key]);
  }, [chartStack, sideOption.topic, sideOption.source]);

  const filteredNodes = useMemo(() => {
    if (!currentChartData) return [];
    const fDA = dataAudiences.filter(
      (da) => sideOption.audiences.findIndex((ad) => da.id === ad) > -1
    );

    const fNodes =
      sideOption.audiences.length > 0
        ? currentChartData.nodes.filter(
            (node) => fDA.findIndex((fda) => fda.id === node.audienceId) > -1
          )
        : currentChartData.nodes;

    return fNodes;
  }, [currentChartData, dataAudiences, sideOption.audiences]);

  const shownNodesPercent = useMemo(() => {
    if (!currentChartData) return;
    const sum = filteredNodes
      .filter(
        (node) =>
          node.orderID >=
          currentChartData.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
      )
      .reduce((sum, node) => sum + node.sizePercent, 0);
    return sum.toFixed(2);
  }, [filteredNodes, percent]);

  const mainMenuRightPos = useMemo(() => {
    if (mode === "graph") return 840;
    return 130;
  }, [mode]);

  useEffect(() => {
    const loadGlossary = async () => {
      const response = await fetch(API_GLOSSARY, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      setGlossaries(data);
      return data;
    };

    if (authToken) loadGlossary();
  }, [authToken]);

  useEffect(() => {
    setKeyword("");
    setAccount(undefined);

    const loadNotification = async () => {
      const response = await fetch(
        API_NOTIFICATION_GET.replace("$1", sideOption.topic).replace(
          "$2",
          sideOption.source
        ),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = await response.json();
      setNotifications(data);
    };

    if (sideOption.topic && sideOption.source) {
      loadNotification();
    }
  }, [sideOption]);

  const readNotification = async (readMsg: AtlasMach.INotification) => {
    const notifyAccount = filteredNodes.find(
      (node) => node.key === readMsg.accountName
    );
    setAccount(notifyAccount);
    if (readMsg.status === 1) {
      setNotifications((prev) =>
        prev.map((msg) =>
          msg.id === readMsg.id
            ? {
                ...msg,
                status: 2,
              }
            : msg
        )
      );
      await fetch(API_NOTIFICATION_READ.replace("$1", readMsg.id), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
    }
  };

  const handleChangeInput = (v) => {
    setKeyword(v);
    if (!currentChartData || v === "") setSuggestionList([]);
    else
      setSuggestionList(
        filteredNodes.filter((node) =>
          node.label.toLowerCase().startsWith(v.toLowerCase())
        )
      );
  };

  const handleSetAccount = (account: AtlasMach.INode) => {
    setKeyword(account.label);
    setSuggestionList([]);
    setAccount(account);
  };

  const handleExport = useCallback(
    async (ev) => {
      const sourceObj = dataSources.find((ds) => ds.id === sideOption.source);
      const topicObj = dataTopics.find((dt) => dt.id === sideOption.topic);
      if (mode === "graph") {
        if (!sourceObj) return;

        const csv = [
          [
            "Name",
            "Account Name",
            `${sourceObj.name} URL`,
            "Audience",
            "Starred (Y/N)",
            "Centricity Score",
          ],
        ];
        if (!currentChartData) return;

        const nodes = (
          sideOption.audiences.length === 0
            ? currentChartData.nodes
            : currentChartData.nodes.filter(
                (node) =>
                  sideOption.audiences.findIndex(
                    (ad) => ad === node.audienceId
                  ) > -1
              )
        ).filter(
          (node) =>
            node.orderID >=
            currentChartData.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
        );

        nodes.forEach((node) => {
          const ad = dataAudiences.find((da) => da.id === node.audienceId);
          csv.push([
            node.label.replace(/\n|\r/g, "").replace(/"/g, '""'),
            node.key,
            node.profileLink,
            ad ? ad.name : "",
            node.isStarred ? "Y" : "N",
            node.sizePercent.toString(),
          ]);
        });

        setCsvData(csv);
        const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
        const fileName = `Atlas[${sourceObj.name}][${topicObj?.name}][${moment(
          dt.dt_from
        ).format("YYYY-MM")}][${moment(dt.dt_to).format("YYYY-MM")}]`;
        setDownloadName(fileName);
        setTimeout(() => {
          csvLink.current.link.click();
          chartRef.current.export({ type: "png" }).then((exportedImage) => {
            exportedImage.download(`${fileName}.png`);
          });
        }, 500);
      } else if (mode === "chart") {
        setIsLoading(true);
        const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
        const response = await fetch(
          API_CHART_EXPORT.replace("$1", sideOption.topic).replace(
            "$2",
            sideOption.source
          ),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              startDate: dt.dt_from,
              endDate: dt.dt_to,
              selectedAudiences: sideOption.audiences,
              selectedSubtopics: sideOption.subtopics,
            }),
          }
        );
        const exportData: IVoiceExport[] = await response.json();

        const topicObj = dataTopics.find((dt) => dt.id === sideOption.topic);
        const sourceObj = dataSources.find((ds) => ds.id === sideOption.source);

        if (!currentChartData) return;
        const nodes =
          sideOption.audiences.length === 0
            ? currentChartData.nodes
            : currentChartData.nodes.filter(
                (node) =>
                  sideOption.audiences.findIndex(
                    (ad) => ad === node.audienceId
                  ) > -1
              );

        const csv = [
          [
            "Source",
            "Sender Name",
            "Sender Username",
            "Sender Influence Score",
            "Sender Audience",
            "Sender Link to Profile",
            "Message",
            "Link to Message",
            "Message Date",
            "Overall Message Sentiment",
            "Theme",
          ],
        ];
        exportData.forEach((ed) => {
          const node = nodes.find((node) => node.key === ed.key);
          if (node)
            csv.push([
              sourceObj?.name || "",
              node?.label || "",
              ed.key,
              node?.sizePercent.toString() || "",
              ed.audience,
              node?.profileLink || "",
              ed.message.replace(/\n|\r/g, "").replace(/"/g, '""'),
              ed.messageLink,
              ed.messageDate,
              ed.messageSentiment,
              ed.theme,
            ]);
        });

        setIsLoading(false);

        setCsvData(csv);
        const fileName = `Atlas[${topicObj?.name}]_TopicListening`;
        setDownloadName(fileName);
        setTimeout(() => {
          csvLink.current.link.click();
        }, 500);
      } else if (mode === "accounts") {
        const csv = [
          ["First Name", "Last Name", "Centricity Score", "Date Added"],
        ];
        starAccounts
          .filter(
            (ac) =>
              ac.account.audiences.findIndex(
                (acAu) =>
                  sideOption.audiences.findIndex(
                    (au) => au === acAu.topicAudienceId
                  ) >= 0
              ) >= 0
          )
          .forEach((ac) => {
            const first_name = ac.account.name
              .split(" ")
              .slice(0, -1)
              .join(" ");
            const last_name = ac.account.name.split(" ").slice(-1).join(" ");
            const score = ac.score;
            const dateon = ac.createdOn;
            csv.push([
              first_name,
              last_name,
              score.toString(),
              moment(dateon).format("YYYY-MM-DD"),
            ]);
          });
        setCsvData(csv);
        const fileName = `Atlas[${topicObj?.name}][${sourceObj?.name}]_StarredAccounts`;
        setDownloadName(fileName);
        setTimeout(() => {
          csvLink.current.link.click();
        }, 500);
      } else if (mode === "insight") {
        // heat map
        const allHeatMapLabels: string[] = [];
        heatMaps.forEach((hm) => {
          allHeatMapLabels.push(...hm.data.map((dt) => dt.label));
        });
        const heatMapLabels = [...new Set(allHeatMapLabels)].sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );
        const newHeatMap = heatMaps.map((hm) => {
          const normalizedData = heatMapLabels.map((lbDate) => {
            let existValue = hm.data.find((hmd) => hmd.label === lbDate);
            return {
              label: lbDate,
              value: existValue?.value || 0,
            };
          });

          return { ...hm, data: normalizedData };
        });
        const csvAudience = [
          ["", ...heatMapLabels.map((hml) => moment(hml).format("MMM-YY"))],
        ];
        newHeatMap.forEach((nhm) => {
          csvAudience.push([
            nhm.label,
            ...nhm.data.map((nhmd) => `${nhmd.value}%`),
          ]);
        });
        const audienceFileName = `Atlas[${topicObj?.name}][${sourceObj?.name}]_Audience_Centricity`;
        // heat account
        const allHeatAccountLabels: string[] = [];
        heatAccounts.forEach((ha) => {
          allHeatAccountLabels.push(...ha.data.map((dt) => dt.label));
        });
        const heatAccountLabels = [...new Set(allHeatAccountLabels)].sort(
          (a, b) => new Date(a).getTime() - new Date(b).getTime()
        );
        const newHeatAccounts = heatAccounts.map((hm) => {
          const normalizedData = heatAccountLabels.map((lbDate) => {
            let existValue = hm.data.find((hmd) => hmd.label === lbDate);
            return {
              label: lbDate,
              value: existValue?.value || 0,
            };
          });
          const node = currentChartData?.nodes.find(
            (node) => node.key === hm.label
          );
          return {
            data: normalizedData,
            color: node ? node.color : "white",
            label: node ? node.label : hm.label,
          };
        });
        const csvAccount = [
          ["", ...heatAccountLabels.map((hml) => moment(hml).format("MMM-YY"))],
        ];
        newHeatAccounts.forEach((nhm) => {
          csvAccount.push([
            nhm.label,
            ...nhm.data.map((nhmd) => `${nhmd.value}%`),
          ]);
        });
        const accountFileName = `Atlas[${topicObj?.name}][${sourceObj?.name}]_StarredAccount_Centricity`;
        // export
        setCsvData(csvAudience);
        setDownloadName(audienceFileName);
        setTimeout(() => {
          csvLink.current.link.click();
          setCsvData(csvAccount);
          setDownloadName(accountFileName);
          setTimeout(() => {
            csvLink.current.link.click();
          }, 500);
        }, 500);
      } else if (mode === "matrix") {
        const csv = [
          ["Account"].concat(exportData.columns),
          ...exportData.data,
        ];
        setCsvData(csv);
        const fileName = `Atlas[${topicObj?.name}][${sourceObj?.name}]_ConversationalMatrix`;
        setDownloadName(fileName);
        setTimeout(() => {
          csvLink.current.link.click();
        }, 500);
      } else if (mode === "pathways") {
        const csv: string[][] = [
          [
            "Account",
            "Audience",
            "Centricity Score",
            "Engagement Likelihood",
            "Category",
          ],
        ];
        for (let category of ["Mach9 Rec", "Quick Wins", "Top 25", "Starred"]) {
          for (let row of exportData[category]) {
            if (category === "Starred") {
              csv.push([
                String(row.account.accountName),
                "",
                String(`${row.score.toFixed(2)}%`),
                "",
                category,
              ]);
            } else {
              csv.push([
                String(row.account),
                String(row.audience),
                String(`${row.score.toFixed(2)}%`),
                dataEngagements.find((de) => de.key === row.likelihood)
                  ?.label || "",
                category,
              ]);
            }
          }
        }
        setCsvData(csv);
        const fileName = `Atlas[${topicObj?.name}][${sourceObj?.name}]_Pathways`;
        setDownloadName(fileName);
        setTimeout(() => {
          csvLink.current.link.click();
        }, 500);
      }
    },
    [currentChartData, percent, exportData]
  );

  const handleSupport = () => {
    hideMenu();
    setContactOpen(true);
    setSupportMessage("");
  };

  const handleSubmitContact = async () => {
    await fetch(API_SUPPORT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: `"${supportMessage}"`,
    });
    setContactOpen(false);
  };

  const handleSliderChange = debounce((v: number) => {
    updatePercent(v);
  }, 200);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: "#0D1D28",
      borderRadius: 20,
      border: "2px solid #78D2F1",
    },
    overlay: {
      backgroundColor: "transparent",
    },
  };

  Modal.setAppElement("#root");

  const csvLink = useRef<any>();

  return (
    <div className="header-container">
      <ReactTooltip />
      <Modal
        isOpen={contactOpen}
        onRequestClose={() => setContactOpen(false)}
        style={customStyles}
      >
        <label>Help &amp; Support</label>
        <div className="textarea-wrapper">
          <textarea
            className="contact-textarea"
            placeholder="Send Us a Message"
            value={supportMessage}
            onChange={(ev) => setSupportMessage(ev.target.value)}
          />
        </div>
        <div className="button-wrapper">
          <BlueButton title="Submit" onClick={handleSubmitContact} />
        </div>
      </Modal>
      <CSVLink
        className="hidden"
        data={csvData}
        ref={csvLink}
        filename={`${downloadName}.csv`}
      >
        Download me
      </CSVLink>
      <div className="header-menu-wrapper">
        <div className="menu">
          {mode === "graph" && (
            <div className="notification">
              {notifications.filter((msg) => msg.status === 1).length > 0 && (
                <div className="red-point"></div>
              )}
              <img
                src={BellIcon}
                alt="bell"
                onClick={() => openMenu("notification")}
              />
            </div>
          )}
          <img src={ExportIcon} alt="menu" onClick={handleExport} />
          <img src={SettingIcon} alt="cog" onClick={() => openMenu("user")} />
        </div>
        {mode === "graph" && (
          <div className="status">
            <div className="progress-status">
              <div className="slider-wrapper">
                <Range
                  step={1}
                  min={1}
                  max={HEADER_ZOOM_GROUP_CT}
                  values={[sliderValue]}
                  onChange={(values) => {
                    setSliderValue(values[0]);
                    handleSliderChange(values[0]);
                  }}
                  renderTrack={({ props, children }) => (
                    <div
                      onMouseDown={props.onMouseDown}
                      onTouchStart={props.onTouchStart}
                      style={{
                        ...props.style,
                        height: "36px",
                        display: "flex",
                        width: "100%",
                      }}
                    >
                      <div
                        ref={props.ref}
                        style={{
                          height: "5px",
                          width: "100%",
                          borderRadius: "4px",
                          background: getTrackBackground({
                            values: [sliderValue],
                            colors: ["#78D2F1", "#ccc"],
                            min: 0,
                            max: HEADER_ZOOM_GROUP_CT,
                          }),
                          alignSelf: "center",
                        }}
                      >
                        {children}
                      </div>
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "24px",
                        width: "24px",
                        borderRadius: "12px",
                        backgroundColor: "#78D2F1",
                      }}
                    />
                  )}
                />
              </div>
              <div
                className="circle-wrapper"
                data-tip="Total centricity score in current view"
              >
                <CircleProgress
                  percent={Number(shownNodesPercent)}
                  color="#78D2F1"
                />
                <span className="span-progress">{shownNodesPercent}%</span>
              </div>
            </div>
            <div
              className="users-status"
              data-tip="Total Accounts in current view"
            >
              <img src={AccountIcon} alt="account" />
              <span>{profileCt}</span>
            </div>
          </div>
        )}
        {mode === "graph" && (
          <div className="search-bar">
            <img src={SearchIcon} alt="search" />
            <input
              className="atlas-input"
              placeholder="Search Accounts..."
              value={keyword}
              onChange={(e) => handleChangeInput(e.target.value)}
            />
          </div>
        )}
        <div className="main-menu-head">
          {menu !== "main" ? (
            <img
              src={ChevronDown}
              alt="main-down"
              onClick={() => openMenu("main")}
            />
          ) : (
            <img src={ChevronUp} alt="main-up" onClick={() => hideMenu()} />
          )}
        </div>
        <div className="title-bar">
          <label>{title}</label>
        </div>
      </div>
      {suggestionList.length > 0 && (
        <div className="suggestion-list">
          {suggestionList.map((account, idx) => (
            <div
              className="suggestion-item"
              key={`suggestion-item-${idx}`}
              onClick={() => handleSetAccount(account)}
            >
              {account.label}
            </div>
          ))}
        </div>
      )}
      {menu === "main" && <MenuMain rp={mainMenuRightPos} />}
      {menu === "user" && <MenuUser rp={45} handleSupport={handleSupport} />}
      {menu === "glossary" && <MenuGlossary rp={15} data={glossaries} />}
      {menu === "notification" && (
        <MenuNotification
          rp={15}
          data={notifications}
          readMessage={readNotification}
        />
      )}
    </div>
  );
}

export default Header;
