/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from "react";
import queryString from "query-string";
import { toast } from "react-toastify";
import CalendarIcon from "assets/images/icons/calendar.svg";
import ArrowIcon from "assets/images/icons/arrow-down-small.svg";
import { pageRank } from "regraph/analysis";
import "./styles.scss";
import CustomCalendar from "./CustomCalendar";
import { API_TOPIC, API_CHARTDATA, API_SOURCES } from "constants/routes";
import { convertToDt, compressJson } from "utils/convertData";
import { HEADER_ZOOM_GROUP_CT } from "constants/map";

const MONTHS = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

const dataEngagements = [
  { key: 1, label: "Very likely" },
  { key: 2, label: "Likely" },
  { key: 3, label: "Medium" },
  { key: 4, label: "Unlikely" },
  { key: 5, label: "Very unlikely" },
];

interface SubMenuProps {
  title: string;
  children: React.ReactNode;
}

function SideSubMenu({ title, children }: SubMenuProps) {
  return (
    <div className="submenu-form">
      <div className="submenu-title">
        <span>{title}</span>
      </div>
      <div className="submenu-wrapper">
        <div className="submenu-contents">{children}</div>
      </div>
    </div>
  );
}

interface SideMenuProps {
  menu: string;
  mode: string;
  sideOption: AtlasMach.UISideOption;
  dataTopics: AtlasMach.ITopic[];
  dataSubtopics: AtlasMach.ISubtopic[];
  dataSources: AtlasMach.ISource[];
  dataAudiences: AtlasMach.IAudience[];
  authToken: string;
  openMenu: (menu: string) => void;
  hideMenu: () => void;
  updateSideOption: (option: AtlasMach.UISideOption) => void;
  percent: number;
  updatePercent: (percent: number) => void;
  setTopics: (topics: AtlasMach.ITopic[]) => void;
  setSubTopics: (subtopics: AtlasMach.ISubtopic[]) => void;
  setSources: (sources: AtlasMach.ISource[]) => void;
  setAudiences: (audiences: AtlasMach.IAudience[]) => void;
  starAccounts: AtlasMach.IStarredAccount[];
  setIsLoading: (loading: boolean) => void;
  chartStack: { [key: string]: AtlasMach.IChartData };
  pushChart: (key: string, chart: AtlasMach.IChartData) => void;
}

function SideMenu({
  menu,
  openMenu,
  hideMenu,
  mode,
  percent,
  updatePercent,
  sideOption,
  updateSideOption,
  setTopics,
  dataTopics,
  setSubTopics,
  dataSubtopics,
  setSources,
  dataSources,
  setAudiences,
  dataAudiences,
  starAccounts,
  setIsLoading,
  pushChart,
  chartStack,
  authToken,
}: SideMenuProps) {
  const currentSourceKey = useMemo(() => {
    return compressJson({
      topic: sideOption.topic,
      source: sideOption.source,
      dateFrom: sideOption.dateFrom,
      dateTo: sideOption.dateTo,
    });
  }, [sideOption]);

  const dataSourceKeys = useMemo(() => {
    return dataSources.map((ds) => {
      const key = compressJson({
        topic: sideOption.topic,
        source: ds.id,
        dateFrom: sideOption.dateFrom,
        dateTo: sideOption.dateTo,
      });
      const data = chartStack[key];
      let percent = 0;
      if (data) percent = data.percentage;
      return {
        ...ds,
        percent: percent,
      };
    });
  }, [dataSources, chartStack]);

  const currentChartData = useMemo(() => {
    const key = currentSourceKey;
    return chartStack[key];
  }, [sideOption.topic, sideOption.source, chartStack]);

  const generateGraphData = (data: { chartData: AtlasMach.IChartData[] }) => {
    if (!data || !data.chartData) {
      return;
    }
    // for each different source, we need to calculate the pageRank
    const items = data.chartData.map((sourceData) => {
      let item: { [key: string]: any } = {};
      sourceData.nodes.forEach((node) => {
        let gNode = {
          label: {
            text: node.label,
          },
        };
        item[node.key] = gNode;
      });
      sourceData.links.forEach((link) => {
        item[`${link.id1}-${link.id2}`] = {
          id1: link.id1,
          id2: link.id2,
        };
      });
      return item;
    });

    return items;
  };

  const calculateSizeForEachNode = async (
    items,
    data
  ): Promise<AtlasMach.IChartData[]> => {
    console.log("page rank");
    const normalize = function (
      nonOutlierMax,
      outlierMax,
      outlierBoundary,
      current
    ) {
      var scale = 2;
      var result = 0;
      if (current > outlierBoundary) {
        //Scale outliers to 2-8
        result = (current * 4) / outlierMax;
        result = result + 2;
      } else {
        //Scale non-outliers to 1-2
        result = (current * 2) / nonOutlierMax;
        result = result < 1 ? 1 : result;
      }
      return result / scale;
    };
    await Promise.all(
      items.map(async (itemList, i) => {
        const filtered = await pageRank(itemList);
        const filteredValues = Object.values(filtered);

        filteredValues.sort();
        var Q1pos = Math.floor((1 * filteredValues.length) / 4);
        var Q3pos = Math.floor((3 * filteredValues.length) / 4);
        var Q1 = filteredValues[Q1pos];
        var Q3 = filteredValues[Q3pos];
        var IQR = Q3 - Q1;
        var outlierBoundary = Q3 + 1.5 * IQR;

        var nonOutlierMax = 0;
        var outlierMax = Math.max(...filteredValues);
        for (var j: number = 0; j < filteredValues.length; j++) {
          if (
            filteredValues[j] > nonOutlierMax &&
            filteredValues[j] <= outlierBoundary
          ) {
            nonOutlierMax = filteredValues[j];
          }
        }

        data.chartData[i].nodes = data.chartData[i].nodes
          .map((node: AtlasMach.INode) => {
            let id = node.key;

            node.size = normalize(
              nonOutlierMax,
              outlierMax,
              outlierBoundary,
              filtered[id]
            );

            node.sizePercent = filtered[id] * 100;

            return node;
          })
          .sort(
            (a: AtlasMach.INode, b: AtlasMach.INode) =>
              a.sizePercent - b.sizePercent
          )
          .map((node, i) => ({
            ...node,
            orderID: i,
          }));
      })
    );

    data.chartData = data.chartData.map((cd: AtlasMach.IChartData) => {
      const stepsValue: number[] = [0];

      let sum = 0;
      let indicator = 1;
      for (let i = 0; i < cd.nodes.length; ++i) {
        const node = cd.nodes[i];
        sum += node.sizePercent;
        if (sum >= (100 / HEADER_ZOOM_GROUP_CT) * indicator) {
          stepsValue.push(node.orderID - 1);
          indicator++;
        }
      }

      return {
        ...cd,
        percentSteps: stepsValue,
      };
    });

    return data.chartData;
  };

  const loadChartPost = async (topic, from, to) => {
    setIsLoading(true);
    const response = await fetch(API_CHARTDATA.replace("$1", topic), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        startDate: from,
        endDate: to,
      }),
    });
    const data = await response.json();

    const items = generateGraphData(data);
    const chartdata = await calculateSizeForEachNode(items, data);
    chartdata.forEach((cd) => {
      const key = compressJson({
        topic: sideOption.topic,
        source: cd.id,
        dateFrom: sideOption.dateFrom,
        dateTo: sideOption.dateTo,
      });
      pushChart(key, cd);
    });
    setIsLoading(false);
    if (chartdata.length === 0) {
      toast("Data does not exists for selected dates", {
        position: "top-left",
        type: toast.TYPE.WARNING,
      });
    }
  };

  const loadSources = async (topic) => {
    const response = await fetch(API_SOURCES.replace("$1", topic), {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();
    return data;
  };

  const changeTopic = async (topic: string, topics: AtlasMach.ITopic[]) => {
    setIsLoading(true);
    const topicData = topics.find((tp) => tp.id === topic);
    if (!topicData) return;
    const dt_from = new Date(topicData.startDate);
    const dt_to = new Date(topicData.endtDate);

    const orgTopicData = dataTopics.find((dt) => dt.id === topic);
    let sources: AtlasMach.ISource[] = [];
    if (orgTopicData?.sources && orgTopicData.sources.length > 0) {
      sources = orgTopicData.sources;
    } else {
      sources = await loadSources(topicData.id);
      setTopics(
        topics.map((tp) => (tp.id === topic ? { ...tp, sources: sources } : tp))
      );
    }

    setSubTopics(topicData.subtopics);
    setSources(sources);
    setAudiences(topicData.audiences);

    updateSideOption({
      ...sideOption,
      topic: topicData.id,
      dateFrom: {
        year: dt_from.getFullYear(),
        month: dt_from.getMonth(),
      },
      dateTo: {
        year: dt_to.getFullYear(),
        month: dt_to.getMonth(),
      },
      subtopics: [],
      source: sources.length > 0 ? sources[0].id : "",
      audiences: [],
    });
  };

  useEffect(() => {
    // init topics data
    const loadTopics = async () => {
      const response = await fetch(API_TOPIC, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = await response.json();
      if (data.length > 0) {
        const initData = data.map((dt) => ({
          ...dt,
          sources: [],
          chartData: [],
        }));
        setTopics(initData);
        changeTopic(initData[0].id, initData);
      } else {
        // TODO: redirect to error page
        alert("No topics found");
      }
    };

    if (authToken && dataTopics.length === 0) loadTopics();
  }, [authToken]);

  useEffect(() => {
    if (sideOption.topic) {
      const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
      const key = currentSourceKey;
      if (!chartStack[key])
        loadChartPost(sideOption.topic, dt.dt_from, dt.dt_to);
      else setIsLoading(false);

      updatePercent(HEADER_ZOOM_GROUP_CT);
    }
  }, [sideOption.dateFrom, sideOption.dateTo]);

  const calcAudienceCount = useCallback(
    (audienceId) => {
      if (mode === "accounts") {
        return starAccounts.filter(
          (ac) =>
            ac.account.audiences.findIndex(
              (acaa) => acaa.topicAudienceId === audienceId
            ) >= 0
        ).length;
      } else {
        if (currentChartData)
          return currentChartData.nodes
            .filter((node) => node.audienceId === audienceId)
            .filter(
              (node) =>
                node.orderID >=
                currentChartData.percentSteps[HEADER_ZOOM_GROUP_CT - percent]
            ).length;
        return "";
      }
    },
    [currentChartData, percent]
  );

  const handleCalendarMenu = () => {
    if (menu === "calendar") {
      hideMenu();
    } else {
      openMenu("calendar");
    }
  };

  const handleChangeDate = (value) => {
    updateSideOption({
      ...sideOption,
      ...value,
    });
  };

  const handleCheckSubTopic = (ev) => {
    if (ev.target.checked) {
      updateSideOption({
        ...sideOption,
        subtopics: [...sideOption.subtopics, ev.target.value],
      });
    } else {
      updateSideOption({
        ...sideOption,
        subtopics: sideOption.subtopics.filter((ad) => ad !== ev.target.value),
      });
    }
  };

  const handleCheckSource = (ev) => {
    updateSideOption({
      ...sideOption,
      source: ev.target.value,
    });
  };

  const handleCheckAudience = (ev) => {
    if (ev.target.checked) {
      updateSideOption({
        ...sideOption,
        audiences: [...sideOption.audiences, ev.target.value],
      });
    } else {
      updateSideOption({
        ...sideOption,
        audiences: sideOption.audiences.filter((ad) => ad !== ev.target.value),
      });
    }
  };

  const handleCheckEngagements = (ev) => {
    if (ev.target.checked) {
      updateSideOption({
        ...sideOption,
        engagements: [...sideOption.engagements, Number(ev.target.value)],
      });
    } else {
      updateSideOption({
        ...sideOption,
        engagements: sideOption.engagements.filter(
          (ad) => ad !== Number(ev.target.value)
        ),
      });
    }
  };

  const handleStarredCheck = (ev) => {
    updateSideOption({
      ...sideOption,
      starred: ev.target.checked,
    });
  };

  return (
    <div className="sidemenu-container">
      <div className="sidemenu-wrapper">
        {mode !== "accounts" && (
          <div className="menu-calendar">
            <div className="menu-calendar-header" onClick={handleCalendarMenu}>
              <img src={CalendarIcon} alt="calendar" />
              <span>
                {`${MONTHS[sideOption.dateFrom.month]} ${
                  sideOption.dateFrom.year
                }`}{" "}
                -
                {`${MONTHS[sideOption.dateTo.month]} ${sideOption.dateTo.year}`}
              </span>
              <img src={ArrowIcon} alt="arrow" />
            </div>
            {menu === "calendar" && (
              <CustomCalendar
                from={sideOption.dateFrom}
                to={sideOption.dateTo}
                hideMenu={hideMenu}
                onChange={handleChangeDate}
              />
            )}
          </div>
        )}
        <SideSubMenu title="Topic">
          {dataTopics.map((tk) => (
            <label className="radio-container" key={`topic-${tk.id}`}>
              <input
                type="radio"
                name="radioTopic"
                className="radio-input"
                checked={sideOption.topic === tk.id}
                value={tk.id}
                onChange={(ev) => changeTopic(ev.target.value, dataTopics)}
              />
              <span className="radio-title">{tk.name}</span>
              <span className="checkmark"></span>
            </label>
          ))}
        </SideSubMenu>
        {mode === "chart" && (
          <SideSubMenu title="sub-topics">
            {dataSubtopics.map((ds) => (
              <label className="check-container" key={`subtopic-${ds.id}`}>
                <input
                  type="checkbox"
                  name="checkAudiences"
                  className="check-input"
                  value={ds.id}
                  checked={
                    sideOption.subtopics.findIndex((st) => st === ds.id) > -1
                  }
                  onChange={handleCheckSubTopic}
                />
                <span className="check-title">{ds.name}</span>
                <span className="checkmark"></span>
              </label>
            ))}
          </SideSubMenu>
        )}
        <SideSubMenu title="Sources">
          {dataSourceKeys.map((ds) => (
            <label className="radio-container" key={`source-${ds.name}`}>
              <input
                type="radio"
                name="radioSources"
                className="radio-input"
                value={ds.id}
                checked={sideOption.source === ds.id}
                onChange={handleCheckSource}
              />
              <span className="radio-title">{ds.name}</span>
              <span className="radio-percent">{ds.percent}%</span>
              <span className="checkmark"></span>
            </label>
          ))}
        </SideSubMenu>
        {mode !== "insight" && mode !== "notifications" && (
          <SideSubMenu title="Audiences">
            {dataAudiences.map((da) => (
              <label className="check-container" key={`audience-${da.id}`}>
                <input
                  type="checkbox"
                  name="checkAudiences"
                  className="check-input"
                  value={da.id}
                  checked={
                    sideOption.audiences.findIndex((ad) => ad === da.id) > -1
                  }
                  onChange={handleCheckAudience}
                />
                <span className="check-title">
                  {da.name} ({calcAudienceCount(da.id)})
                </span>
                <span className="checkmark"></span>
              </label>
            ))}
          </SideSubMenu>
        )}
        {(mode === "graph" || mode === "matrix") && (
          <SideSubMenu title="Filter">
            <label className="check-container">
              <input
                type="checkbox"
                name="checkAudiences"
                className="check-input"
                value={sideOption.starred ? 1 : 0}
                checked={sideOption.starred}
                onChange={handleStarredCheck}
              />
              <span className="check-title">Starred Accounts</span>
              <span className="checkmark"></span>
            </label>
          </SideSubMenu>
        )}
        {mode === "pathways" && (
          <SideSubMenu title="Engagement Likelihood">
            {dataEngagements.map((da) => (
              <label className="check-container" key={`engagement-${da.key}`}>
                <input
                  type="checkbox"
                  name="checkAudiences"
                  className="check-input"
                  value={da.key}
                  checked={
                    sideOption.engagements.findIndex((ad) => ad === da.key) > -1
                  }
                  onChange={handleCheckEngagements}
                />
                <span className="check-title">{da.label}</span>
                <span className="checkmark"></span>
              </label>
            ))}
          </SideSubMenu>
        )}
      </div>
    </div>
  );
}

export default SideMenu;
