import React from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import { UserManager, CHANGE_PASSWORD_ROUTE } from "utils/authConst";

import MenuItem from "components/Page/Menu/MenuItem";

import GlossaryIcon from "assets/images/icons/glossary.svg";
import GlossaryHoverIcon from "assets/images/icons/glossary-hover.svg";
import PasswordIcon from "assets/images/icons/password.svg";
import PasswordHoverIcon from "assets/images/icons/password-hover.svg";
import HelpIcon from "assets/images/icons/help.svg";
import HelpHoverIcon from "assets/images/icons/help-hover.svg";
import LogoutIcon from "assets/images/icons/logout.svg";
import LogoutHoverIcon from "assets/images/icons/logout-hover.svg";

interface IMenuUser {
  openMenu: (menu: string) => void;
  hideMenu: () => void;
  rp: number;
  handleSupport: () => void;
}

function MenuUser({ openMenu, hideMenu, rp, handleSupport }: IMenuUser) {
  const ref = useDetectClickOutside({ onTriggered: hideMenu });

  return (
    <div className={`menu-dropdown`} style={{ right: rp }} ref={ref}>
      <MenuItem
        icon={GlossaryIcon}
        hoverIcon={GlossaryHoverIcon}
        title="Glossary"
        handleClick={() => openMenu("glossary")}
      />
      <MenuItem
        icon={PasswordIcon}
        hoverIcon={PasswordHoverIcon}
        title="Change Password"
        handleClick={() => {
          hideMenu();
          window.location.href = CHANGE_PASSWORD_ROUTE;
        }}
      />
      <MenuItem
        icon={HelpIcon}
        hoverIcon={HelpHoverIcon}
        title="Help &amp; Support"
        handleClick={handleSupport}
      />
      <MenuItem
        icon={LogoutIcon}
        hoverIcon={LogoutHoverIcon}
        title="Logout"
        handleClick={() => UserManager.signoutRedirect()}
      />
    </div>
  );
}

export default MenuUser;
