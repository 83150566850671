import LZString from "lz-string";

export const convertToDt = (dateFrom, dateTo) => {
  const dt_from = new Date(Date.UTC(dateFrom.year, dateFrom.month, 1, 0, 0, 0));
  let dt_to = new Date(Date.UTC(dateTo.year, dateTo.month + 1, 0, 23, 59, 59));
  if (dateTo.month === 11) {
    dt_to = new Date(Date.UTC(dateTo.year + 1, 0, 0, 23, 59, 59));
  }
  return { dt_from, dt_to };
};

export const convertToDt2 = (dateFrom, dateTo) => {
  const dt_from = `${dateFrom.year}-${dateFrom.month + 1}-1`;
  let dt_to = `${dateTo.year}-${dateTo.month + 2}-1`;
  if (dateTo.month === 11) {
    dt_to = `${dateTo.year + 1}-1-1`;
  }
  return { dt_from, dt_to };
};

export const compressJson = (obj: any) => {
  return LZString.compressToEncodedURIComponent(JSON.stringify(obj));
};

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const replaceAll = (string, search, replace) => {
  return string.split(search).join(replace);
};
