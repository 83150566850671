import React, { useState, useEffect, useMemo, useCallback } from "react";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import PageTemplate from "pages/PageTemplate";

import "./styles.scss";

import SvgSort from "assets/images/icons/sort.svg";
import SvgSortSel from "assets/images/icons/sort-up.svg";
import SvgInfo from "assets/images/icons/icon-info.svg";
import {
  API_MATRIX_OLD,
  API_MATRIX_MENTIONS_OLD,
  API_MATRIX_THEME_MESSAGE,
  API_MESSAGE,
  API_STAR,
  API_UNSTAR,
} from "constants/routes";
import { compressJson, convertToDt } from "utils/convertData";
import GraphPopup from "components/Page/GraphPopup";
import ChartPopup from "components/Page/ChartPopup";
import AudienceAnalysis from "components/Page/AudienceAnalysis";

interface IProps {
  menu: string;
  loading: boolean;
  sideOption: AtlasMach.UISideOption;
  chartStack: { [key: string]: AtlasMach.IChartData };
  authToken: string;
  switchViewMode: (mode: string) => void;
  setExportData: (data: any) => void;
  setIsLoading: (loading: boolean) => void;
}

const MatrixScreen = (props: IProps) => {
  const {
    menu,
    loading,
    sideOption,
    chartStack,
    authToken,
    switchViewMode,
    setExportData,
    setIsLoading,
  } = props;
  const [noThemeError, setNoThemeError] = useState<boolean>(false);
  const [noThemeMentionsError, setNoThemeMentionsError] =
    useState<boolean>(false);

  const [currentChartData, setCurrentChartData] =
    useState<AtlasMach.IChartData>();
  const [selectedAccount, setSelectedAccount] = useState<AtlasMach.INode>();
  const [accountMessages, setAccountMessages] =
    useState<AtlasMach.IAccountMessages>();
  const [selectedTheme, setSelectedTheme] = useState<AtlasMach.IPieData>();
  const [accountThemeMessages, setAccountThemeMessages] =
    useState<AtlasMach.IPieMessages[]>();

  const [popupPosition, setPopupPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);
  const [themePopupPosition, setThemePopupPosition] = useState<
    { x: number; y: number } | undefined
  >(undefined);

  const [dmData, setDMData] = useState<any[]>([
    {
      Account: "BethMorton",
      "Science & Research": 22,
      "Treatment Options": 2,
      "Community & Adovococy": 16,
      "Side Effects": 23,
      COVID: 15,
      "Price & Insurance": 2,
      Other: 0,
      "Symptoms & Aliments": 1,
      "Diagnosis & Patient Care": 9,
      "Diet & Lifestyle": 10,
    },
    {
      Account: "513eats",
      "Science & Research": 13,
      "Treatment Options": 8,
      "Community & Adovococy": 9,
      "Side Effects": 24,
      COVID: 2,
      "Price & Insurance": 0,
      Other: 4,
      "Symptoms & Aliments": 32,
      "Diagnosis & Patient Care": 3,
      "Diet & Lifestyle": 5,
    },
    {
      Account: "55bonnie",
      "Science & Research": 2,
      "Treatment Options": 12,
      "Community & Adovococy": 26,
      "Side Effects": 17,
      COVID: 6,
      "Price & Insurance": 7,
      Other: 1,
      "Symptoms & Aliments": 15,
      "Diagnosis & Patient Care": 6,
      "Diet & Lifestyle": 8,
    },
    {
      Account: "7hevandal",
      "Science & Research": 31,
      "Treatment Options": 2,
      "Community & Adovococy": 7,
      "Side Effects": 21,
      COVID: 0,
      "Price & Insurance": 9,
      Other: 14,
      "Symptoms & Aliments": 9,
      "Diagnosis & Patient Care": 3,
      "Diet & Lifestyle": 4,
    },
    {
      Account: "83alexandrine",
      "Science & Research": 11,
      "Treatment Options": 9,
      "Community & Adovococy": 13,
      "Side Effects": 7,
      COVID: 21,
      "Price & Insurance": 2,
      Other: 7,
      "Symptoms & Aliments": 10,
      "Diagnosis & Patient Care": 4,
      "Diet & Lifestyle": 6,
    },
    {
      Account: "90laurenquigley",
      "Science & Research": 18,
      "Treatment Options": 3,
      "Community & Adovococy": 19,
      "Side Effects": 2,
      COVID: 17,
      "Price & Insurance": 11,
      Other: 6,
      "Symptoms & Aliments": 1,
      "Diagnosis & Patient Care": 2,
      "Diet & Lifestyle": 21,
    },
    {
      Account: "BethMorton",
      "Science & Research": 14,
      "Treatment Options": 20,
      "Community & Adovococy": 6,
      "Side Effects": 0,
      COVID: 18,
      "Price & Insurance": 5,
      Other: 7,
      "Symptoms & Aliments": 16,
      "Diagnosis & Patient Care": 3,
      "Diet & Lifestyle": 11,
    },
    {
      Account: "513eats",
      "Science & Research": 1,
      "Treatment Options": 23,
      "Community & Adovococy": 16,
      "Side Effects": 19,
      COVID: 11,
      "Price & Insurance": 0,
      Other: 17,
      "Symptoms & Aliments": 3,
      "Diagnosis & Patient Care": 5,
      "Diet & Lifestyle": 5,
    },
    {
      Account: "55bonnie",
      "Science & Research": 9,
      "Treatment Options": 4,
      "Community & Adovococy": 30,
      "Side Effects": 7,
      COVID: 9,
      "Price & Insurance": 11,
      Other: 2,
      "Symptoms & Aliments": 8,
      "Diagnosis & Patient Care": 14,
      "Diet & Lifestyle": 6,
    },
    {
      Account: "7hevandal",
      "Science & Research": 28,
      "Treatment Options": 11,
      "Community & Adovococy": 1,
      "Side Effects": 6,
      COVID: 33,
      "Price & Insurance": 2,
      Other: 9,
      "Symptoms & Aliments": 0,
      "Diagnosis & Patient Care": 8,
      "Diet & Lifestyle": 2,
    },
    {
      Account: "83alexandrine",
      "Science & Research": 8,
      "Treatment Options": 0,
      "Community & Adovococy": 4,
      "Side Effects": 26,
      COVID: 2,
      "Price & Insurance": 14,
      Other: 35,
      "Symptoms & Aliments": 1,
      "Diagnosis & Patient Care": 7,
      "Diet & Lifestyle": 3,
    },
    {
      Account: "90laurenquigley",
      "Science & Research": 18,
      "Treatment Options": 3,
      "Community & Adovococy": 19,
      "Side Effects": 2,
      COVID: 17,
      "Price & Insurance": 11,
      Other: 6,
      "Symptoms & Aliments": 1,
      "Diagnosis & Patient Care": 2,
      "Diet & Lifestyle": 21,
    },
  ]);

  const [sortColumn, setSortColumn] = useState<string>("");
  const [ordering, setOrdering] = useState<boolean>(false);

  // TODO: ideally the mention-related data should be in a separate component
  // we should end up with two local components in this file, one for mention and one sender
  const [columns, setColumns] = useState<AtlasMach.IPieData[]>([]);
  const [columnsMentions, setColumnsMentions] = useState<AtlasMach.IPieData[]>(
    []
  );

  const [currentStart, setCurrentStart] = useState(0);
  const [currentStartMentions, setCurrentStartMentions] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreMentions, setHasMoreMentions] = useState(true);
  const [data, setData] = useState<any[]>([]);
  const [dataMentions, setDataMentions] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState<"SENDER" | "MENTIONED">("SENDER");

  const handleChangeActiveTab = (action: "SENDER" | "MENTIONED") => {
    setActiveTab(action);
  };

  useEffect(() => {
    switchViewMode("matrix");
  }, [switchViewMode]);

  useEffect(() => {
    const key = compressJson({
      topic: sideOption.topic,
      source: sideOption.source,
      dateFrom: sideOption.dateFrom,
      dateTo: sideOption.dateTo,
    });
    setCurrentChartData(chartStack[key]);
  }, [
    chartStack,
    sideOption.topic,
    sideOption.source,
    sideOption.dateFrom,
    sideOption.dateTo,
  ]);

  useEffect(() => {
    if (!sideOption.topic || !sideOption.source) return;
    setIsLoading(true);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    // Fetch info for both matrixes, so that switching tabs is fast
    fetch(
      API_MATRIX_OLD.replace("$1", sideOption.topic).replace(
        "$2",
        sideOption.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: sideOption.audiences,
          starAccountOnly: sideOption.starred,
          start: 0,
          take: 50,
          orderBy: sortColumn,
          isDesc: ordering,
          filter: "",
        }),
      }
    )
      .then((res) => {
        if (res.status == 412) {
          setNoThemeError(true);
          setIsLoading(false);
          return new Response();
        }
        return res.json();
      })
      .then((res) => {
        setColumns(res.columns);
        setData(res.data);
        setCurrentStart(res.data.length);
        setHasMore(res.data.length === 50);
        setIsLoading(false);
      });

    fetch(
      API_MATRIX_MENTIONS_OLD.replace("$1", sideOption.topic).replace(
        "$2",
        sideOption.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: sideOption.audiences,
          starAccountOnly: sideOption.starred,
          start: 0,
          take: 50,
          orderBy: sortColumn,
          isDesc: ordering,
          filter: "",
        }),
      }
    )
      .then((res) => {
        if (res.status == 412) {
          setNoThemeMentionsError(true);
          setIsLoading(false);
          return new Response();
        }
        return res.json();
      })
      .then((res) => {
        setColumnsMentions(res.columns);
        setDataMentions(res.data);
        setCurrentStartMentions(res.data.length);
        setHasMoreMentions(res.data.length === 50);
        setIsLoading(false);
      });
  }, [
    authToken,
    sideOption.topic,
    sideOption.source,
    sideOption.dateFrom,
    sideOption.dateTo,
    sideOption.audiences,
    sideOption.starred,
    sortColumn,
    ordering,
  ]);

  useEffect(() => {
    setExportData({
      columns: columns.map((col) => col.label),
      data: data.map((item) => [
        [item[1]["Account"]],
        ...columns.map((col, jdx) => `${itemValue(item, jdx, false)}%`),
      ]),
    });
  }, [columns, data]);

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setOrdering((prev) => !prev);
    } else {
      setSortColumn(column);
      setOrdering(false);
    }
  };

  const handleResetSort = () => {
    setSortColumn("");
    setOrdering(false);
  };

  const handleScroll = useCallback(
    (e: any) => {
      const bottom =
        Math.trunc(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight;
      const mentions = activeTab === "MENTIONED";
      if (bottom) {
        if (!sideOption.topic || !sideOption.source) return;
        if ((mentions && !hasMoreMentions) || (!mentions && !hasMore)) return;
        if (loading) return;
        if (!hasMore) return;

        setIsLoading(true);
        const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
        const api = mentions ? API_MATRIX_MENTIONS_OLD : API_MATRIX_OLD;
        fetch(
          api.replace("$1", sideOption.topic).replace("$2", sideOption.source),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              startDate: dt.dt_from,
              endDate: dt.dt_to,
              selectedAudiences: sideOption.audiences,
              starAccountOnly: sideOption.starred,
              start: mentions ? currentStartMentions : currentStart,
              take: 50,
              orderBy: sortColumn,
              isDesc: ordering,
              filter: "",
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            mentions
              ? setDataMentions((prev) => [...prev, ...res.data])
              : setData((prev) => [...prev, ...res.data]);
            mentions
              ? setCurrentStartMentions((prev) => prev + res.data.length)
              : setCurrentStart((prev) => prev + res.data.length);
            mentions
              ? setHasMoreMentions(res.data.length === 50)
              : setHasMore(res.data.length === 50);
            setIsLoading(false);
            setData((prev) => [...prev, ...res.data]);
            setCurrentStart((prev) => prev + res.data.length);
            setHasMore(res.data.length === 50);
            setIsLoading(false);
          });
      }
    },
    [
      authToken,
      sideOption.topic,
      sideOption.source,
      sideOption.dateFrom,
      sideOption.dateTo,
      sideOption.audiences,
      sideOption.starred,
      currentStart,
      currentStartMentions,
      loading,
      hasMore,
      hasMoreMentions,
      sortColumn,
      ordering,
    ]
  );

  const itemValue = (item: any, colIdx: number, mentions: boolean) => {
    let value = 0;
    try {
      if (mentions) {
        value = item[colIdx + 2][columnsMentions[colIdx].label];
      } else {
        value = item[colIdx + 2][columns[colIdx].label];
      }
      value = isNaN(value) ? 0 : value;
    } catch (ex) {}
    return value.toFixed(2);
  };

  const loadAccountMessages = async (topic, from, to, accountId, sourceId) => {
    const response = await fetch(API_MESSAGE.replace("$1", topic), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        dateRange: {
          startDate: from,
          endDate: to,
        },
        accountId: accountId,
        sourceId: sourceId,
      }),
    });
    const data = await response.json();
    setAccountMessages(data);
  };

  const handleSelectAccount = async (id: string) => {
    setSelectedTheme(undefined);

    const node = currentChartData?.nodes.find((node) => node.accountId === id);
    if (!node) return;

    setSelectedAccount(node);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    await loadAccountMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node.accountId,
      node.sourceId
    );
  };

  const loadAccountThemeMessages = async (
    topic,
    from,
    to,
    accountId,
    sourceId,
    themeId,
    isMention
  ) => {
    var api = isMention ? API_MATRIX_THEME_MESSAGE : API_MATRIX_THEME_MESSAGE;
    const response = await fetch(
      api
        .replace("$1", topic)
        .replace("$2", sourceId)
        .replace("$3", accountId)
        .replace("$4", themeId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: from,
          endDate: to,
          selectedAudiences: sideOption.audiences,
          starAccountOnly: false,
          start: 0,
          take: 50,
          orderBy: "",
          isDesc: true,
          filter: "",
        }),
      }
    );
    const data = await response.json();
    setAccountThemeMessages(data);
  };

  const handleZero = () => {
    alert("No messages to show");
  };

  const handleSelectAccountTheme = async (
    id: string,
    col: AtlasMach.IPieData,
    isMention: boolean
  ) => {
    const node = currentChartData?.nodes.find((node) => node.accountId === id);
    if (!node) return;

    setSelectedTheme(col);

    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    await loadAccountThemeMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node.accountId,
      node.sourceId,
      col.id,
      isMention
    );
  };

  const handleWrapperClick = (e: any) => {
    const bounds = document
      .getElementById("table-body")
      ?.getBoundingClientRect();
    if (!bounds) return;

    let x = e.clientX - bounds.left;
    let y = e.clientY - bounds.top;

    x = Math.min(bounds.width - 310, x);
    y = Math.min(bounds.height - 500, y);
    setPopupPosition({ x: x + 330, y });
    setThemePopupPosition({ x, y });
  };

  const handleStarred = (pnode, updateNode) => {
    const starLink = pnode.isStarred ? API_UNSTAR : API_STAR;
    fetch(
      starLink
        .replace("$1", sideOption.topic)
        .replace("$2", pnode.sourceId)
        .replace("$3", pnode.accountId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setCurrentChartData((prev) => {
          if (!prev) return undefined;
          return {
            ...prev,
            nodes: prev.nodes.map((node) =>
              node.accountId === pnode.accountId
                ? { ...node, isStarred: !node.isStarred }
                : node
            ),
          };
        });
        updateNode((prev) => ({
          ...prev,
          isStarred: !prev.isStarred,
        }));
      }
    });
  };

  const handleThemeMessage = async (msg: AtlasMach.IMessage) => {
    const node = currentChartData?.nodes.find(
      (node) => node.key === msg.ingestionMeta.from.accountName
    );
    setSelectedAccount(node);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    await loadAccountMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node?.accountId,
      node?.sourceId
    );
  };

  const SenderMatrix = (props) => (
    <>
      {noThemeError && (
        <p className="p">No themes available for the selected topic.</p>
      )}
      {!noThemeError && (
        <div className="matrix-wrapper">
          <div className="matrix-sort-title">
            <div className="title-text">
              <img src={SvgInfo} alt="info" />
              <span>
                Accounts sorted by{" "}
                {sortColumn === "" ? "highest centricity" : sortColumn}
              </span>
            </div>
            <div className="reset-sorting-wrapper">
              <div className="reset-sorting" onClick={handleResetSort}>
                Reset Sorting
              </div>
            </div>
          </div>
          <div className="matrix-table-wrapper">
            <ScrollSync>
              <div
                className="matrix-table"
                id="table-body"
                onClick={handleWrapperClick}
              >
                <ScrollSyncPane>
                  <div className="header">
                    <div className="header-item">
                      <div
                        className="icon-header"
                        style={{ borderBottomColor: "#78D2F1" }}
                      >
                        <div
                          className={sortColumn === "Account" ? "active" : ""}
                        >
                          Account
                        </div>
                        <img
                          src={sortColumn === "Account" ? SvgSortSel : SvgSort}
                          alt="sort"
                          className="sort-icon"
                          onClick={() => handleSort("Account")}
                        />
                      </div>
                    </div>
                    {columns.map((col, idx) => (
                      <div key={`ddkey-${col.label}`} className="header-item">
                        <div
                          className="icon-header"
                          style={{ borderBottomColor: col.color }}
                        >
                          <div
                            className={sortColumn === col.label ? "active" : ""}
                          >
                            {col.label}
                          </div>
                          <img
                            src={
                              sortColumn === col.label ? SvgSortSel : SvgSort
                            }
                            alt="sort"
                            className="sort-icon"
                            onClick={() => handleSort(col.label)}
                          />
                        </div>
                      </div>
                    ))}
                    <div style={{ width: 1, flexShrink: 0 }}></div>
                  </div>
                </ScrollSyncPane>
                <ScrollSyncPane>
                  <div
                    className="body hs-scrollable-container"
                    onScroll={handleScroll}
                  >
                    {data.map((item, idx) => (
                      <div className="body-row" key={`rowTitle-${idx}`}>
                        <div
                          className="body-item"
                          onClick={() =>
                            handleSelectAccount(item[0]["AccountId"])
                          }
                        >
                          {item[1]["Account"]}
                        </div>
                        {columns.map((col, jdx) => (
                          <div
                            className="body-item"
                            key={`rowColumn-${idx}-${jdx}`}
                          >
                            <div
                              onClick={() =>
                                itemValue(item, jdx, false) === "0.00"
                                  ? handleZero()
                                  : handleSelectAccountTheme(
                                      item[0]["AccountId"],
                                      col,
                                      false
                                    )
                              }
                            >
                              {itemValue(item, jdx, false)}%
                            </div>
                          </div>
                        ))}
                        <div style={{ width: 1, flexShrink: 0 }}></div>
                      </div>
                    ))}
                  </div>
                </ScrollSyncPane>
                {selectedTheme &&
                  accountThemeMessages &&
                  themePopupPosition && (
                    <ChartPopup
                      position={themePopupPosition}
                      data={selectedTheme}
                      msgData={accountThemeMessages}
                      handleClose={() => {
                        setSelectedAccount(undefined);
                        setAccountThemeMessages(undefined);
                        setThemePopupPosition(undefined);
                      }}
                      handleMessage={handleThemeMessage}
                    />
                  )}
                {selectedAccount && accountMessages && popupPosition && (
                  <GraphPopup
                    position={popupPosition}
                    handleClose={() => {
                      setSelectedAccount(undefined);
                      setAccountMessages(undefined);
                      setPopupPosition(undefined);
                    }}
                    data={accountMessages}
                    node={selectedAccount}
                    onStarred={() => {
                      handleStarred(selectedAccount, setSelectedAccount);
                    }}
                  />
                )}
              </div>
            </ScrollSync>
          </div>
        </div>
      )}
    </>
  );

  const MentionMatrix = (props) => (
    <>
      {noThemeMentionsError && (
        <p className="p">No themes available for the selected topic.</p>
      )}
      {!noThemeMentionsError && (
        <div className="matrix-wrapper">
          <div className="matrix-sort-title">
            <div className="title-text">
              <img src={SvgInfo} alt="info" />
              <span>
                Accounts sorted by{" "}
                {sortColumn === "" ? "highest centricity" : sortColumn}
              </span>
            </div>
            <div className="reset-sorting-wrapper">
              <div className="reset-sorting" onClick={handleResetSort}>
                Reset Sorting
              </div>
            </div>
          </div>
          <div className="matrix-table-wrapper">
            <ScrollSync>
              <div
                className="matrix-table"
                id="table-body"
                onClick={handleWrapperClick}
              >
                <ScrollSyncPane>
                  <div className="header">
                    <div className="header-item">
                      <div
                        className="icon-header"
                        style={{ borderBottomColor: "#78D2F1" }}
                      >
                        <div
                          className={sortColumn === "Account" ? "active" : ""}
                        >
                          Account
                        </div>
                        <img
                          src={sortColumn === "Account" ? SvgSortSel : SvgSort}
                          alt="sort"
                          className="sort-icon"
                          onClick={() => handleSort("Account")}
                        />
                      </div>
                    </div>
                    {columnsMentions.map((col, idx) => (
                      <div key={`ddkey-${col.label}`} className="header-item">
                        <div
                          className="icon-header"
                          style={{ borderBottomColor: col.color }}
                        >
                          <div
                            className={sortColumn === col.label ? "active" : ""}
                          >
                            {col.label}
                          </div>
                          <img
                            src={
                              sortColumn === col.label ? SvgSortSel : SvgSort
                            }
                            alt="sort"
                            className="sort-icon"
                            onClick={() => handleSort(col.label)}
                          />
                        </div>
                      </div>
                    ))}
                    <div style={{ width: 1, flexShrink: 0 }}></div>
                  </div>
                </ScrollSyncPane>
                <ScrollSyncPane>
                  <div
                    className="body hs-scrollable-container"
                    onScroll={handleScroll}
                  >
                    {dataMentions.map((item, idx) => (
                      <div className="body-row" key={`rowTitle-${idx}`}>
                        <div
                          className="body-item"
                          onClick={() =>
                            handleSelectAccount(item[0]["AccountId"])
                          }
                        >
                          {item[1]["Account"]}
                        </div>
                        {columnsMentions.map((col, jdx) => (
                          <div
                            className="body-item"
                            key={`rowColumn-${idx}-${jdx}`}
                          >
                            <div
                              onClick={() =>
                                itemValue(item, jdx, true) === "0.00"
                                  ? handleZero()
                                  : handleSelectAccountTheme(
                                      item[0]["AccountId"],
                                      col,
                                      true
                                    )
                              }
                            >
                              {itemValue(item, jdx, true)}%
                            </div>
                          </div>
                        ))}
                        <div style={{ width: 1, flexShrink: 0 }}></div>
                      </div>
                    ))}
                  </div>
                </ScrollSyncPane>
                {selectedTheme &&
                  accountThemeMessages &&
                  themePopupPosition && (
                    <ChartPopup
                      position={themePopupPosition}
                      data={selectedTheme}
                      msgData={accountThemeMessages}
                      handleClose={() => {
                        setSelectedAccount(undefined);
                        setAccountThemeMessages(undefined);
                        setThemePopupPosition(undefined);
                      }}
                      handleMessage={handleThemeMessage}
                    />
                  )}
                {selectedAccount && accountMessages && popupPosition && (
                  <GraphPopup
                    position={popupPosition}
                    handleClose={() => {
                      setSelectedAccount(undefined);
                      setAccountMessages(undefined);
                      setPopupPosition(undefined);
                    }}
                    data={accountMessages}
                    node={selectedAccount}
                    onStarred={() => {
                      handleStarred(selectedAccount, setSelectedAccount);
                    }}
                  />
                )}
              </div>
            </ScrollSync>
          </div>
        </div>
      )}
    </>
  );

  return (
    <PageTemplate title="Conversational Matrix">
      {menu === "aa" && selectedAccount && <AudienceAnalysis />}
      <div className="matrix-container">
        <div className="tabs-wrapper">
          <div
            className={`tab-chart${activeTab === "SENDER" ? " active" : ""}`}
            onClick={() => handleChangeActiveTab("SENDER")}
          >
            Authors
          </div>
          <div
            className={`tab-text${activeTab === "MENTIONED" ? " active" : ""}`}
            onClick={() => handleChangeActiveTab("MENTIONED")}
          >
            Mentions
          </div>
        </div>
        <div className="matrix-border">
          {activeTab === "SENDER" && <SenderMatrix />}
          {activeTab === "MENTIONED" && <MentionMatrix />}
        </div>
      </div>
    </PageTemplate>
  );
};

export default MatrixScreen;
