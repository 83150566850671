import React from "react";
import { useNavigate } from "react-router-dom";
import { useDetectClickOutside } from "react-detect-click-outside";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ROUTES from "constants/routes";

interface IMenuNotification {
  rp: number;
  data: AtlasMach.INotification[];
  hideMenu: () => void;
  readMessage: (msg: AtlasMach.INotification) => void;
}

TimeAgo.addDefaultLocale(en);

function MenuNotification({
  hideMenu,
  readMessage,
  rp,
  data,
}: IMenuNotification) {
  const navigate = useNavigate();
  const ref = useDetectClickOutside({ onTriggered: hideMenu });
  const timeAgo = new TimeAgo("en-US");

  const onClickMessage = (msg: AtlasMach.INotification) => {
    readMessage(msg);
  };

  return (
    <div className={`notification-menu`} style={{ right: rp }} ref={ref}>
      <div className="menu-title">
        <label>Notifications</label>
        <span onClick={() => hideMenu()}>
          <FontAwesomeIcon icon={faXmark} fontSize={30} />
        </span>
      </div>
      {data.map((gData) => (
        <div
          key={`notification-${gData.id}`}
          className={gData.status === 1 ? "menu-item active" : "menu-item"}
          onClick={() => onClickMessage(gData)}
        >
          {gData.status === 1 && <div className="alert-mark"></div>}
          <div className="item-content">{gData.text}</div>
          <div className="msg-ago">
            {timeAgo.format(new Date(gData.createdOn + "Z"))}
          </div>
        </div>
      ))}
      {data.length > 0 && (
        <div className="menu-item text-center">
          <div
            onClick={() => {
              hideMenu();
              navigate(ROUTES.NOTIFICATION);
            }}
          >
            See All
          </div>
        </div>
      )}
      {data.length === 0 && (
        <div className="menu-item text-center">
          <span>No notifications</span>
        </div>
      )}
    </div>
  );
}

export default MenuNotification;
