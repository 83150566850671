import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import DropIconUp from "assets/images/icons/dropdown-arrow-up.svg";
import DropIconDown from "assets/images/icons/dropdown-arrow-down.svg";
import { toast } from "react-toastify";
import "./styles.scss";
import { convertToDt } from "utils/convertData";

interface CustomDropDownProps {
  list: {
    id: string;
    title: number;
  }[];
  defaultValue: number;
  onChange: (year: number) => void;
}

function CustomDropDown({ list, defaultValue, onChange }: CustomDropDownProps) {
  const [headerTitle, setHeaderTitle] = useState(defaultValue);
  const [isListOpen, setIsListOpen] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      setIsListOpen(false);
    },
  });

  const toggleList = () => {
    setIsListOpen((prev) => !prev);
  };

  const selectItem = (item) => {
    setIsListOpen(false);
    setHeaderTitle(item.title);
    if (onChange) onChange(item.title);
  };

  return (
    <div className="dd-wrapper" ref={ref}>
      <div className="dd-header" onClick={toggleList}>
        <div className="dd-header-title">{headerTitle}</div>
        {isListOpen ? (
          <img src={DropIconUp} alt="drop-indicator" />
        ) : (
          <img src={DropIconDown} alt="drop-indicator" />
        )}
      </div>
      <div
        role="list"
        className="dd-list"
        style={{ display: isListOpen ? "block" : "none" }}
      >
        {list.map((item) => (
          <div
            className="dd-list-item"
            key={item.id}
            onClick={() => selectItem(item)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  );
}

const MONTHS_UI = [
  ["JAN", "FEB", "MAR", "APR"],
  ["MAY", "JUN", "JUL", "AUG"],
  ["SEP", "OCT", "NOV", "DEC"],
];

interface CustomCalendarProps {
  from: AtlasMach.IDate;
  to: AtlasMach.IDate;
  hideMenu: () => void;
  onChange: (value: any) => void;
}

function CustomCalendar({ from, to, hideMenu, onChange }: CustomCalendarProps) {
  const [fromDate, setFromDate] = useState(from);
  const [toDate, setToDate] = useState(to);
  const [showFromYear, setShowFromYear] = useState(from.year);
  const [showToYear, setShowToYear] = useState(to.year);

  const ref = useDetectClickOutside({
    onTriggered: () => {
      hideMenu();
    },
  });

  const handleOkay = () => {
    const dt = convertToDt(fromDate, toDate);
    if (dt.dt_from > dt.dt_to) {
      toast("Invalid date selection", {
        position: "top-left",
        type: toast.TYPE.ERROR,
      });
      return;
    }
    if (onChange) {
      onChange({
        dateFrom: fromDate,
        dateTo: toDate,
      });
    }
    hideMenu();
  };

  return (
    <div className="calendar-wrapper" ref={ref}>
      <div className="part">
        <div className="part-header">
          <label>FROM</label>
          <CustomDropDown
            list={[
              { id: "2019", title: 2019 },
              { id: "2020", title: 2020 },
              { id: "2021", title: 2021 },
              { id: "2022", title: 2022 },
              { id: "2023", title: 2023 },
            ]}
            defaultValue={fromDate.year}
            onChange={(year) => setShowFromYear(year)}
          />
        </div>
        <div className="part-body">
          <div className="cmc-table">
            {MONTHS_UI.map((row, idx) => (
              <div className="cmc-row" key={`rowmf-${idx}`}>
                {row.map((month, mdx) => (
                  <div
                    className={`cmc-item ${
                      showFromYear === fromDate.year &&
                      fromDate.month === idx * 4 + mdx
                        ? "active"
                        : ""
                    }`}
                    key={`monthf-${month}`}
                    onClick={() =>
                      setFromDate({ year: showFromYear, month: idx * 4 + mdx })
                    }
                  >
                    {month}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="part">
        <div className="part-header">
          <label>TO</label>
          <CustomDropDown
            list={[
              { id: "2019", title: 2019 },
              { id: "2020", title: 2020 },
              { id: "2021", title: 2021 },
              { id: "2022", title: 2022 },
              { id: "2023", title: 2023 },
            ]}
            defaultValue={to.year}
            onChange={(year) => setShowToYear(year)}
          />
        </div>
        <div className="part-body">
          <div className="cmc-table">
            {MONTHS_UI.map((row, idx) => (
              <div className="cmc-row" key={`rowmt-${idx}`}>
                {row.map((month, mdx) => (
                  <div
                    className={`cmc-item ${
                      showToYear === toDate.year &&
                      toDate.month === idx * 4 + mdx
                        ? "active"
                        : ""
                    }`}
                    key={`montht-${month}`}
                    onClick={() =>
                      setToDate({ year: showToYear, month: idx * 4 + mdx })
                    }
                  >
                    {month}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <button className="calendar-ok" onClick={handleOkay}>
        OK
      </button>
    </div>
  );
}

export default CustomCalendar;
