import { connect } from "react-redux";
import { setAPIToken } from "redux/actions";
import PageTemplate from "./PageTemplate";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  loading: state.ui.isLoading,
});

const mapDispatchToProps = {
  setAPIToken,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageTemplate);
