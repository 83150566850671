import React from "react";
import "./styles.scss";
interface IBlueButton {
  title: string;
  disabled?: boolean;
  onClick?: () => void;
}
export default function BlueButton({ title, disabled, onClick }: IBlueButton) {
  return (
    <div
      className={`blue-button ${disabled ? "disabled" : ""}`}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <span>{title}</span>
    </div>
  );
}
