import * as ACTION_TYPES from "./actionTypes";

export const openMenu = (menu: string) => ({
  type: ACTION_TYPES.OPEN_MENU,
  payload: { menu },
});

export const hideMenu = () => ({
  type: ACTION_TYPES.HIDE_MENU,
  payload: {},
});

export const setIsLoading = (loading: boolean) => ({
  type: ACTION_TYPES.SET_ISLOADING,
  payload: { loading },
});

export const updateProfileCount = (count: number) => ({
  type: ACTION_TYPES.UPDATE_PROFILE_COUNT,
  payload: { count },
});

export const updatePercent = (percent: number) => ({
  type: ACTION_TYPES.UPDATE_PERCENT,
  payload: { percent },
});

export const updateSideOption = (option: AtlasMach.UISideOption) => ({
  type: ACTION_TYPES.UPDATE_SIDEOPTION,
  payload: { option },
});

export const updateTopic = (topic: AtlasMach.ITopic) => ({
  type: ACTION_TYPES.UPDATE_TOPIC,
  payload: { topic },
});

export const updateDateRange = (dateRange: AtlasMach.IDateRange) => ({
  type: ACTION_TYPES.UPDATE_DATE_RANGE,
  payload: { dateRange },
});

export const updateFilters = (filters: AtlasMach.UIFilters | undefined) => ({
  type: ACTION_TYPES.UPDATE_FILTERS,
  payload: { filters },
});

export const switchViewMode = (mode: string) => ({
  type: ACTION_TYPES.SWITCH_VIEW_MODE,
  payload: { mode },
});

export const setClient = (client: AtlasMach.IClient) => ({
  type: ACTION_TYPES.SET_CLIENT,
  payload: { client },
});
export const setTopics = (topics: AtlasMach.ITopic[]) => ({
  type: ACTION_TYPES.SET_TOPICS,
  payload: { topics },
});

export const setSubTopics = (subtopics: AtlasMach.ISubtopic[]) => ({
  type: ACTION_TYPES.SET_SUBTOPICS,
  payload: { subtopics },
});

export const setAudiences = (audiences: AtlasMach.IAudience[]) => ({
  type: ACTION_TYPES.SET_AUDIENCES,
  payload: { audiences },
});

export const setThemes = (themes: AtlasMach.ITheme[]) => ({
  type: ACTION_TYPES.SET_THEMES,
  payload: { themes },
});

export const setBrands = (brands: AtlasMach.IBrand[]) => ({
  type: ACTION_TYPES.SET_BRANDS,
  payload: { brands },
});

export const setBrandAccounts = (brandAccounts: AtlasMach.IAccount[]) => ({
  type: ACTION_TYPES.SET_BRAND_ACCOUNTS,
  payload: { brandAccounts },
});

export const setSources = (sources: AtlasMach.ISource[]) => ({
  type: ACTION_TYPES.SET_SOURCES,
  payload: { sources },
});

// export const setNotifications = (notifications: AtlasMach.INotification[]) => ({
//   type: ACTION_TYPES.SET_NOTIFICATIONS,
//   payload: { notifications }
// })

export const setAccount = (account: AtlasMach.INode | undefined) => ({
  type: ACTION_TYPES.SET_ACCOUNT,
  payload: { account },
});

export const setStarAccounts = (accounts: AtlasMach.IStarredAccount[]) => ({
  type: ACTION_TYPES.SET_STARACCOUNTS,
  payload: { accounts },
});

export const setHeatMaps = (heatMaps: AtlasMach.IHeatMapData[]) => ({
  type: ACTION_TYPES.SET_HEATMAPS,
  payload: { heatMaps },
});

export const setHeatThemes = (heatThemes: AtlasMach.IHeatMapData[]) => ({
  type: ACTION_TYPES.SET_HEATTHEMES,
  payload: { heatThemes },
});

export const setHeatAccounts = (
  heatAccounts: AtlasMach.IHeatAccountData[]
) => ({
  type: ACTION_TYPES.SET_HEATACCOUNTS,
  payload: { heatAccounts },
});

export const setAudienceAnalysis = (aa: AtlasMach.IAASection) => ({
  type: ACTION_TYPES.SET_AUDIENCE_ANALYSIS,
  payload: { aa },
});

export const setAPIToken = (token: string) => ({
  type: ACTION_TYPES.SET_API_TOKEN,
  payload: { token },
});

export const setExportData = (data: any) => ({
  type: ACTION_TYPES.UPDATE_EXPORT_DATA,
  payload: { data },
});

export const setChartRef = (ref) => ({
  type: ACTION_TYPES.SET_CHARTREF,
  payload: { ref },
});

export const pushChart = (key: string, chart: AtlasMach.IChartData) => ({
  type: ACTION_TYPES.PUSH_CHARTDATA,
  payload: { key, chart },
});

export const pushChartPosition = (key: string, chart: any) => ({
  type: ACTION_TYPES.PUSH_CHARTPOSITION,
  payload: { key, chart },
});
