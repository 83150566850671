import { connect } from "react-redux";
import {
  updateProfileCount,
  updatePercent,
  switchViewMode,
  setAccount,
  setChartRef,
  pushChart,
  pushChartPosition,
  setIsLoading,
} from "redux/actions";
import GraphScreen from "./GraphScreen";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  menu: state.ui.headerMenu,
  percent: state.ui.percent,
  profileCt: state.ui.profileCount,
  sideOption: state.ui.sideOption,
  dataAudiences: state.data.audiences,
  chartStack: state.data.chartStack,
  chartPositionStack: state.ui.chartPositionStack,
  account: state.ui.account,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  updateProfileCount,
  updatePercent,
  switchViewMode,
  setAccount,
  setChartRef,
  pushChart,
  pushChartPosition,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphScreen);
