import { connect } from "react-redux";
import {
  openMenu,
  hideMenu,
  setAccount,
  setIsLoading,
  updatePercent,
} from "redux/actions";
import Header from "./Header";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  menu: state.ui.headerMenu,
  percent: state.ui.percent,
  profileCt: state.ui.profileCount,
  mode: state.ui.mode,
  sideOption: state.ui.sideOption,
  dataTopics: state.data.topics,
  dataSources: state.data.sources,
  dataAudiences: state.data.audiences,
  dataSubTopics: state.data.subtopics,
  starAccounts: state.data.starAccounts,
  heatMaps: state.data.heatMaps,
  heatAccounts: state.data.heatAccounts,
  chartStack: state.data.chartStack,
  chartRef: state.ui.chartRef,
  authToken: state.data.auth_token,
  exportData: state.data.exportData,
});

const mapDispatchToProps = {
  openMenu,
  hideMenu,
  setIsLoading,
  setAccount,
  updatePercent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
