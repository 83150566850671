import React, { useState, useEffect, useMemo } from "react";

import moment from "moment";
import { Line } from "react-chartjs-2";
import PageTemplate from "pages/PageTemplate";
import { compressJson, convertToDt } from "utils/convertData";
import {
  API_HEATMAP,
  API_HEATMAP_ACCOUNTS,
  API_THEMES_HEATMAP,
} from "constants/routes";

import "./styles.scss";

interface IInsightScreen {
  sideOption: AtlasMach.UISideOption;
  heatMaps: AtlasMach.IHeatMapData[];
  heatThemes: AtlasMach.IHeatMapData[];
  heatAccounts: AtlasMach.IHeatAccountData[];
  chartStack: { [key: string]: AtlasMach.IChartData };
  authToken: string;
  setHeatMaps: (heatMaps: AtlasMach.IHeatMapData[]) => void;
  setHeatThemes: (heatThemes: AtlasMach.IHeatMapData[]) => void;
  setHeatAccounts: (heatAccounts: AtlasMach.IHeatAccountData[]) => void;
  switchViewMode: (mode: string) => void;
  setIsLoading: (loading: boolean) => void;
}

const InsightScreen = (props: IInsightScreen) => {
  const {
    sideOption,
    heatMaps,
    heatThemes,
    heatAccounts,
    chartStack,
    authToken,
    setHeatMaps,
    setHeatThemes,
    setHeatAccounts,
    switchViewMode,
    setIsLoading,
  } = props;

  const [currentChartData, setCurrentChartData] = useState<
    AtlasMach.IChartData | undefined
  >();

  const heatMapLabels = useMemo(() => {
    if (heatMaps.length === 0) return [];
    let allLabels: string[] = [];
    heatMaps.forEach((hm) => {
      allLabels.push(...hm.data.map((dt) => dt.label));
    });
    const uniqueLabels = [...new Set(allLabels)].sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    return uniqueLabels;
  }, [heatMaps]);

  const heatMapData = useMemo(() => {
    const newHeatMap = heatMaps.map((hm) => {
      const normalizedData = heatMapLabels.map((lbDate) => {
        let existValue = hm.data.find((hmd) => hmd.label === lbDate);
        return {
          label: lbDate,
          value: existValue?.value || 0,
        };
      });

      return { ...hm, data: normalizedData };
    });
    return newHeatMap.sort(
      (a: AtlasMach.IHeatMapData, b: AtlasMach.IHeatMapData) =>
        b.data[0].value - a.data[0].value
    );
  }, [heatMapLabels]);

  const heatThemesLabels = useMemo(() => {
    if (heatThemes.length === 0) return [];
    let allLabels: string[] = [];
    heatThemes.forEach((hm) => {
      allLabels.push(...hm.data.map((dt) => dt.label));
    });
    const uniqueLabels = [...new Set(allLabels)].sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    return uniqueLabels;
  }, [heatThemes]);

  const heatThemesData = useMemo(() => {
    const newHeatThemes = heatThemes.map((hm) => {
      const normalizedData = heatThemesLabels.map((lbDate) => {
        let existValue = hm.data.find((hmd) => hmd.label === lbDate);
        return {
          label: lbDate,
          value: existValue?.value || 0,
        };
      });

      return { ...hm, data: normalizedData };
    });
    return newHeatThemes.sort(
      (a: AtlasMach.IHeatMapData, b: AtlasMach.IHeatMapData) =>
        b.data[0].value - a.data[0].value
    );
  }, [heatThemesLabels]);

  const heatAccountLabels = useMemo(() => {
    if (heatAccounts.length === 0) return [];
    let allLabels: string[] = [];
    heatAccounts.forEach((hm) => {
      allLabels.push(...hm.data.map((dt) => dt.label));
    });
    const uniqueLabels = [...new Set(allLabels)].sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    return uniqueLabels;
  }, [heatAccounts]);

  const heatAccountData = useMemo(() => {
    const newHeatAccounts = heatAccounts.map((hm) => {
      const normalizedData = heatAccountLabels.map((lbDate) => {
        let existValue = hm.data.find((hmd) => hmd.label === lbDate);
        return {
          label: lbDate,
          value: existValue?.value || 0,
        };
      });
      const node = currentChartData?.nodes.find(
        (node) => node.key === hm.label
      );
      return {
        data: normalizedData,
        color: node ? node.color : "white",
        label: node ? node.label : hm.label,
      };
    });
    return newHeatAccounts;
  }, [heatAccountLabels, currentChartData]);

  useEffect(() => {
    switchViewMode("insight");
  }, [switchViewMode]);

  useEffect(() => {
    const key = compressJson({
      topic: sideOption.topic,
      source: sideOption.source,
      dateFrom: sideOption.dateFrom,
      dateTo: sideOption.dateTo,
    });
    setCurrentChartData(chartStack[key]);
  }, [chartStack, sideOption.topic, sideOption.source]);

  const loadHeatMap = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_HEATMAP.replace("$1", option.topic).replace("$2", option.source),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedData = json.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data.sort(
              (a, b) =>
                new Date(a.label).getTime() - new Date(b.label).getTime()
            ),
          };
        });
        setHeatMaps(sortedData);
      });
  };

  const loadHeatThemes = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_THEMES_HEATMAP.replace("$1", option.topic).replace(
        "$2",
        option.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedData = json.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data.sort(
              (a, b) =>
                new Date(a.label).getTime() - new Date(b.label).getTime()
            ),
          };
        });
        setHeatThemes(sortedData);
      });
  };

  const loadHeatMapAccounts = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_HEATMAP_ACCOUNTS.replace("$1", option.topic).replace(
        "$2",
        option.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const sortedData = json.map((dataset) => {
          return {
            ...dataset,
            data: dataset.data.sort(
              (a, b) =>
                new Date(a.label).getTime() - new Date(b.label).getTime()
            ),
          };
        });
        setHeatAccounts(sortedData);
      });
  };

  const loadChartData = () => {
    setIsLoading(true);
    loadHeatMap(sideOption);
    loadHeatThemes(sideOption);
    loadHeatMapAccounts(sideOption);
    setIsLoading(false);
  };

  useEffect(() => {
    if (sideOption.topic) {
      loadChartData();
    }
  }, [sideOption]);

  const renderAudienceCentricityChart = () => {
    return (
      <Line
        datasetIdKey="id"
        data={{
          labels: heatMapLabels.map((hml) => moment(hml).format("MMM-YY")),
          datasets: heatMapData.map((lb, idx) => ({
            id: idx,
            label: lb.label,
            data: lb.data.map((d) => d.value),
            borderColor: lb.color,
            backgroundColor: lb.color,
            fill: true,
            pointRadius: 0,
          })),
        }}
        options={{
          interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
          },
          plugins: {
            tooltip: {
              mode: "index",
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label;
                  const dataValue = context.dataset.data[context.dataIndex];
                  let value = "";
                  if (typeof dataValue === "number") {
                    value = dataValue.toFixed(2);
                  }
                  return `${label}: ${value} %`;
                },
              },
            },
            legend: {
              position: "bottom",
              align: "start",
              labels: {
                color: "#78D2F1",
                boxWidth: 20,
                boxHeight: 20,
                padding: 30,
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
                generateLabels: function (chart) {
                  return heatMapData.map((lb, idx) => ({
                    datasetIndex: idx,
                    text: lb.label,
                    fillStyle: lb.color,
                    strokeStyle: lb.color,
                    fontColor: lb.color,
                    borderRadius: 5,
                    hidden: chart.getDatasetMeta(idx).hidden,
                  }));
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#78D2F1",
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                color: "#78D2F1",
                padding: 20,
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
                callback: function (value, index, values) {
                  return `${value}%`;
                },
              },
              min: 0,
              max: 100,
            },
          },
        }}
      />
    );
  };

  const renderThemesChart = () => {
    return (
      <Line
        datasetIdKey="id"
        data={{
          labels: heatThemesLabels.map((hml) => moment(hml).format("MMM-YY")),
          datasets: heatThemesData.map((lb, idx) => ({
            id: idx,
            label: lb.label,
            data: lb.data.map((d) => d.value),
            borderColor: lb.color,
            backgroundColor: lb.color,
            fill: true,
            pointRadius: 0,
          })),
        }}
        options={{
          interaction: {
            mode: "nearest",
            axis: "x",
            intersect: false,
          },
          plugins: {
            tooltip: {
              mode: "index",
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label;
                  const dataValue = context.dataset.data[context.dataIndex];
                  let value = "";
                  if (typeof dataValue === "number") {
                    value = dataValue.toFixed(2);
                  }
                  return `${label}: ${value} %`;
                },
              },
            },
            legend: {
              position: "bottom",
              align: "start",
              labels: {
                color: "#78D2F1",
                boxWidth: 20,
                boxHeight: 20,
                padding: 30,
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
                generateLabels: function (chart) {
                  return heatThemesData.map((lb, idx) => ({
                    datasetIndex: idx,
                    text: lb.label,
                    fillStyle: lb.color,
                    strokeStyle: lb.color,
                    fontColor: lb.color,
                    borderRadius: 5,
                    hidden: chart.getDatasetMeta(idx).hidden,
                  }));
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#78D2F1",
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
                drawBorder: false,
              },
              ticks: {
                color: "#78D2F1",
                padding: 20,
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
                callback: function (value, index, values) {
                  return `${value}%`;
                },
              },
              min: 0,
              max: 100,
            },
          },
        }}
      />
    );
  };

  const renderStarredAccountsChart = () => {
    return (
      <Line
        datasetIdKey="id"
        data={{
          labels: heatAccountLabels.map((hml) => moment(hml).format("MMM-YY")),
          datasets: heatAccountData.map((lb, idx) => ({
            id: idx,
            label: lb.label,
            data: lb.data.map((d) => d.value),
            borderColor: lb.color,
            backgroundColor: lb.color,
            pointRadius: 5,
          })),
        }}
        options={{
          plugins: {
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label;
                  const dataValue = context.dataset.data[context.dataIndex];
                  let value = "";
                  if (typeof dataValue === "number") {
                    value = dataValue.toFixed(2);
                  }
                  return `${label}: ${value} %`;
                },
              },
            },
            legend: {
              position: "bottom",
              align: "start",
              labels: {
                color: "#78D2F1",
                boxWidth: 20,
                boxHeight: 20,
                padding: 30,
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
                generateLabels: function (chart) {
                  return heatAccountData.map((lb, idx) => ({
                    datasetIndex: idx,
                    text: lb.label,
                    fillStyle: lb.color,
                    strokeStyle: lb.color,
                    fontColor: lb.color,
                    borderRadius: 5,
                    hidden: chart.getDatasetMeta(idx).hidden,
                  }));
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "#78D2F1",
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
              },
            },
            y: {
              grid: {
                display: true,
                color: "#78D2F180",
                drawBorder: false,
              },
              ticks: {
                color: "#78D2F1",
                padding: 20,
                font: {
                  family: "TomorrowSemiBold",
                  size: 13,
                },
                callback: function (value, index, values) {
                  return `${value}%`;
                },
              },
              suggestedMin: 0,
            },
          },
        }}
      />
    );
  };

  return (
    <PageTemplate title="INSIGHTS ORACLE">
      <div className="chart-container">
        <div className="border-wrapper-round">
          <div className="chart-wrapper">
            <div className="insight-title">
              <span>Audience Centricity</span>
            </div>
            <div>{renderAudienceCentricityChart()}</div>
            <div className="horizontal-separator" />
            <div className="insight-title">
              <span>Themes Insight</span>
            </div>
            <div>{renderThemesChart()}</div>
            <div className="horizontal-separator" />
            <div className="insight-title">
              <span>Starred Accounts Centricity</span>
            </div>
            <div>{renderStarredAccountsChart()}</div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default InsightScreen;
