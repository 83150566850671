import { connect } from "react-redux";
import GraphPopup from "./GraphPopup";
import { openMenu, setAudienceAnalysis } from "redux/actions";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  sideOption: state.ui.sideOption,
  dataSources: state.data.sources,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  openMenu,
  setAudienceAnalysis,
};

export default connect(mapStateToProps, mapDispatchToProps)(GraphPopup);
