import React from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useNavigate } from "react-router-dom";
import MenuItem from "components/Page/Menu/MenuItem";
import * as ROUTES from "constants/routes";

import TopicIcon from "assets/images/icons/topic.svg";
import TopicHoverIcon from "assets/images/icons/topic-hover.svg";
import MappingIcon from "assets/images/icons/mapping.svg";
import MappingHoverIcon from "assets/images/icons/mapping-hover.svg";
import StarIcon from "assets/images/icons/star.svg";
import StarHoverIcon from "assets/images/icons/star-hover.svg";
import EmailIcon from "assets/images/icons/email.svg";
import EmailHoverIcon from "assets/images/icons/email-hover.svg";
import InsightIcon from "assets/images/icons/insights.svg";
import InsightHoverIcon from "assets/images/icons/insights-hover.svg";
import PathwayIcon from "assets/images/icons/pathway.svg";
import PathwayHoverIcon from "assets/images/icons/pathway-hover.svg";
import ConversationalIcon from "assets/images/icons/conversational.svg";
import ConversationalHoverIcon from "assets/images/icons/conversational-hover.svg";
import ContentIcon from "assets/images/icons/content.svg";
import ContentHoverIcon from "assets/images/icons/content-hover.svg";

interface IMenuMain {
  rp: number;
  mode: string;
  hideMenu: () => void;
}

function MenuMain({ hideMenu, rp, mode }: IMenuMain) {
  const navigate = useNavigate();

  const ref = useDetectClickOutside({ onTriggered: hideMenu });

  return (
    <div className={`menu-dropdown`} style={{ right: rp }} ref={ref}>
      {mode !== "graph" && (
        <MenuItem
          icon={MappingIcon}
          hoverIcon={MappingHoverIcon}
          title={"The Atlas"}
          handleClick={() => {
            hideMenu();
            navigate(ROUTES.HCP);
          }}
        />
      )}
      {mode !== "chart" && (
        <MenuItem
          icon={TopicIcon}
          hoverIcon={TopicHoverIcon}
          title={"Atlas Voices"}
          handleClick={() => {
            hideMenu();
            navigate(ROUTES.TOPIC);
          }}
        />
      )}
      {/* {mode !== 'accounts' && (
        <MenuItem
          icon={StarIcon}
          hoverIcon={StarHoverIcon}
          title='Starred Accounts'
          handleClick={() => {
            hideMenu()
            history.push(ROUTES.STARRED)
          }} />
      )} */}
      {mode !== "insight" && (
        <MenuItem
          icon={InsightIcon}
          hoverIcon={InsightHoverIcon}
          title="Insight Oracle"
          handleClick={() => {
            hideMenu();
            navigate(ROUTES.INSIGHT);
          }}
        />
      )}
      {mode !== "pathways" && (
        <MenuItem
          icon={PathwayIcon}
          hoverIcon={PathwayHoverIcon}
          title="Pathways"
          handleClick={() => {
            hideMenu();
            navigate(ROUTES.PATHWAYS);
          }}
        />
      )}
      {mode !== "matrix" && (
        <MenuItem
          icon={ConversationalIcon}
          hoverIcon={ConversationalHoverIcon}
          title="Conversational Matrix"
          handleClick={() => {
            hideMenu();
            navigate(ROUTES.CONVERSATIONAL);
          }}
        />
      )}
      <MenuItem
        icon={ContentIcon}
        hoverIcon={ContentHoverIcon}
        title="Content Studio"
        handleClick={() => {
          hideMenu();
        }}
      />
      <MenuItem
        icon={EmailIcon}
        hoverIcon={EmailHoverIcon}
        title="Message Center"
        handleClick={() => {
          hideMenu();
        }}
      />
    </div>
  );
}

export default MenuMain;
