/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import "./styles.scss";

import { Chart as ChartJS } from "chart.js";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Line, Doughnut } from "react-chartjs-2";
import { useDetectClickOutside } from "react-detect-click-outside";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import moment from "moment";

import PageTemplate from "pages/PageTemplate";
import {
  API_LINECHART_SOURCE,
  API_SENTIMENTCHART_SOURCE,
  API_THEMECHART_SOURCE,
  API_SENTIMENTMESSAGE_SOURCE,
  API_THEMEMESSAGE_SOURCE,
  API_MESSAGE,
  API_STAR,
  API_UNSTAR,
  API_AUDIENCECHART_SOURCE,
  API_AUDIENCEMESSAGE_SOURCE,
  API_SOURCECHART,
  API_SOURCECHART_MESSAGE,
  API_VOICE_TEXTVIEW,
  API_VOICE_SEARCH_ACCOUNT,
} from "constants/routes";
import { convertToDt, compressJson } from "utils/convertData";
import ChartPopup from "components/Page/ChartPopup";
import GraphPopup from "components/Page/GraphPopup";
import AudienceAnalysis from "components/Page/AudienceAnalysis";

import SvgInfo from "assets/images/icons/icon-info.svg";
import ChevDown from "assets/images/icons/chev-up.svg";
import ChevUp from "assets/images/icons/chev-down.svg";
import VoiceFilterPopup from "components/Page/VoiceFilterPopup";

interface IChartScreen {
  switchViewMode: (mode: string) => void;
  setIsLoading: (loading: boolean) => void;
  menu: string;
  loading: boolean;
  dataSources: AtlasMach.ISource[];
  chartStack: { [key: string]: AtlasMach.IChartData };
  sideOption: AtlasMach.UISideOption;
  authToken: string;
}

interface ILineData {
  label: string;
  value: number;
}

function ChartScreen({
  menu,
  switchViewMode,
  setIsLoading,
  dataSources,
  chartStack,
  sideOption,
  authToken,
  loading,
}: IChartScreen) {
  const [lineData, setLineData] = useState<ILineData[]>([]);
  const [sentimentData, setSentimentData] = useState<AtlasMach.IPieData[]>([]);
  const [themeData, setThemeData] = useState<AtlasMach.IPieData[]>([]);
  const [audienceData, setAudienceData] = useState<AtlasMach.IPieData[]>([]);
  const [sourceData, setSourceData] = useState<AtlasMach.IPieData[]>([]);

  const [sentimentMessages, setSentimentMessages] = useState<
    AtlasMach.IPieMessages[]
  >([]);
  const [themeMessages, setThemeMessages] = useState<AtlasMach.IPieMessages[]>(
    []
  );
  const [audienceMessages, setAudienceMessages] = useState<
    AtlasMach.IPieMessages[]
  >([]);
  const [sourceMessages, setSourceMessages] = useState<
    AtlasMach.IPieMessages[]
  >([]);

  const [selectedTheme, setSelectedTheme] = useState<AtlasMach.IPieData>();
  const [selectedAudience, setSelectedAudience] =
    useState<AtlasMach.IPieData>();
  const [selectedSentiment, setSelectedSentiment] =
    useState<AtlasMach.IPieData>();
  const [selectedSource, setSelectedSource] = useState<AtlasMach.IPieData>();

  const [currentChartData, setCurrentChartData] = useState<
    AtlasMach.IChartData | undefined
  >();
  const [selectedThemeAccount, setSelectedThemeAccount] =
    useState<AtlasMach.INode>();
  const [themeAccountMsg, setThemeAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [selectedAudienceAccount, setSelectedAudienceAccount] =
    useState<AtlasMach.INode>();
  const [audienceAccountMsg, setAudienceAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [selectedSentimentAccount, setSelectedSentimentAccount] =
    useState<AtlasMach.INode>();
  const [sentimentAccountMsg, setSentimentAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [selectedSourceAccount, setSelectedSourceAccount] =
    useState<AtlasMach.INode>();
  const [sourceAccountMsg, setSourceAccountMsg] =
    useState<AtlasMach.IAccountMessages>();

  const [popupNode, setPopupNode] = useState<AtlasMach.INode>();

  const [hiddenSentiments, setHiddenSentiments] = useState<string[]>([]);
  const [hiddenThemes, setHiddenThemes] = useState<string[]>([]);
  const [hiddenAudiences, setHiddenAudiences] = useState<string[]>([]);
  const [hiddenSources, setHiddenSources] = useState<string[]>([]);

  const [initialized, setInitialized] = useState(false);

  const [viewMode, setViewMode] = useState<"CHART" | "TEXT">("CHART");

  const sentimentChartRef = useRef<any>();
  const themeChartRef = useRef<any>();
  const audienceChartRef = useRef<any>();
  const sourceChartRef = useRef<any>();

  useEffect(() => {
    switchViewMode("chart");
  }, []);

  useEffect(() => {
    const key = compressJson({
      topic: sideOption.topic,
      source: sideOption.source,
      dateFrom: sideOption.dateFrom,
      dateTo: sideOption.dateTo,
    });
    setCurrentChartData(chartStack[key]);
  }, [chartStack, sideOption.topic, sideOption.source]);

  const loadLineChart = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_LINECHART_SOURCE.replace("$1", option.topic).replace(
        "$2",
        option.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => setLineData(json.data));
  };

  const loadSentimentChart = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_SENTIMENTCHART_SOURCE.replace("$1", option.topic).replace(
        "$2",
        option.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const newData = json.data.map((jd) => {
          let color = "white";
          if (jd.label !== "N/A") {
            color = jd.color;
          }
          return { ...jd, color };
        });
        setSentimentData(newData);
        setTimeout(() => {
          newData.forEach((nd, idx) => {
            const visible = sentimentChartRef.current.getDataVisibility(idx);
            if (hiddenSentiments.includes(nd.label)) {
              if (visible) {
                sentimentChartRef.current.toggleDataVisibility(idx);
              }
            } else {
              if (!visible) {
                sentimentChartRef.current.toggleDataVisibility(idx);
              }
            }
          });
          sentimentChartRef.current.update();
        }, 1000);
      });
  };

  const loadThemeChart = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_THEMECHART_SOURCE.replace("$1", option.topic).replace(
        "$2",
        option.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setThemeData(json.data);
        setTimeout(() => {
          json.data.forEach((nd, idx) => {
            const visible = themeChartRef.current.getDataVisibility(idx);
            if (hiddenThemes.includes(nd.label)) {
              if (visible) {
                themeChartRef.current.toggleDataVisibility(idx);
              }
            } else {
              if (!visible) {
                themeChartRef.current.toggleDataVisibility(idx);
              }
            }
          });
          themeChartRef.current.update();
        }, 1000);
      });
  };

  const loadAudienceChart = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(
      API_AUDIENCECHART_SOURCE.replace("$1", option.topic).replace(
        "$2",
        option.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setAudienceData(json.data);
        setTimeout(() => {
          json.data.forEach((nd, idx) => {
            const visible = audienceChartRef.current.getDataVisibility(idx);
            if (hiddenAudiences.includes(nd.label)) {
              if (visible) {
                audienceChartRef.current.toggleDataVisibility(idx);
              }
            } else {
              if (!visible) {
                audienceChartRef.current.toggleDataVisibility(idx);
              }
            }
          });
          audienceChartRef.current.update();
        }, 1000);
      });
  };

  const loadSourceChart = (option) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    fetch(API_SOURCECHART.replace("$1", option.topic), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        startDate: dt.dt_from,
        endDate: dt.dt_to,
        selectedAudiences: [],
        selectedSubtopics: [],
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        setSourceData(json.data);
        setTimeout(() => {
          json.data.forEach((nd, idx) => {
            const visible = sourceChartRef.current.getDataVisibility(idx);
            if (hiddenSources.includes(nd.label)) {
              if (visible) {
                sourceChartRef.current.toggleDataVisibility(idx);
              }
            } else {
              if (!visible) {
                sourceChartRef.current.toggleDataVisibility(idx);
              }
            }
          });
          sourceChartRef.current.update();
        }, 1000);
      });
  };

  const loadSentimentMessages = async (option, sentimentId) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    const response = await fetch(
      API_SENTIMENTMESSAGE_SOURCE.replace("$1", option.topic)
        .replace("$2", option.source)
        .replace("$3", sentimentId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    );
    const data = await response.json();
    return data;
  };

  const loadThemeMessages = async (option, themeId) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    const response = await fetch(
      API_THEMEMESSAGE_SOURCE.replace("$1", option.topic)
        .replace("$2", option.source)
        .replace("$3", themeId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    );
    const data = await response.json();
    return data;
  };

  const loadAudienceMessages = async (option, audienceId) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    const response = await fetch(
      API_AUDIENCEMESSAGE_SOURCE.replace("$1", option.topic)
        .replace("$2", option.source)
        .replace("$3", audienceId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: option.audiences,
          selectedSubtopics: option.subtopics,
        }),
      }
    );
    const data = await response.json();
    return data;
  };

  const loadSourceMessages = async (option, sourceId) => {
    const dt = convertToDt(option.dateFrom, option.dateTo);
    const response = await fetch(
      API_SOURCECHART_MESSAGE.replace("$1", option.topic).replace(
        "$2",
        sourceId
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: [],
          selectedSubtopics: [],
        }),
      }
    );
    const data = await response.json();
    return data;
  };

  const loadAccountMessages = async (topic, from, to, accountId, sourceId) => {
    const response = await fetch(API_MESSAGE.replace("$1", topic), {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        dateRange: {
          startDate: from,
          endDate: to,
        },
        accountId: accountId,
        sourceId: sourceId,
      }),
    });
    const data = await response.json();
    return data;
  };

  const sortedLineData = useMemo(() => {
    return lineData.sort(
      (a, b) => new Date(a.label).getTime() - new Date(b.label).getTime()
    );
  }, [lineData]);

  const lineLabels = useMemo(() => {
    return sortedLineData.map((ld) => moment(ld.label).format("YY-MM-DD"));
  }, [sortedLineData]);

  const totalMentions = useMemo(() => {
    return lineData.reduce((prev, v) => prev + v.value, 0);
  }, [lineData]);

  const suggestedMax = useMemo(() => {
    if (lineData.length === 0) return 100;
    const maxValue = lineData
      .map((ld) => ld.value)
      .reduce(function (p, v) {
        return p > v ? p : v;
      });
    return Math.round(maxValue / 10 + 1) * 10;
  }, [lineData]);

  const loadCharData = () => {
    loadLineChart(sideOption);
    loadSentimentChart(sideOption);
    loadThemeChart(sideOption);
    loadAudienceChart(sideOption);
    loadSourceChart(sideOption);
  };

  useEffect(() => {
    if (sideOption.topic) loadCharData();
    if (!initialized) {
      setInitialized(true);
    } else {
      setSentimentMessages([]);
      setThemeMessages([]);
    }
  }, [sideOption]);

  const renderLineChart = () => {
    return (
      <Line
        datasetIdKey="id"
        data={{
          labels: lineLabels,
          datasets: [
            {
              data: sortedLineData.map((ld) => ld.value),
              borderColor: "#78D2F1",
              borderWidth: 5,
              pointBackgroundColor: "#78D2F1",
            },
          ],
        }}
        options={{
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: "white",
                padding: 10,
                font: {
                  family: "Tomorrow",
                  size: 18,
                },
              },
            },
            y: {
              title: {
                color: "white",
                display: true,
                text: "MENTIONS",
                font: {
                  family: "Tomorrow",
                  size: 18,
                },
              },
              grid: {
                color: "#1F4860",
                lineWidth: 2,
                drawBorder: false,
              },
              ticks: {
                color: "white",
                padding: 20,
                font: {
                  family: "Tomorrow",
                  size: 18,
                },
              },
              suggestedMin: 0,
              suggestedMax: suggestedMax,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
      />
    );
  };
  const defaultLegendClickHandler =
    ChartJS.overrides.doughnut.plugins.legend.onClick;
  const renderDoughnut = (type, ref, data, handlePieClick) => {
    return (
      <Doughnut
        ref={ref}
        onClick={handlePieClick}
        data={{
          labels: data.map((ds) => ds.label),
          datasets: [
            {
              data: data.map((ds) => ds.value),
              backgroundColor: data.map((ds) => ds.color),
              borderWidth: 0,
              hoverBorderColor: "white",
              hoverBorderWidth: 1,
            },
          ],
        }}
        options={{
          cutout: "70%",
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              bodyFont: {
                family: "Tomorrow",
                size: 14,
              },
              callbacks: {
                label: function (context) {
                  return ` ${context.dataset.data[context.dataIndex]} %`;
                },
              },
            },
            legend: {
              onClick: function (e, legendItem, legend) {
                if (type === "sentiment") {
                  setHiddenSentiments((prev) =>
                    prev.indexOf(legendItem.text) >= 0
                      ? prev.filter((hd) => hd !== legendItem.text)
                      : [...prev, legendItem.text]
                  );
                } else if (type === "theme") {
                  setHiddenThemes((prev) =>
                    prev.indexOf(legendItem.text) >= 0
                      ? prev.filter((hd) => hd !== legendItem.text)
                      : [...prev, legendItem.text]
                  );
                } else if (type === "audience") {
                  setHiddenAudiences((prev) =>
                    prev.indexOf(legendItem.text) >= 0
                      ? prev.filter((hd) => hd !== legendItem.text)
                      : [...prev, legendItem.text]
                  );
                }
                defaultLegendClickHandler.call(this, e, legendItem, legend);
              },
              position: "right",
              labels: {
                font: {
                  family: "Tomorrow",
                  size: 12,
                },
                color: "white",
                usePointStyle: true,
                pointStyle: "circle",
                padding: 20,
              },
            },
          },
        }}
      />
    );
  };

  const renderDoughnutSource = () => {
    let totalMsgCount = 0;
    if (sourceData.length > 0)
      totalMsgCount = sourceData.map((ds) => ds.value).reduce((p, a) => p + a);
    const values = sourceData.map(
      (ds) => Math.round(((ds.value * 100) / totalMsgCount) * 100) / 100
    );
    const currentSourceObjName =
      dataSources.find((ds) => ds.id === sideOption.source)?.name ?? "";
    return (
      <Doughnut
        ref={sourceChartRef}
        onClick={handleSourceClick}
        data={{
          labels: sourceData.map((ds) => ds.label),
          datasets: [
            {
              data: values,
              backgroundColor: sourceData.map((ds) => ds.color ?? "black"),
              borderWidth: sourceData.map((ds) =>
                ds.label === currentSourceObjName ? 2 : 0
              ),
              hoverBorderColor: "white",
              hoverBorderWidth: 1,
            },
          ],
        }}
        options={{
          cutout: "70%",
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            tooltip: {
              bodyFont: {
                family: "Tomorrow",
                size: 14,
              },
              callbacks: {
                label: function (context) {
                  return `${context.dataset.data[context.dataIndex]} %`;
                },
              },
            },
            legend: {
              onClick: function (e, legendItem, legend) {
                setHiddenSources((prev) =>
                  prev.indexOf(legendItem.text) >= 0
                    ? prev.filter((hd) => hd !== legendItem.text)
                    : [...prev, legendItem.text]
                );

                defaultLegendClickHandler.call(this, e, legendItem, legend);
              },
              position: "right",
              labels: {
                font: {
                  family: "Tomorrow",
                  size: 12,
                },
                color: "white",
                usePointStyle: true,
                pointStyle: "circle",
                padding: 20,
              },
            },
            datalabels: {
              color: "white",
              labels: {
                label: {
                  font: {
                    family: "Tomorrow",
                    size: 20,
                  },
                  formatter: function (value, ctx) {
                    if (ctx.chart.data.labels)
                      return ctx.chart.data.labels[ctx.dataIndex];
                  },
                },
              },
            },
          },
        }}
      />
    );
  };

  const handleSentimentClick = async (ev) => {
    const points = sentimentChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      setIsLoading(true);
      let segmentMessages = await loadSentimentMessages(
        sideOption,
        sentimentData[points[0].index].id
      );
      setSentimentMessages(segmentMessages);
      setIsLoading(false);

      const idx = points[0].index;
      const data = sentimentData[idx];
      if (data.label === "N/A") return;
      setSelectedSentiment(data);
    }
  };
  const handleThemeClick = async (ev) => {
    const points = themeChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      setIsLoading(true);
      let segmentMessages = await loadThemeMessages(
        sideOption,
        themeData[points[0].index].id
      );
      setIsLoading(false);
      setThemeMessages(segmentMessages);
      const idx = points[0].index;
      const data = themeData[idx];
      if (data.label === "N/A") return;
      setSelectedTheme(data);
    }
  };

  const handleAudienceClick = async (ev) => {
    const points = audienceChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      setIsLoading(true);
      let adMessages = await loadAudienceMessages(
        sideOption,
        audienceData[points[0].index].id
      );
      setIsLoading(false);
      setAudienceMessages(adMessages);
      const idx = points[0].index;
      const data = audienceData[idx];
      if (data.label === "N/A") return;
      setSelectedAudience(data);
    }
  };

  const handleSourceClick = async (ev) => {
    const points = sourceChartRef.current.getElementsAtEventForMode(
      ev,
      "nearest",
      { intersect: true },
      true
    );
    if (points.length > 0) {
      setIsLoading(true);
      let adMessages = await loadSourceMessages(
        sideOption,
        sourceData[points[0].index].id
      );
      setIsLoading(false);
      setSourceMessages(adMessages);
      const idx = points[0].index;
      const data = sourceData[idx];
      if (data.label === "N/A") return;
      setSelectedSource(data);
    }
  };

  const closeThemePopup = () => {
    setSelectedTheme(undefined);
  };
  const closeSentimentPopup = () => {
    setSelectedSentiment(undefined);
  };
  const closeAudiencePopup = () => {
    setSelectedAudience(undefined);
  };
  const closeSourcePopup = () => {
    setSelectedSource(undefined);
  };

  const handleStarred = (pnode, updateNode) => {
    const starLink = pnode.isStarred ? API_UNSTAR : API_STAR;
    fetch(
      starLink
        .replace("$1", sideOption.topic)
        .replace("$2", pnode.sourceId)
        .replace("$3", pnode.accountId),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    ).then((res) => {
      if (res.status === 200) {
        setCurrentChartData((prev) => {
          if (!prev) return undefined;
          return {
            ...prev,
            nodes: prev.nodes.map((node) =>
              node.accountId === pnode.accountId
                ? { ...node, isStarred: !node.isStarred }
                : node
            ),
          };
        });
        updateNode((prev) => ({
          ...prev,
          isStarred: !prev.isStarred,
        }));
      }
    });
  };

  const handleThemeMessage = async (msg: AtlasMach.IMessage) => {
    const node = currentChartData?.nodes.find(
      (node) => node.key === msg.ingestionMeta.from.accountName
    );
    setSelectedThemeAccount(node);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    const messages = await loadAccountMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node?.accountId,
      node?.sourceId
    );
    setThemeAccountMsg(messages);
    setPopupNode(node);
  };
  const closeThemeMessagePopup = () => {
    setSelectedThemeAccount(undefined);
    setPopupNode(undefined);
  };
  const handleThemeStarred = () => {
    handleStarred(selectedThemeAccount, setSelectedThemeAccount);
  };
  const handleAudienceMessage = async (msg: AtlasMach.IMessage) => {
    const node = currentChartData?.nodes.find(
      (node) => node.key === msg.ingestionMeta.from.accountName
    );
    setSelectedAudienceAccount(node);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    const messages = await loadAccountMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node?.accountId,
      node?.sourceId
    );
    setAudienceAccountMsg(messages);
    setPopupNode(node);
  };
  const closeAudienceMessagePopup = () => {
    setSelectedAudienceAccount(undefined);
    setPopupNode(undefined);
  };
  const handleAudienceStarred = () => {
    handleStarred(selectedAudienceAccount, setSelectedAudienceAccount);
  };
  const handleSentimentMessage = async (msg: AtlasMach.IMessage) => {
    const node = currentChartData?.nodes.find(
      (node) => node.key === msg.ingestionMeta.from.accountName
    );
    setSelectedSentimentAccount(node);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    const messages = await loadAccountMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node?.accountId,
      node?.sourceId
    );
    setSentimentAccountMsg(messages);
    setPopupNode(node);
  };
  const handleSentimentStarred = () => {
    handleStarred(selectedSentimentAccount, setSelectedSentimentAccount);
  };
  const closeSentimentMessagePopup = () => {
    setSelectedSentimentAccount(undefined);
    setPopupNode(undefined);
  };

  const handleSourceMessage = async (msg: AtlasMach.IMessage) => {
    const node = currentChartData?.nodes.find(
      (node) => node.key === msg.ingestionMeta.from.accountName
    );
    setSelectedSourceAccount(node);
    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    const messages = await loadAccountMessages(
      sideOption.topic,
      dt.dt_from,
      dt.dt_to,
      node?.accountId,
      node?.sourceId
    );
    setSourceAccountMsg(messages);
    setPopupNode(node);
  };
  const handleSourceStarred = () => {
    handleStarred(selectedSourceAccount, setSelectedSourceAccount);
  };
  const closeSourceMessagePopup = () => {
    setSelectedSourceAccount(undefined);
    setPopupNode(undefined);
  };

  const tableKeys = [
    { title: "Sender", name: "Sender", width: 275, leftPos: 20 },
    { title: "Audience", name: "Audience", width: 214, leftPos: 303 },
    { title: "Message", name: "Message", width: 423, leftPos: 525 },
    { title: "Sentiment", name: "Sentiment", width: 197, leftPos: 956 },
    { title: "Theme", name: "Theme", width: 296, leftPos: 1161 },
    { title: "Engagement", name: "EngagementScore", width: 214, leftPos: 1280 },
    { title: "Date", name: "Date", width: 214, leftPos: 1502 },
  ];
  const [sortColumn, setSortColumn] = useState<string>("");
  const [ordering, setOrdering] = useState<boolean>(false);
  const [selectedSentimentIDs, setSelectedSentimentIDs] = useState<string[]>(
    []
  );
  const [selectedThemeIDs, setSelectedThemeIDs] = useState<string[]>([]);
  const [filterKeyword, setFilterKeyword] = useState<string>("");
  const [accountKeyword, setAccountKeyword] = useState<string>("");
  const [searchedAccounts, setSearchedAccounts] = useState<any[]>([]);
  const [selectedAccountIDs, setSelectedAccountIDs] = useState<string[]>([]);
  const [dmData, setDMData] = useState<any[]>([
    {
      Sender: "BethMorton",
      Audience: "Caregiver",
      Message:
        "Join ninth ARCH today , 1104 neurologists from all over the world joining in to listen to @daviddodick_@petergoadsby now@combatstrokeSL_@MigraineFounda2_@WFNRehab_@INTERACT_my @ihs_official",
      Sentiment: "NEGATIVE",
      Theme: "COVID",
      Date: "08/17/2022",
      audience_color: "#FA4178",
      theme_color: "#FF8049",
      sentiment_color: "#FF8049",
    },
    {
      Sender: "513eats",
      Audience: "Patient",
      Message:
        "@wfneurology has dedicated today’s #WorldBrainDay theme to #BrainHealthforAll. To do our part, we are continuing to promote #migraine awareness, research, advocacy and patient education. Learn more about this year’s theme and how you can get involved: https://wfneurology.org/world-brain-day-2022",
      Sentiment: "POSITIVE",
      Theme: "Symptoms & aliments",
      Date: "08/13/2022",
      audience_color: "#FFD0AD",
      theme_color: "#E9ACE9",
      sentiment_color: "#5CFE9D",
    },
    {
      Sender: "55bonnie",
      Audience: "HCP",
      Message:
        "What are G Forces? In Episode 03 of The 'Hawkin Podcast' Dr. David Dodick talks in detail about G Forces and the amount we experience playing collision sports: https://bit.ly/3leNs96",
      Sentiment: "POSITIVE",
      Theme: "Treatment Options",
      Date: "08/02/2022",
      audience_color: "#C3DE1F",
      theme_color: "#B4CD66",
      sentiment_color: "#5CFE9D",
    },
    {
      Sender: "7hevandal",
      Audience: "Research",
      Message:
        'Dr David Dodick talks on "Controlling Unresponsive Chronic Migraine", a lecture so many of us direly need. Tag a friend who lives in constant pain due to unrelenting migraine. @daviddodick @MayoClinic @MayoClinicNeuro @MigraineSummit',
      Sentiment: "NEGATIVE",
      Theme: "Diagnoses & Patient Care",
      Date: "07/28/2022",
      audience_color: "#35D645",
      theme_color: "#A3B3E6",
      sentiment_color: "#FF8049",
    },
    {
      Sender: "83alexandrine",
      Audience: "Pharma",
      Message:
        "Dr David Dodick identifies #prevention as key in avoiding human suffering caused by #neurologicaldisorders, as high number is preventable!",
      Sentiment: "POSITIVE",
      Theme: "Community & Advocacy",
      Date: "07/14/2022",
      audience_color: "#9EF8F2",
      theme_color: "#C6B2FA",
      sentiment_color: "#5CFE9D",
    },
    {
      Sender: "90laurenquigley",
      Audience: "Patient",
      Message:
        "David Dodick, MD, reviews changes to the treatment landscape for acute migraine in this video series. https://lnkd.in/djy-FWe",
      Sentiment: "POSITIVE",
      Theme: "Diet & Lifestyle",
      Date: "07/10/2022",
      audience_color: "#FFD0AD",
      theme_color: "#DDA87D",
      sentiment_color: "#5CFE9D",
    },
  ]);

  const [popupColumn, setPopupColumn] = useState<string>("");
  const [showSort, setShowSort] = useState<boolean>(false);

  const [currentStart, setCurrentStart] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [textData, setTextData] = useState<any[]>([]);
  const [textSentiments, setTextSentiments] = useState<any>();
  const [scrollOffset, setScrollOffset] = useState<number>(0);

  useEffect(() => {
    if (!sideOption.topic || !sideOption.source) return;

    const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
    fetch(
      API_VOICE_TEXTVIEW.replace("$1", sideOption.topic).replace(
        "$2",
        sideOption.source
      ),
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          startDate: dt.dt_from,
          endDate: dt.dt_to,
          selectedAudiences: sideOption.audiences,
          selectedSubtopics: sideOption.subtopics,
          selectedThemes: selectedThemeIDs,
          selectedSentiments: selectedSentimentIDs,
          selectedAccounts: selectedAccountIDs,
          start: 0,
          take: 50,
          orderBy: sortColumn,
          isDesc: ordering,
          filter: filterKeyword,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        setTextData(res.data);
        setTextSentiments(res.sentiments);
        setCurrentStart(res.data.length);
        setHasMore(res.data.length === 50);
      });
  }, [
    authToken,
    sideOption.topic,
    sideOption.source,
    sideOption.dateFrom,
    sideOption.dateTo,
    sideOption.audiences,
    sideOption.subtopics,
    sortColumn,
    ordering,
    selectedSentimentIDs,
    selectedThemeIDs,
    filterKeyword,
    selectedAccountIDs,
  ]);

  const handleChangeViewMode = (mode: "CHART" | "TEXT") => {
    setViewMode(mode);
  };

  const handleSort = (column: string) => {
    if (popupColumn === column) {
      setShowSort((prev) => !prev);
    } else {
      setShowSort(true);
    }
    setPopupColumn(column);
  };

  const getAudienceColor = (audienceID: string) => {
    const ad = audienceData.find((item) => item.id === audienceID);
    if (!ad) return "white";

    return ad.color;
  };

  const getSentimentText = (sentimentIdx: number) => {
    const sentiment = sentimentData.find(
      (sd) => sd.id === sentimentIdx.toString()
    );
    if (!sentiment) return "";

    return sentiment.label;
  };

  const getSentimentColor = (sentimentIdx: number) => {
    const sentiment = sentimentData.find(
      (sd) => sd.id === sentimentIdx.toString()
    );
    if (!sentiment) return "";

    return sentiment.color || "white";
  };

  const getThemeColor = (themeID: string) => {
    const theme = themeData.find((td) => td.id === themeID);
    if (!theme) return "white";

    return theme.color;
  };

  const searchAccount = useCallback(
    (keyword: string) => {
      if (!sideOption.topic || !sideOption.source) return;
      if (keyword.length < 3) return;

      setAccountKeyword(keyword);
      const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
      fetch(
        API_VOICE_SEARCH_ACCOUNT.replace("$1", sideOption.topic).replace(
          "$2",
          sideOption.source
        ),
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            startDate: dt.dt_from,
            endDate: dt.dt_to,
            selectedAudiences: sideOption.audiences,
            selectedSubtopics: sideOption.subtopics,
            selectedThemes: selectedThemeIDs,
            selectedSentiments: selectedSentimentIDs,
            starAccountOnly: false,
            start: 0,
            take: 50,
            orderBy: "",
            isDesc: true,
            filter: keyword,
          }),
        }
      )
        .then((res) => res.json())
        .then((res) => {
          let arr: any[] = [];
          for (let key of Object.keys(res)) {
            arr.push({
              id: key,
              label: res[key],
            });
          }
          setSearchedAccounts(arr);
        });
    },
    [
      authToken,
      sideOption.topic,
      sideOption.source,
      sideOption.dateFrom,
      sideOption.dateTo,
      sideOption.audiences,
      sideOption.subtopics,
      selectedThemeIDs,
      selectedSentimentIDs,
    ]
  );

  const handleTextScroll = useCallback(
    (e) => {
      const bottom =
        Math.trunc(e.target.scrollHeight - e.target.scrollTop) ===
        e.target.clientHeight;
      setScrollOffset(e.target.scrollLeft);
      if (bottom) {
        if (!sideOption.topic || !sideOption.source) return;
        if (!hasMore) return;
        if (loading) return;

        setIsLoading(true);
        const dt = convertToDt(sideOption.dateFrom, sideOption.dateTo);
        fetch(
          API_VOICE_TEXTVIEW.replace("$1", sideOption.topic).replace(
            "$2",
            sideOption.source
          ),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({
              startDate: dt.dt_from,
              endDate: dt.dt_to,
              selectedAudiences: sideOption.audiences,
              selectedSubtopics: sideOption.subtopics,
              selectedThemes: selectedThemeIDs,
              selectedSentiments: selectedSentimentIDs,
              selectedAccounts: selectedAccountIDs,
              start: currentStart,
              take: 50,
              orderBy: sortColumn,
              isDesc: ordering,
              filter: filterKeyword,
            }),
          }
        )
          .then((res) => res.json())
          .then((res) => {
            setTextData((prev) => [...prev, ...res.data]);
            setCurrentStart((prev) => prev + res.data.length);
            setHasMore(res.data.length === 50);
            setIsLoading(false);
          });
      }
    },
    [
      authToken,
      sideOption.topic,
      sideOption.source,
      sideOption.dateFrom,
      sideOption.dateTo,
      sideOption.audiences,
      sideOption.subtopics,
      currentStart,
      loading,
      hasMore,
      sortColumn,
      ordering,
      selectedSentimentIDs,
      selectedThemeIDs,
      selectedAccountIDs,
      filterKeyword,
    ]
  );

  return (
    <PageTemplate title="Atlas Voices">
      {menu === "aa" && popupNode && <AudienceAnalysis />}
      <div className="chart-container">
        <div className="chart-tabs-wrapper">
          <div
            className={`tab-chart${viewMode === "CHART" ? " active" : ""}`}
            onClick={() => handleChangeViewMode("CHART")}
          >
            Chart View
          </div>
          <div
            className={`tab-text${viewMode === "TEXT" ? " active" : ""}`}
            onClick={() => handleChangeViewMode("TEXT")}
          >
            Text View
          </div>
        </div>
        <div className="border-wrapper">
          {viewMode === "CHART" && (
            <div className="chart-wrapper">
              <div className="line-chart-header">
                <div className="line-chart-header-title">MENTION TREND</div>
                <div className="line-chart-header-desc">
                  <span className="title">MENTIONS:</span>
                  <span>{totalMentions.toLocaleString()}</span>
                </div>
              </div>
              <div style={{ height: 400, marginBottom: 40 }}>
                {renderLineChart()}
              </div>
              <div className="dn-wrapper">
                <div className="dn-part">
                  <div className="title">Sentiment</div>
                  <div className="doughnut-wrapper">
                    {selectedSentiment && sentimentMessages.length && (
                      <ChartPopup
                        position={{ x: 0, y: 0 }}
                        data={selectedSentiment}
                        msgData={sentimentMessages}
                        handleClose={closeSentimentPopup}
                        handleMessage={handleSentimentMessage}
                      />
                    )}
                    {selectedSentimentAccount && sentimentAccountMsg && (
                      <GraphPopup
                        position={{ x: 330, y: 0 }}
                        handleClose={closeSentimentMessagePopup}
                        data={sentimentAccountMsg}
                        node={selectedSentimentAccount}
                        onStarred={handleSentimentStarred}
                      />
                    )}
                    {renderDoughnut(
                      "sentiment",
                      sentimentChartRef,
                      sentimentData,
                      handleSentimentClick
                    )}
                  </div>
                </div>
                <div className="separator" />
                <div className="dn-part">
                  <div className="title">Themes & Discussion</div>
                  <div className="doughnut-wrapper">
                    {selectedTheme && (
                      <ChartPopup
                        position={{ x: 0, y: 0 }}
                        data={selectedTheme}
                        msgData={themeMessages}
                        handleClose={closeThemePopup}
                        handleMessage={handleThemeMessage}
                      />
                    )}
                    {selectedThemeAccount && themeAccountMsg && (
                      <GraphPopup
                        position={{ x: 330, y: 0 }}
                        handleClose={closeThemeMessagePopup}
                        data={themeAccountMsg}
                        node={selectedThemeAccount}
                        onStarred={handleThemeStarred}
                      />
                    )}
                    {renderDoughnut(
                      "theme",
                      themeChartRef,
                      themeData,
                      handleThemeClick
                    )}
                  </div>
                </div>
              </div>
              <div className="dn-wrapper">
                <div className="dn-part">
                  <div className="title">Audience</div>
                  <div className="doughnut-wrapper">
                    {selectedAudience && audienceMessages.length && (
                      <ChartPopup
                        position={{ x: 0, y: 0 }}
                        data={selectedAudience}
                        msgData={audienceMessages}
                        handleClose={closeAudiencePopup}
                        handleMessage={handleAudienceMessage}
                      />
                    )}
                    {selectedAudienceAccount && audienceAccountMsg && (
                      <GraphPopup
                        position={{ x: 330, y: 0 }}
                        handleClose={closeAudienceMessagePopup}
                        data={audienceAccountMsg}
                        node={selectedAudienceAccount}
                        onStarred={handleAudienceStarred}
                      />
                    )}
                    {renderDoughnut(
                      "audience",
                      audienceChartRef,
                      audienceData,
                      handleAudienceClick
                    )}
                  </div>
                </div>
                <div className="separator" />
                <div className="dn-part">
                  <div className="title">Sources</div>
                  <div className="doughnut-wrapper">
                    {selectedSource && (
                      <ChartPopup
                        position={{ x: 0, y: 0 }}
                        data={selectedSource}
                        msgData={sourceMessages}
                        handleClose={closeSourcePopup}
                        handleMessage={handleSourceMessage}
                      />
                    )}
                    {selectedSourceAccount && sourceAccountMsg && (
                      <GraphPopup
                        position={{ x: 330, y: 0 }}
                        handleClose={closeSourceMessagePopup}
                        data={sourceAccountMsg}
                        node={selectedSourceAccount}
                        onStarred={handleSourceStarred}
                      />
                    )}
                    {renderDoughnutSource()}
                  </div>
                </div>
              </div>
            </div>
          )}
          {viewMode === "TEXT" && (
            <div className="text-wrapper">
              <div className="text-scroll-wrapper">
                <div className="text-header">
                  <div className="large">All Data</div>
                  <div className="small">
                    <img src={SvgInfo} alt="info" />
                    <span>Messages sorted by highest engagement</span>
                  </div>
                </div>
                <ScrollSync>
                  <div className="text-table-wrapper">
                    <ScrollSyncPane>
                      <div className="header">
                        {tableKeys.map((dk, idx) => (
                          <div
                            className="header-item"
                            key={`ddkey-${dk.name}`}
                            style={{ width: dk.width }}
                            onClick={() => handleSort(dk.name)}
                          >
                            <div className="icon-header">
                              <div className="column-header">{dk.title}</div>
                              <img
                                src={
                                  showSort && dk.name === popupColumn
                                    ? ChevDown
                                    : ChevUp
                                }
                                alt="sort"
                                className="sort-icon"
                              />
                            </div>
                            {popupColumn === dk.name && showSort && (
                              <VoiceFilterPopup
                                popupColumn={popupColumn}
                                leftPos={dk.leftPos - scrollOffset}
                                sortColumn={sortColumn}
                                ordering={ordering}
                                themeData={themeData}
                                sentimentData={sentimentData}
                                selectedSentimentIDs={selectedSentimentIDs}
                                selectedThemeIDs={selectedThemeIDs}
                                filterKeyword={filterKeyword}
                                accountKeyword={accountKeyword}
                                searchedAccounts={searchedAccounts}
                                selectedAccountIDs={selectedAccountIDs}
                                setSortColumn={setSortColumn}
                                setOrdering={setOrdering}
                                setShowSort={setShowSort}
                                setSelectedSentimentIDs={
                                  setSelectedSentimentIDs
                                }
                                setSelectedThemeIDs={setSelectedThemeIDs}
                                setFilterKeyword={setFilterKeyword}
                                setAccountKeyword={searchAccount}
                                setSelectedAccountIDs={setSelectedAccountIDs}
                              />
                            )}
                          </div>
                        ))}
                        <div style={{ width: 1, flexShrink: 0 }}></div>
                      </div>
                    </ScrollSyncPane>
                    <ScrollSyncPane>
                      <div className="body" onScroll={handleTextScroll}>
                        {textData.map((item, idx) => (
                          <div key={`ddrow-${idx}`} className="body-row">
                            <div className="body-item" style={{ width: 275 }}>
                              <div className="text-center cell">
                                <span>{item[0]["Sender"]}</span>
                              </div>
                            </div>
                            <div className="body-item" style={{ width: 214 }}>
                              <div className="text-center cell">
                                <div
                                  className="circle"
                                  style={{
                                    background: getAudienceColor(
                                      item[2]["AudienceId"]
                                    ),
                                  }}
                                ></div>
                                <span>{item[3]["Audience"]}</span>
                              </div>
                            </div>
                            <div className="body-item" style={{ width: 423 }}>
                              <div className="cell">{item[4]["Message"]}</div>
                            </div>
                            <div className="body-item" style={{ width: 197 }}>
                              <div
                                className="text-center cell"
                                style={{
                                  color: getSentimentColor(
                                    item[6]["Sentiment"]
                                  ),
                                  textTransform: "uppercase",
                                  fontWeight: "600",
                                }}
                              >
                                <span>
                                  {getSentimentText(item[6]["Sentiment"])}
                                </span>
                              </div>
                            </div>
                            <div className="body-item" style={{ width: 296 }}>
                              <div className="text-center cell">
                                <div
                                  className="circle"
                                  style={{
                                    background: getThemeColor(
                                      item[7]["ThemeId"]
                                    ),
                                  }}
                                ></div>
                                <span>{item[8]["Theme"]}</span>
                              </div>
                            </div>
                            <div className="body-item" style={{ width: 214 }}>
                              <div className="text-center cell">
                                <span>{item[5]["EngagementScore"]}</span>
                              </div>
                            </div>
                            <div className="body-item" style={{ width: 214 }}>
                              <div className="text-center cell">
                                <span>
                                  {moment(item[9]["Date"]).format("MM/DD/YYYY")}
                                </span>
                              </div>
                            </div>
                            <div style={{ width: 1, flexShrink: 0 }}></div>
                          </div>
                        ))}
                      </div>
                    </ScrollSyncPane>
                  </div>
                </ScrollSync>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageTemplate>
  );
}

export default ChartScreen;
