import React, { useState } from "react";

interface IMenuItem {
  icon: any;
  hoverIcon: any;
  title: string;
  handleClick?: () => void;
}

function MenuItem({ icon, hoverIcon, title, handleClick }: IMenuItem) {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseMove = (e) => {
    setIsHovering(true);
  };

  const handleMouseOut = (e) => {
    setIsHovering(false);
  };

  return (
    <div
      className="menu-item"
      onMouseOver={handleMouseMove}
      onMouseOut={handleMouseOut}
      onClick={(e) => {
        if (handleClick) handleClick();
      }}
    >
      {!isHovering && <img src={icon} alt="topic" />}
      {isHovering && <img src={hoverIcon} alt="topic" />}
      <span>{title}</span>
    </div>
  );
}

export default MenuItem;
