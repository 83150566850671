import React from "react";
import LogoImage from "assets/images/logo.png";

function Logo() {
  return (
    <div className="logo-container">
      <img alt="logo" src={LogoImage} />
      <label>Mach9 Atlas</label>
    </div>
  );
}

export default Logo;
