import React from "react";

import ArrowUpIcon from "assets/images/icons/arrow-up-big.svg";
import ArrowDownIcon from "assets/images/icons/arrow-down-big.svg";
import LegendArrows from "assets/images/icons/legend-arrows.svg";
import LegendCircles from "assets/images/icons/legend-circles.svg";
import LegendThick from "assets/images/icons/legend-thick-arrow.svg";

interface ILegendMenu {
  menu: string;
  openMenu: (menu: string) => void;
  hideMenu: () => void;
  dataAudiences: AtlasMach.IAudience[];
}

function LegendMenu({ menu, openMenu, hideMenu, dataAudiences }: ILegendMenu) {
  return (
    <div className="legend-container">
      <div className="shown-menu" onClick={() => openMenu("legend")}>
        <span>Legend</span>
        <img src={ArrowUpIcon} alt="arrow" />
      </div>
      {menu === "legend" && (
        <div className="hidden-menu">
          <div className="legend-indicator" onClick={() => hideMenu()}>
            <img src={ArrowDownIcon} alt="arrow" />
          </div>
          <div className="symbol-container">
            <div className="symbol">
              <img src={LegendArrows} alt="legend arrow" />
              <div className="symbol-text">
                <div className="symbol-title">Arrows</div>
                <div className="symbol-desc">
                  Shows direction of the relationship
                </div>
              </div>
            </div>
            <div className="symbol">
              <img src={LegendThick} alt="legend arrow" />
              <div className="symbol-text">
                <div className="symbol-title">Arrow Thickness</div>
                <div className="symbol-desc">Represents volume of messages</div>
              </div>
            </div>
            <div className="symbol">
              <img src={LegendCircles} alt="legend arrow" />
              <div className="symbol-text">
                <div className="symbol-title">Circle Size</div>
                <div className="symbol-desc">
                  Calculated based on centricity
                </div>
              </div>
            </div>
          </div>
          <div className="color-container">
            {dataAudiences.map((da) => (
              <div className="color-item" key={`legend-${da.id}`}>
                <div className="color-box" style={{ background: da.color }} />
                <div className="color-name">{da.name}</div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default LegendMenu;
