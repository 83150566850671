import React from "react";

import ArrowUpIcon from "assets/images/icons/arrow-up-big.svg";
import ArrowDownIcon from "assets/images/icons/arrow-down-big.svg";

interface ILegendMenu {
  menu: string;
  openMenu: (menu: string) => void;
  hideMenu: () => void;
  dataAudiences: AtlasMach.IAudience[];
}

function PathwayLegend({
  menu,
  openMenu,
  hideMenu,
  dataAudiences,
}: ILegendMenu) {
  return (
    <div className="legend-container">
      <div className="shown-menu" onClick={() => openMenu("pathway_legend")}>
        <span>Legend</span>
        <img src={ArrowUpIcon} alt="arrow" />
      </div>
      {menu === "pathway_legend" && (
        <div className="pathway-hidden-menu">
          <div className="legend-indicator" onClick={() => hideMenu()}>
            <img src={ArrowDownIcon} alt="arrow" />
          </div>
          <div className="pathway-legend-descriptor">
            <p className="header">Definition:</p>
            <p className="body">
              <b>Path 1:</b> High Centricity + High Likelihood of Engagement
            </p>
            <p className="body">
              <b>Path 2:</b> Medium/Low Centricity + High Likelihood of
              Engagement
            </p>
            <p className="body">
              <b>Path 3:</b> High Centricity + Medium Likelihood of Engagement
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default PathwayLegend;
