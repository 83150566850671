export const OPEN_MENU = "OPEN_MENU";
export const HIDE_MENU = "HIDE_MENU";
export const UPDATE_DATE = "UPDATE_DATE";
export const SET_CHARTREF = "SET_CHARTREF";
export const SWITCH_VIEW_MODE = "SWITCH_VIEW_MODE";

export const SET_ISLOADING = "SET_ISLOADING";
export const UPDATE_PROFILE_COUNT = "UPDATE_PROFILE_COUNT";
export const UPDATE_PERCENT = "UPDATE_PERCENT";
export const UPDATE_EXPORT_DATA = "UPDATE_EXPORT_DATA";

export const UPDATE_TOPIC = "UPDATE_TOPIC";
export const UPDATE_DATE_RANGE = "UPDATE_UPDATE_RANGE";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_SUBTOPICS = "UPDATE_SUBTOPICS";
export const UPDATE_SOURCE = "UPDATE_SOURCE";
export const UPDATE_AUDIENCES = "UPDATE_AUDIENCES";
export const UPDATE_SIDEOPTION = "UPDATE_SIDEOPTION";

export const SET_CLIENT = "SET_CLIENT";
export const SET_TOPICS = "SET_TOPICS";
export const SET_SUBTOPICS = "SET_SUBTOPICS";
export const SET_SOURCES = "SET_SOURCES";
export const SET_AUDIENCES = "SET_AUDIENCES";
export const SET_THEMES = "SET_THEMES";
export const SET_BRANDS = "SET_BRANDS";
export const SET_BRAND_ACCOUNTS = "SET_BRAND_ACCOUNTS";
export const PUSH_CHARTDATA = "PUSH_CHARTDATA";
export const PUSH_CHARTPOSITION = "PUSH_CHARTPOSITION";

export const SET_ACCOUNT = "SET_ACCOUNT";
// export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const SET_STARACCOUNTS = "SET_STARACCOUNTS";
export const SET_HEATMAPS = "SET_HEATMAPS";
export const SET_HEATTHEMES = "SET_HEATTHEMES";
export const SET_HEATACCOUNTS = "SET_HEATACCOUNTS";
export const SET_AUDIENCE_ANALYSIS = "SET_AUDIENCE_ANALYSIS";

export const SET_API_TOKEN = "SET_API_TOKEN";
