import { connect } from "react-redux";
import { openMenu, hideMenu } from "redux/actions";
import MenuMain from "./MenuUser";

const mapStateToProps = (state: AtlasMach.StoreState) => ({});

const mapDispatchToProps = {
  openMenu,
  hideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMain);
