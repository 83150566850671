import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "constants/routes";
import { UserManager } from "utils/authConst";

// Note that IdentityServer suggests the callback URL (the first two components below)
// Could just use a new UserMnager with response_mode: "query" (without the rest of settings)
// and then do a full reload of the page with window.location = "/". As this would reload the
// page, it doesn't matter that we re-instantiate the same user manager again instead of using
// the global one, so maybe that is cleaner? Currently we share the same global UserManager.

//const mgr = new UserManager({ response_mode: "query" });

export const LoginCallback = () => {
  console.log("atlas login callback");
  const navigate = useNavigate();
  useEffect(() => {
    UserManager.signinRedirectCallback().then(() => {
      navigate(ROUTES.HCP, { replace: true });
    });
  }, []);
  return <div>Redirecting</div>;
};

export const HSLoginCallback = () => {
  const navigate = useNavigate();
  console.log("hs login callback");
  useEffect(() => {
    UserManager.signinRedirectCallback()
      .then(() => {
        navigate("/hs/home", { replace: true });
      })
      .catch((e) => {
        console.error(e);
        UserManager.signoutRedirect();
        UserManager.removeUser();
        UserManager.revokeAccessToken();
        UserManager.clearStaleState();
      });
  }, []);
  return <div>Redirecting</div>;
};

// This is a bit of a hack, but the goal of this page is to behave as a login page, but since
// login is outside our control (on identity server) we need to redirect there with the right redirect URI.
// In order to do that, we log out first, then this redirects to /hs, which itself will check if we are logged
// in (we are not) and will do the right redirect to the identity server. We don't technically need to log out,
// but we do it because we share credentials between atlas and HS, and if an Atlas user were to click log in
// on HS website and be already logged in in Atlas, they would just land in the app, which we want to avoid, so we
// force a logout when you visit the /hs/login page.
export const HSLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    UserManager.getUser().then((user) => {
      if (user) {
        UserManager.signoutRedirect();
      } else {
        navigate("/hs");
      }
    });
  }, []);
  return <div>Redirecting</div>;
};
