import { connect } from "react-redux";
import { switchViewMode, setStarAccounts } from "redux/actions";
import StarProfileScreen from "./StarProfileScreen";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  starAccounts: state.data.starAccounts,
  sideOption: state.ui.sideOption,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  setStarAccounts,
  switchViewMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(StarProfileScreen);
