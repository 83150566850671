import { connect } from "react-redux";
import VoiceFilterPopup from "./VoiceFilterPopup";

const mapStateToProps = (state: AtlasMach.StoreState, ownProps) => ({
  sideOption: state.ui.sideOption,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(VoiceFilterPopup);
