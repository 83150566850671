import React, { useEffect, useMemo, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import moment from "moment";

import { API_STAR_PROFILE, API_UNSTAR } from "constants/routes";
import PageTemplate from "pages/PageTemplate";

import "./styles.scss";
import BlueButton from "components/Page/BlueButton";

import SvgFirst from "assets/images/icons/first.svg";
import SvgPrev from "assets/images/icons/prev.svg";
import SvgNext from "assets/images/icons/next.svg";
import SvgLast from "assets/images/icons/last.svg";
import SvgSort from "assets/images/icons/sort.svg";
import SvgSortSel from "assets/images/icons/sort-up.svg";

interface Props {
  sideOption: AtlasMach.UISideOption;
  starAccounts: AtlasMach.IStarredAccount[];
  authToken: string;
  setStarAccounts: (accounts: AtlasMach.IStarredAccount[]) => void;
  switchViewMode: (mode: string) => void;
}

function StarProfileScreen(props: Props) {
  const {
    sideOption,
    starAccounts,
    setStarAccounts,
    authToken,
    switchViewMode,
  } = props;
  // const [accounts, setAccounts] = useState<AtlasMach.IStarredAccount[]>([])
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState<string[]>([]);
  const [sortColumn, setSortColumn] = useState<string>("");
  const [ordering, setOrdering] = useState<boolean>(false);

  const filteredAccounts = useMemo(() => {
    if (sideOption.audiences.length === 0) return starAccounts;

    return starAccounts.filter(
      (ac) =>
        ac.account.audiences.findIndex(
          (acAu) =>
            sideOption.audiences.findIndex(
              (au) => au === acAu.topicAudienceId
            ) >= 0
        ) >= 0
    );
  }, [starAccounts, sideOption.audiences]);

  const sortedAccounts = useMemo(() => {
    const res = filteredAccounts.sort((a, b) => {
      if (sortColumn === "name") {
        if (ordering) {
          if (a.account.accountName > b.account.accountName) return -1;
          else if (a.account.accountName < b.account.accountName) return 1;
          else return 0;
        } else {
          if (a.account.accountName < b.account.accountName) return -1;
          else if (a.account.accountName > b.account.accountName) return 1;
          else return 0;
        }
      } else if (sortColumn === "score") {
        if (ordering) {
          return a.score - b.score;
        } else {
          return b.score - a.score;
        }
      } else {
        if (ordering) {
          if (a.createdOn > b.createdOn) return -1;
          else if (a.createdOn < b.createdOn) return 1;
          else return 0;
        } else {
          if (a.createdOn < b.createdOn) return -1;
          else if (a.createdOn > b.createdOn) return 1;
          else return 0;
        }
      }
    });

    return [...res];
  }, [filteredAccounts, sortColumn, ordering]);

  const pageCount = useMemo(() => {
    if (filteredAccounts.length === 0) return 0;
    return filteredAccounts.length % pageSize !== 0
      ? Number.parseInt((filteredAccounts.length / pageSize).toString()) + 1
      : filteredAccounts.length / pageSize;
  }, [filteredAccounts, pageSize]);

  const pageLimit = useMemo(() => {
    const minPage = Math.max(0, page - 2);
    const maxPage = Math.min(minPage + 5, pageCount);
    return { minPage, maxPage };
  }, [pageCount, page]);

  const pageAccounts = useMemo(() => {
    return sortedAccounts.filter(
      (ac, idx) => idx >= page * pageSize && idx < (page + 1) * pageSize
    );
  }, [sortedAccounts, page, pageSize]);

  const allSelect = useMemo(() => {
    return pageAccounts.length > 0 && pageAccounts.length === selected.length;
  }, [selected, pageAccounts]);

  useEffect(() => {
    switchViewMode("accounts");
  }, []);

  useEffect(() => {
    if (sideOption.topic && sideOption.source)
      fetch(
        API_STAR_PROFILE.replace("$1", sideOption.topic).replace(
          "$2",
          sideOption.source
        ),
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      )
        .then((res) => res.json())
        .then((data) => setStarAccounts(data));
  }, [sideOption.topic, sideOption.source]);

  useEffect(() => {
    setSelected([]);
  }, [pageAccounts]);

  const handleCheck = (id) => {
    setSelected((prev) =>
      prev.findIndex((p) => p === id) >= 0
        ? prev.filter((p) => p !== id)
        : [...prev, id]
    );
  };
  const handleAllCheck = () => {
    if (allSelect) setSelected([]);
    else setSelected(pageAccounts.map((pa) => pa.id));
  };

  const removeStar = async () => {
    await Promise.all(
      selected.map(async (id, i) => {
        const selectedAccount = starAccounts.find((ac) => ac.id === id);
        if (!selectedAccount) return;

        await fetch(
          API_UNSTAR.replace("$1", selectedAccount.topicId)
            .replace("$2", selectedAccount.sourceId)
            .replace("$3", selectedAccount.accountId),
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }
        );
      })
    );
    setStarAccounts(
      starAccounts.filter(
        (pac) => selected.findIndex((sac) => sac === pac.id) < 0
      )
    );
  };

  const handleSort = (column: string) => {
    if (column === sortColumn) {
      setOrdering((prev) => !prev);
    } else {
      setSortColumn(column);
      setOrdering(true);
    }
  };

  return (
    <PageTemplate title="Starred Accounts">
      <div className="profile-container">
        <div className="profile-table-container">
          <table>
            <thead>
              <tr>
                <th>
                  <label className="check-container">
                    <input
                      type="checkbox"
                      name="checkAllProfile"
                      className="check-input"
                      checked={allSelect}
                      onChange={(ev) => handleAllCheck()}
                    />
                    <span className="check-title"></span>
                    <span className="checkmark"></span>
                  </label>
                </th>
                <th>
                  <div className="icon-header">
                    <span className={sortColumn === "name" ? "active" : ""}>
                      Account
                    </span>
                    <img
                      src={sortColumn === "name" ? SvgSortSel : SvgSort}
                      alt="sort"
                      className="sort-icon"
                      onClick={() => handleSort("name")}
                    />
                  </div>
                </th>
                <th>
                  <div className="icon-header">
                    <span className={sortColumn === "score" ? "active" : ""}>
                      Centricity Score
                    </span>
                    <img
                      src={sortColumn === "score" ? SvgSortSel : SvgSort}
                      alt="sort"
                      className="sort-icon"
                      onClick={() => handleSort("score")}
                    />
                  </div>
                </th>
                <th>
                  <div className="icon-header">
                    <span className={sortColumn === "date" ? "active" : ""}>
                      Date Added
                    </span>
                    <img
                      src={sortColumn === "date" ? SvgSortSel : SvgSort}
                      alt="sort"
                      className="sort-icon"
                      onClick={() => handleSort("date")}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {pageAccounts.map((ac) => (
                <tr key={`star-profile-${ac.id}`}>
                  <td>
                    <label className="check-container">
                      <input
                        type="checkbox"
                        name="checkAllProfile"
                        className="check-input"
                        value={ac.id}
                        checked={selected.findIndex((sl) => sl === ac.id) >= 0}
                        onChange={(ev) => handleCheck(ac.id)}
                      />
                      <span className="check-title"></span>
                      <span className="checkmark"></span>
                    </label>
                  </td>
                  <td>{ac.account.name}</td>
                  <td>{ac.score}%</td>
                  <td>{moment(ac.createdOn).format("YYYY-MM-DD")}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="profile-table-footer">
          <div className="profile-table-buttons">
            <BlueButton
              title="Remove Starred Account"
              disabled={selected.length === 0}
              onClick={() => removeStar()}
            />
            <span className="selected-count">{selected.length} SELECTED</span>
          </div>
          <div className="profile-table-pagination">
            <div>
              <select
                className="pagination-count"
                value={pageSize}
                onChange={(ev) => setPageSize(Number(ev.target.value))}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <span>PER PAGE</span>
            </div>
            <img
              src={SvgFirst}
              alt="first-page"
              className="move-page"
              onClick={() => setPage(0)}
            />
            <img
              src={SvgPrev}
              alt="prev-page"
              className="move-page"
              onClick={() => setPage((prev) => Math.max(0, prev - 1))}
            />
            {Array.from(Array(pageLimit.maxPage - pageLimit.minPage)).map(
              (v, i) => (
                <span
                  className={`page-number ${
                    pageLimit.minPage + i === page ? "active" : ""
                  }`}
                  key={`page-${pageLimit.minPage + i + 1}`}
                  onClick={() => setPage(i)}
                >
                  {pageLimit.minPage + i + 1}
                </span>
              )
            )}
            <img
              src={SvgNext}
              alt="prev-page"
              className="move-page"
              onClick={() =>
                setPage((prev) => Math.min(pageCount - 1, prev + 1))
              }
            />
            <img
              src={SvgLast}
              alt="prev-page"
              className="move-page"
              onClick={() => setPage(pageCount - 1)}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default StarProfileScreen;
