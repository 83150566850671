import { connect } from "react-redux";
import {
  switchViewMode,
  setStarAccounts,
  setExportData,
  setIsLoading,
} from "redux/actions";
import PathwayScreen from "./PathwayScreen";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  starAccounts: state.data.starAccounts,
  sideOption: state.ui.sideOption,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  setStarAccounts,
  switchViewMode,
  setExportData,
  setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(PathwayScreen);
