import React, { useEffect, useMemo, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import moment from "moment";

import {
  API_NOTIFICATION_GETALL,
  API_NOTIFICATION_READALL,
  API_STAR_PROFILE,
} from "constants/routes";
import PageTemplate from "pages/PageTemplate";

import "./styles.scss";

import SvgFirst from "assets/images/icons/first.svg";
import SvgPrev from "assets/images/icons/prev.svg";
import SvgNext from "assets/images/icons/next.svg";
import SvgLast from "assets/images/icons/last.svg";

interface IProps {
  sideOption: AtlasMach.UISideOption;
  authToken: string;
  switchViewMode: (mode: string) => void;
}

function NotificationScreen(props: IProps) {
  const { sideOption, authToken, switchViewMode } = props;

  const [notifications, setNotifications] = useState<AtlasMach.INotification[]>(
    []
  );
  const [pageSize, setPageSize] = useState(20);
  const [page, setPage] = useState(0);

  const pageCount = useMemo(() => {
    if (notifications.length === 0) return 0;
    return notifications.length % pageSize !== 0
      ? Number.parseInt((notifications.length / pageSize).toString()) + 1
      : notifications.length / pageSize;
  }, [notifications, pageSize]);

  const pageLimit = useMemo(() => {
    const minPage = Math.max(0, page - 2);
    const maxPage = Math.min(minPage + 5, pageCount);
    return { minPage, maxPage };
  }, [pageCount, page]);

  const pageNotifies = useMemo(() => {
    return notifications.filter(
      (ac, idx) => idx >= page * pageSize && idx < (page + 1) * pageSize
    );
  }, [notifications, page, pageSize]);

  useEffect(() => {
    switchViewMode("notifications");
  }, []);

  useEffect(() => {
    const loadNotifications = async () => {
      const response = await fetch(
        API_NOTIFICATION_GETALL.replace("$1", sideOption.topic).replace(
          "$2",
          sideOption.source
        ),
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = await response.json();
      setNotifications(data);
    };
    if (sideOption.topic && sideOption.source) {
      loadNotifications();
    }
  }, [sideOption]);

  const dismissAll = async () => {
    setNotifications((prev) =>
      prev.map((msg) => ({
        ...msg,
        status: 2,
      }))
    );
    await fetch(
      API_NOTIFICATION_READALL.replace("$1", sideOption.topic).replace(
        "$2",
        sideOption.source
      ),
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
  };

  return (
    <PageTemplate title="Notifications">
      <div className="notification-container">
        <div className="table-container">
          <div className="table-header">
            <div className="dismiss" onClick={() => dismissAll()}>
              Dismiss All
            </div>
          </div>
          <div className="table-content">
            {pageNotifies.map((msg, idx) => (
              <div
                className={`${
                  msg.status !== 1 ? "notify-item" : "notify-item active"
                }`}
                key={`notify-item-${idx}`}
              >
                <div className="alert-mark">
                  <div></div>
                </div>
                <div className="notify-date">
                  {moment(msg.createdOn).format("MM-DD-YYYY")}
                </div>
                <div className="notify-msg">{msg.text}</div>
              </div>
            ))}
            {pageNotifies.length === 0 && (
              <div className="nothing">
                <span>No notifications</span>
              </div>
            )}
          </div>
        </div>
        <div className="profile-table-footer">
          <div className="profile-table-pagination">
            <div>
              <select
                className="pagination-count"
                value={pageSize}
                onChange={(ev) => setPageSize(Number(ev.target.value))}
              >
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
              <span>PER PAGE</span>
            </div>
            <img
              src={SvgFirst}
              alt="first-page"
              className="move-page"
              onClick={() => setPage(0)}
            />
            <img
              src={SvgPrev}
              alt="prev-page"
              className="move-page"
              onClick={() => setPage((prev) => Math.max(0, prev - 1))}
            />
            {Array.from(Array(pageLimit.maxPage - pageLimit.minPage)).map(
              (v, i) => (
                <span
                  className={`page-number ${
                    pageLimit.minPage + i === page ? "active" : ""
                  }`}
                  key={`page-${pageLimit.minPage + i + 1}`}
                  onClick={() => setPage(i)}
                >
                  {pageLimit.minPage + i + 1}
                </span>
              )
            )}
            <img
              src={SvgNext}
              alt="prev-page"
              className="move-page"
              onClick={() =>
                setPage((prev) => Math.min(pageCount - 1, prev + 1))
              }
            />
            <img
              src={SvgLast}
              alt="prev-page"
              className="move-page"
              onClick={() => setPage(pageCount - 1)}
            />
          </div>
        </div>
      </div>
    </PageTemplate>
  );
}

export default NotificationScreen;
