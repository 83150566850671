import { connect } from "react-redux";
import { openMenu, hideMenu } from "redux/actions";
import AudienceAnalysis from "./AudienceAnalysis";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  menu: state.ui.headerMenu,
  aaData: state.data.audienceAnalysis,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  openMenu,
  hideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(AudienceAnalysis);
