// The Frontend has a different branding based on the domain it is accessed through. The
// underlying functionaly and capabilities are exactly the same, but this is to help brand
// its features towards different types of clients.
var healthSocialBranding = {
  TITLE: "HealthSocial",
};
var atlasBranding = {
  TITLE: "Mach9 Atlas",
};
var branding = atlasBranding;
if (
  window.location.host == "app.healthsocial.com" ||
  window.location.host == "appdev.healthsocial.com"
) {
  branding = healthSocialBranding;
} else if (
  window.location.host == "mach9atlas.com" ||
  window.location.host == "dev.mach9atlas.com"
) {
  branding = atlasBranding;
} else if (window.location.host == "localhost:3000") {
  branding = healthSocialBranding;
}

export const config = {
  BASE_URL: window.location.protocol + "//" + window.location.host,

  CLIENT_ID: window._env_.CLIENT_ID,
  AUTHORITY: window._env_.AUTHORITY,
  RESPONSE_TYPE: window._env_.RESPONSE_TYPE,
  SCOPE: window._env_.SCOPE,
  WEB_API_BASE_URI: window._env_.WEB_API_BASE_URI,
  CHANGE_PASSWORD_ROUTE: window._env_.CHANGE_PASSWORD_ROUTE,

  BRANDING: branding,
};
