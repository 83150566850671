import { connect } from "react-redux";
import { hideMenu } from "redux/actions";
import MenuNotification from "./MenuNotification";

const mapStateToProps = (state: AtlasMach.StoreState) => ({});

const mapDispatchToProps = {
  hideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuNotification);
