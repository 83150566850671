import React from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

function GlossaryMenuItem({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <div className="menu-item">
      <div className="item-title">{title}</div>
      <div className="item-content">{content}</div>
    </div>
  );
}

interface IMenuGlossary {
  rp: number;
  data: AtlasMach.IGlossary[];
  hideMenu: () => void;
}

function MenuGlossary({ hideMenu, rp, data }: IMenuGlossary) {
  const ref = useDetectClickOutside({ onTriggered: hideMenu });

  return (
    <div className={`glossary-menu`} style={{ right: rp }} ref={ref}>
      <div className="menu-title">
        <label>Glossary</label>
      </div>
      {data.map((gData) => (
        <GlossaryMenuItem
          key={`glossary-${gData.id}`}
          title={gData.name}
          content={gData.description}
        />
      ))}
    </div>
  );
}

export default MenuGlossary;
