import { connect } from "react-redux";
import { hideMenu } from "redux/actions";
import MenuGlossary from "./MenuGlossary";

const mapStateToProps = (state: AtlasMach.StoreState) => ({});

const mapDispatchToProps = {
  hideMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuGlossary);
