import { connect } from "react-redux";
import { switchViewMode } from "redux/actions";
import NotificationScreen from "./NotificationScreen";

const mapStateToProps = (state: AtlasMach.StoreState) => ({
  sideOption: state.ui.sideOption,
  authToken: state.data.auth_token,
});

const mapDispatchToProps = {
  switchViewMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationScreen);
